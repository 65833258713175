import React, {FormEvent, useState} from "react";
import {Card} from "../../components/card";
import {Label} from "../../components/label";
import {useQueryParams} from "../../hooks";
import {Button} from "../../components/button";
import {WP_REST_API_URL} from "../../store/types";
import {Link, withRouter} from "react-router-dom";
import {ERROR_CODES} from "../../error-codes";
import {PasswordChangeInput} from "../../components/password-change-input";
import {promisifyFetch} from "../../helpers/api";
import {useTranslation} from "react-i18next";
import {
  I18N_BACK_TO_LOGIN,
  I18N_EMAIL,
  I18N_RESET_PASSWORD,
  I18N_RESET_PASSWORD_SUCCESS_TEXT,
  I18N_RESET_PASSWORD_SUCCESS_TITLE,
  I18N_SET_PASSWORD
} from "../../translation";

const resetPassword = (email: string, password: string, resetKey: string) => {
  return promisifyFetch(`${WP_REST_API_URL}users/reset-password`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      password,
      resetKey
    })
  });
};

export default withRouter(() => {
  const {t} = useTranslation();
  const queryParams = useQueryParams();
  const email = queryParams.get('email') as string;
  const resetKey = queryParams.get('resetKey') as string;
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [isValid, setIsValid] = useState(false);

  const handlePasswordChange = (value: string) => setNewPassword(value);

  const handlePasswordValidity = (valid: boolean) => setIsValid(valid);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (newPassword) {
      resetPassword(email, newPassword, resetKey)
        .then(() => {
          setSuccess(true);
        })
        .catch((error) => {
          setSuccess(false);
          setError(t(ERROR_CODES[error.code]))
        })
        .finally(() => {
          setIsLoading(false);
        })
    }
  };

  return (
    <div className="full-page">
      <Card style={{'maxWidth': '400px'}}>
        <form onSubmit={handleSubmit} autoComplete="off">
          {success
            ? (
              <>
                <h1>{t(I18N_RESET_PASSWORD_SUCCESS_TITLE)}</h1>
                <p className="big-text">{t(I18N_RESET_PASSWORD_SUCCESS_TEXT)}</p>

                <div className="buttons-container">
                  <Link className="button" to={`/?email=${email}`}>{t(I18N_BACK_TO_LOGIN)}</Link>
                </div>
              </>
            )
            : (
              <>
                <h3 style={{'marginBottom': '0.3em'}}>{t(I18N_RESET_PASSWORD)}</h3>

                <Label name={t(I18N_EMAIL)}>
                  <input type="email" disabled value={queryParams.get('email') ?? ''}/>
                </Label>

                <PasswordChangeInput required onChange={handlePasswordChange}
                                     onValidityChange={handlePasswordValidity}/>

                <div className="buttons-container">
                  <Button type="submit" disabled={!isValid} isLoading={isLoading}>
                    {t(I18N_SET_PASSWORD)}
                  </Button>
                </div>

                {error !== '' && <div className="error-message">{error}</div>}
              </>
            )
          }
        </form>
      </Card>
    </div>
  )
})