import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {Button} from "../button";
import {FiArrowRight, FiX} from "react-icons/all";
import {FiUpload} from "react-icons/fi";
import {Card} from "../card";
import {FileInputProps, FileInputRef} from "./types";
import {Loader} from "../loader";
import {I18N_DROPZONE_TEXT} from "../../translation";
import {useTranslation} from "react-i18next";

const isImage = (file?: File) => {
  return file && file.type.split('/')[0] === 'image'
};

export default forwardRef((
  {
    required = false,
    resettable = false,
    onChange,
    onReset,
    previousMedia,
    value,
    showPreview = true
  }: FileInputProps, ref: any
) => {
  const {t} = useTranslation();
  const [file, setFile] = useState<File | undefined>(value);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, (): FileInputRef => ({
    reset: () => {
      if (inputRef.current) {
        inputRef.current.value = '';
        setFile(undefined);
        setImagePreview(null)
      }
    }
  }));

  useEffect(() => {
    if (file && isImage(file)) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        if (typeof fileReader.result === 'string') {
          setImagePreview(fileReader.result);
        }
      };
      fileReader.readAsDataURL(file)
    } else {
      setImagePreview(null)
    }
  }, [file]);

  useEffect(() => {
    if (onChange) {
      onChange(file);
    }
  }, [file, onChange]);

  const handleFile = (files: FileList | null) => {
    if (files) {
      setFile(files[0])
    }
  };

  const resetFile = () => {
    setFile(undefined);

    if (inputRef?.current) {
      inputRef.current.value = '';
    }

    onReset?.()
  };

  const preview = useMemo(() => {
    if (!file || !showPreview) {
      return null
    }

    return (
      isImage(file)
        ? (imagePreview
          ? <img src={imagePreview} alt={file.name} className="file-preview"/>
          : <div className="file-preview-loader"><Loader/></div>
        )
        : (previousMedia
          ? <div className="notification file-name">{file.name}</div>
          : null
        )
    )
  }, [imagePreview, file, showPreview, previousMedia]);

  const previousPreview = useMemo(() => {
    if (!previousMedia || !showPreview) {
      return null
    }

    return (
      previousMedia.fileType === 'image'
        ? <img src={previousMedia.url} alt={previousMedia.name} className="file-preview"/>
        : <div className="notification file-name">{previousMedia.name}</div>
    )
  }, [previousMedia, showPreview]);

  return (
    <div className="file-input">
      {previousPreview && preview
        ? (
          <div className="file-previews-compare">
            {previousPreview}
            <FiArrowRight className="icon"/>
            {preview}
          </div>
        )
        : (
          preview || previousPreview
        )
      }

      <div className="file-input-container">
        <Card
          empty={true}
          tabIndex={0}
          onClick={() => inputRef?.current?.click()}
          onDrop={(file: File) => setFile(file ?? undefined)}>
          <FiUpload className="icon"/>
          <div className="name" dangerouslySetInnerHTML={{
            __html: file?.name ?? t(I18N_DROPZONE_TEXT)
          }}/>
        </Card>

        <input
          type="file"
          tabIndex={-1}
          required={required && !file}
          ref={inputRef}
          multiple={false}
          onChange={(e) => handleFile(e.currentTarget.files)}/>

        {file && resettable && <Button icon small type="cancel" onClick={resetFile}><FiX/></Button>}
      </div>
    </div>
  )
})