import {RefObject, useEffect, useState} from "react";

type DropHandler = (file: File|null) => any;

export default (ref: RefObject<HTMLElement>, onDrop: DropHandler) => {
  const [draggingOver, setDraggingOver] = useState(false);

  useEffect(() => {
    if (!onDrop || !ref?.current) {
      return
    }

    const handleDragover = (e: DragEvent) => {
      e.preventDefault();
      setDraggingOver(true)
      console.log('dragover');
    };

    const handleDragleave = (e: DragEvent) => {
      e.preventDefault();
      setDraggingOver(false)
    };

    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      setDraggingOver(false);
      onDrop(e.dataTransfer?.files?.[0] ?? null);
    };

    const current = ref.current;
    ref.current.addEventListener('dragover', handleDragover);
    ref.current.addEventListener('dragleave', handleDragleave);
    ref.current.addEventListener('drop', handleDrop);

    return () => {
      current.removeEventListener('dragover', handleDragover);
      current.removeEventListener('dragleave', handleDragleave);
      current.removeEventListener('drop', handleDrop);
    }
  }, [ref, onDrop]);

  return [draggingOver]
}