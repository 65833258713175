import React, {FormEvent, useEffect, useMemo, useRef, useState} from "react";
import {UserSelectProps} from "./types";
import {User} from "../../store/modules/user/types";
import {Avatar} from "../avatar";
import classNames from "classnames";
import {FiX} from "react-icons/all";
import {useOnClickOutside, useOnKeyPress} from "../../hooks";
import {Card} from "../card";
import {useTranslation} from "react-i18next";
import {I18N_NO_MATCHES_FOUND, I18N_REMOVE, I18N_USER_SELECT_PLACEHOLDER} from "../../translation";

export default (props: UserSelectProps) => {
  const {t} = useTranslation();
  const [inputFocused, setInputFocused] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<User[]>(props.value ?? []);
  const [highlightedSuggestion, setHighlightedSuggestion] = useState<User | null>(null);
  const showSuggestions = useMemo(() => inputFocused && searchText.length >= 2, [searchText, inputFocused]);
  const searchRegex = useMemo(() => new RegExp(searchText, 'i'), [searchText]);
  const suggestions = useMemo(() => {
    const filterNotSelected = (user: User) => !selectedUsers.find(u => u.userId === user.userId);
    const filterSearch = (user: User) => [user.displayName, user.email].some(userAttribute => searchRegex.test(userAttribute));

    return props.users.filter(filterNotSelected)
      .filter(filterSearch)
  }, [props.users, searchRegex, selectedUsers]);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.required && inputRef?.current) {
      inputRef.current.setCustomValidity(selectedUsers.length ? '' : 'Please select some users')
    }
  }, [props, selectedUsers, inputRef]);

  useOnClickOutside(inputWrapperRef, () => setInputFocused(false));

  useOnKeyPress({
    'Enter': (e) => {
      const propagateEnter = !searchText;

      if (!propagateEnter) {
        e.preventDefault();
      }

      if (showSuggestions && highlightedSuggestion) {
        addUser(highlightedSuggestion);
      }
    },
    'ArrowDown': (e) => {
      e.preventDefault();

      if (showSuggestions) {
        if (highlightedSuggestion) {
          const highlightIndex = suggestions.findIndex(user => user.userId === highlightedSuggestion.userId);
          const nextIndex = (highlightIndex + 1) % suggestions.length;
          setHighlightedSuggestion(suggestions[nextIndex])
        } else {
          setHighlightedSuggestion(suggestions[0])
        }
      }
    },
    'ArrowUp': (e) => {
      e.preventDefault();

      if (showSuggestions) {
        if (highlightedSuggestion) {
          const highlightIndex = suggestions.findIndex(user => user.userId === highlightedSuggestion.userId);
          const nextIndex = (highlightIndex - 1 + suggestions.length) % suggestions.length;
          setHighlightedSuggestion(suggestions[nextIndex])
        } else {
          setHighlightedSuggestion(suggestions[suggestions.length - 1])
        }
      }
    },
  });

  useEffect(() => {
    props.onChange(selectedUsers);
  }, [props, selectedUsers]);

  useEffect(() => {
    if(suggestions.length) {
      setHighlightedSuggestion(suggestions[0])
    } else {
      setHighlightedSuggestion(null)
    }
  }, [suggestions]);

  const handleInput = (e: FormEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value)
  };

  const addUser = (user: User) => {
    setSearchText('');
    setSelectedUsers([
      ...selectedUsers,
      user
    ])
  };

  const removeUser = (user: User) => {
    setSelectedUsers(selectedUsers.filter(selectedUser => selectedUser.userId !== user.userId))
  };

  return (
    <div className="user-select">
      <div className="user-select-input-wrapper" ref={inputWrapperRef}>
        <input type="text" placeholder={t(I18N_USER_SELECT_PLACEHOLDER)} value={searchText} ref={inputRef}
               onChange={handleInput}
               onFocus={() => setInputFocused(true)}/>

        {showSuggestions && (
          <Card className={classNames({
            'user-select-suggestions': true,
            'user-select-suggestions--open': inputFocused,
          })}>
            {suggestions.length
              ? <ul className="user-list">
                {suggestions.map(user => {
                  const userNameHighlighted = searchText ? user.displayName.replace(searchRegex, `<b>$&</b>`) : user.displayName;

                  return (
                    <li key={user.userId} onClick={() => addUser(user)} className={classNames({
                      'user': true,
                      'user--basic-info': true,
                      'user-select-suggestion': true,
                      'user-select-suggestion--highlighted': highlightedSuggestion?.userId === user.userId,
                    })}>
                      <Avatar url={user.imageUrl ?? ''} key={user.name} size={40}/>
                      <div className="user-info">
                        <div dangerouslySetInnerHTML={{__html: userNameHighlighted}}/>
                        <div className="user-email">{user.email}</div>
                      </div>
                    </li>
                  )
                })}
              </ul>
              : (
                <div>{t(I18N_NO_MATCHES_FOUND)}</div>
              )}

          </Card>
        )}
      </div>

      {selectedUsers.length > 0 && <ul className="user-list user-select-list">
        {selectedUsers.map(user => (
          <li key={user.userId} className={classNames({
            'user': true,
            'user--basic-info': true,
          })}>
            <Avatar url={user.imageUrl ?? ''} key={user.name} size={40}/>
            <div className="user-info">
              <div>{user.displayName}</div>
              <div className="user-email">{user.email}</div>
            </div>

            <span className="link" onClick={() => removeUser(user)}><FiX/> {t(I18N_REMOVE)}</span>
          </li>
        ))}
      </ul>}
    </div>
  )
}