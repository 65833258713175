export const promisifyFetch = (url: string, args: any) => {
  return new Promise((resolve, reject) => {
    fetch(url, args)
      .then(async (response) => {
        if (!response.ok) {
          reject(await response.json())
        } else {
          resolve()
        }
      })
      .catch(e => reject(e));
  })
};