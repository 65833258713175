import React from "react";
import {LoaderProps} from "./types";
import classNames from "classnames";

export default (props: LoaderProps) => {
  return (
    <div className={classNames({
      'loader': true,
      'loader--small': props.size === 'small',
      'loader--large': props.size === 'large'
    })}/>
  )
}