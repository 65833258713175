import React from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../store/configure";

export default ({children, ...routeProps}: RouteProps) => {
  const {currentUser, sessionExpired} = useSelector((state: RootState) => state.auth);

  if (currentUser !== null && !sessionExpired) {
    return <Redirect to="/"/>
  }

  return (
    <Route {...routeProps}>
      {children}
    </Route>
  )
}