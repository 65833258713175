import {
  AUTH_SET_DONE,
  AUTH_SET_PENDING,
  AuthActionTypes,
  AuthResponseToken,
  AuthResponseType,
  AuthUser,
  AuthUserResponse,
  isErrorResponse,
  JWTToken,
  LOGIN,
  LOGOUT,
  REFRESH_CURRENT_USER,
  REFRESH_TOKEN,
  SET_AUTH_ERROR,
  UPDATE_USER,
  UploadUser,
} from "./types";
import {ThunkDispatch} from "redux-thunk";
import {WP_REST_API_URL, WP_REST_AUTH_URL} from "../../types";
import {User} from "../user/types";
import {persistor} from "../../configure";
import {ERROR_CODES} from "../../../error-codes";
import {API_ACTION, ApiAction} from "../../middleware/api/types";

export function setAuthError(authError: string) {
  return {
    type: SET_AUTH_ERROR,
    payload: authError
  }
}

export function login(email: string, password: string): ThunkDispatch<any, any, AuthActionTypes> {
  return async (dispatch: any) => {
    dispatch({
      type: AUTH_SET_PENDING
    });

    const response: AuthResponseType = await fetch(`${WP_REST_AUTH_URL}authenticate`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        username: email,
        password,
        issueJWT: true
      })
    })
      .then((response) => response.json());

    dispatch({
      type: AUTH_SET_DONE
    });

    if (isErrorResponse(response)) {
      dispatch(setAuthError(ERROR_CODES[response.code]))
    } else {
      const jwtToken = {
        token: (response as AuthUserResponse).jwt.token,
        expires: (response as AuthUserResponse).jwt.token_expires
      };

      const user: User = await fetch(`${WP_REST_API_URL}users/me`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken.token}`
        }
      }).then(response => response.json());

      const filterBaseRoles = (role: string) => !role.includes('_');

      dispatch({
        type: LOGIN,
        payload: {
          ...user,
          roles: (Array.isArray(user.roles) ? user.roles : Object.values(user.roles) as any).filter(filterBaseRoles),
          jwtToken
        }
      })
    }
  }
}

export function logout(authUser: AuthUser): ThunkDispatch<any, any, AuthActionTypes> {
  return (dispatch: any) => {
    dispatch({
      type: AUTH_SET_PENDING
    });

    return fetch(`${WP_REST_AUTH_URL}jwt/revoke`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        jwt: authUser.jwtToken.token,
      })
    })
      .then(() => {
        dispatch({
          type: LOGOUT
        });

        persistor.purge();
        persistor.flush();
        persistor.persist()
      }).finally(() => {
        dispatch({
          type: AUTH_SET_DONE
        });
      })
  }
}

export function updateUser(user: UploadUser): ApiAction {
  const formData = new FormData();
  formData.append('action', 'updateUser');
  if (user.imageFile) {
    formData.append('image', user.imageFile);
  }
  if (user.password) {
    formData.append('password', user.password);
  }

  return {
    [API_ACTION]: true,
    type: UPDATE_USER,
    bodyArgs: formData,
    method: 'POST',
    endpoint: 'users/update'
  }
}

export function refreshCurrentUser(): ApiAction {
  return {
    [API_ACTION]: true,
    type: REFRESH_CURRENT_USER,
    method: 'GET',
    endpoint: 'users/me'
  }
}

export function refreshToken(token: JWTToken): ThunkDispatch<any, any, AuthActionTypes> {
  return async (dispatch: any) => {
    dispatch({
      type: AUTH_SET_PENDING
    });

    const response: AuthResponseToken = await fetch(`${WP_REST_AUTH_URL}jwt/refresh`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        jwt: token.token
      })
    })
      .then((response) => response.json());

    dispatch({
      type: AUTH_SET_DONE
    });

    if (isErrorResponse(response)) {
      dispatch(setAuthError(ERROR_CODES[response.code]))
    } else {
      dispatch({
        type: REFRESH_TOKEN,
        payload: {
          token: response.token,
          expires: response.token_expires
        }
      })
    }
  }
}