import React, {FormEvent, useRef, useState} from "react";
import {Button} from "../../components/button";
import {FiSave} from "react-icons/all";
import {updateUser} from "../../store/modules/auth/actions";
import {useDispatch, useSelector} from "react-redux";
import {Card} from "../../components/card";
import {Label} from "../../components/label";
import {RootState} from "../../store/configure";
import {PasswordChangeInput} from "../../components/password-change-input";
import {PasswordChangeInputRef} from "../../components/password-change-input/types";
import {setDarkMode, setLanguage} from "../../store/modules/settings/actions";
import {
  I18N_ACCOUNT,
  I18N_DARK_MODE,
  I18N_EMAIL,
  I18N_ENGLISH,
  I18N_GERMAN,
  I18N_LANGUAGE,
  I18N_MORE_FEATURES,
  I18N_NAME,
  I18N_PROFILE,
  I18N_PROFILE_IMAGE,
  I18N_SAVE,
  I18N_SETTINGS
} from "../../translation";
import {useTranslation} from "react-i18next";
import {AvatarEditor} from "../../components/avatar-editor";
import {AvatarEditorRef} from "../../components/avatar-editor/types";

export default () => {
  const {t} = useTranslation();
  const dispatch: any = useDispatch();
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);

  const {darkMode, language} = useSelector((state: RootState) => state.settings);

  const [password, setPassword] = useState('');
  const passwordRef = useRef<PasswordChangeInputRef>(null);
  const avatarEditorRef = useRef<AvatarEditorRef>(null);

  const handlePasswordChange = (value: string) => setPassword(value);
  const handleDarkMode = (active: boolean) => dispatch(setDarkMode(active));
  const handleLanguage = (e: FormEvent<HTMLSelectElement>) => dispatch(setLanguage(e.currentTarget.value));

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!avatarEditorRef.current?.hasChanged()) {
      return
    }

    let imageFile = await avatarEditorRef.current.getFile();

    dispatch(updateUser({
      imageFile,
      password
    }))
      .then(() => avatarEditorRef.current?.reset())
  };

  return (
    <div className="full-page">
      <h2>{t(I18N_SETTINGS)}</h2>

      <Card className="settings-card">
        <form onSubmit={handleSubmit}>
          <div className="card-section">
            <h4>{t(I18N_ACCOUNT)}</h4>

            <Label name={t(I18N_EMAIL)}>
              <input type="email" value={currentUser?.email} disabled/>
            </Label>

            <PasswordChangeInput hideRulesWhenNotFocused={true} onChange={handlePasswordChange} ref={passwordRef}/>
          </div>

          <div className="card-section">
            <h4>{t(I18N_PROFILE)}</h4>

            <Label name={t(I18N_NAME)}>
              <input type="text" disabled value={currentUser?.displayName}/>
            </Label>

            <Label name={t(I18N_PROFILE_IMAGE)}>
              <AvatarEditor
                ref={avatarEditorRef}
                imageSource={currentUser?.imageUrl ?? undefined}/>
            </Label>
          </div>

          <div className="buttons-container">
            <Button type="submit"><FiSave/>{t(I18N_SAVE)}</Button>
          </div>
        </form>
      </Card>

      <h2>{t(I18N_MORE_FEATURES)}</h2>

      <Card className="settings-card">
        <Label name={t(I18N_DARK_MODE)} toggleable toggled={darkMode} onToggle={handleDarkMode}/>

        <Label name={t(I18N_LANGUAGE)}>
          <select onChange={handleLanguage} defaultValue={language}>
            <option value="de">{t(I18N_GERMAN)}</option>
            <option value="en">{t(I18N_ENGLISH)}</option>
          </select>
        </Label>
      </Card>
    </div>
  )
}