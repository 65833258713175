import {useEffect, useState} from "react";

export default (targetKeyMap: TargetKeyMap) => {
  const keys = Object.keys(targetKeyMap);
  const keysPressedInitial = Object.fromEntries(keys.map(key => [key, false]));
  const [keysPressed, setKeysPressed] = useState<KeyPressedMap>(keysPressedInitial);

  // Add event listeners
  useEffect(() => {
    const downHandler = (event: KeyboardEvent) => {
      if (keys.includes(event.key)) {
        setKeysPressed({
          ...keysPressed,
          [event.key]: true
        });
        targetKeyMap[event.key](event);
      }
    };

    const upHandler = (event: KeyboardEvent) => {
      if (keys.includes(event.key)) {
        setKeysPressed({
          ...keysPressed,
          [event.key]: false
        });
      }
    };

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [targetKeyMap, keys, keysPressed]);

  return keysPressed;
}