import React, {FormEvent, useRef, useState} from "react";
import {Card} from "../../components/card";
import {Label} from "../../components/label";
import {useQueryParams} from "../../hooks";
import {Button} from "../../components/button";
import {WP_REST_API_URL} from "../../store/types";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {ERROR_CODES} from "../../error-codes";
import {PasswordChangeInput} from "../../components/password-change-input";
import {useTranslation} from "react-i18next";
import {
  I18N_CONFIRM_ACCOUNT,
  I18N_CONFIRM_ACCOUNT_TEXT,
  I18N_EMAIL,
  I18N_HELLO,
  I18N_PROFILE_IMAGE
} from "../../translation";
import {AvatarEditor} from "../../components/avatar-editor";
import {AvatarEditorRef} from "../../components/avatar-editor/types";
import {createFormData} from "../../store/modules/room/actions";

interface InitAccountAction {
  email: string;
  password: string;
  resetKey: string;
  image?: File,
}

const initAccount = ({email, password, resetKey, image}: InitAccountAction) => {
  const formData = createFormData({
    email,
    password,
    resetKey,
    image,
    account: 'initAccount'
  }, 'updateAccount');

  return new Promise((resolve, reject) => {
    return fetch(`${WP_REST_API_URL}users/init-account`, {
      method: 'POST',
      mode: 'cors',
      body: formData
    })
      .then(async (response) => {
        if (!response.ok) {
          reject(await response.json())
        } else {
          resolve()
        }
      })
      .catch(e => reject(e))
  })
};

export default withRouter((props: RouteComponentProps) => {
  const {t} = useTranslation();
  const queryParams = useQueryParams();
  const resetKey = queryParams.get('resetKey') as string;
  const [error, setError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isValid, setIsValid] = useState(false);
  const avatarEditorRef = useRef<AvatarEditorRef>();

  const handlePasswordChange = (value: string) => setNewPassword(value);

  const handlePasswordValidity = (valid: boolean) => setIsValid(valid);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (newPassword) {
      const email = queryParams.get('email') as string;

      initAccount({
        email,
        password: newPassword,
        resetKey,
        image: avatarEditorRef.current?.hasChanged() ? await avatarEditorRef.current?.getFile() : undefined
      })
        .then((e) => {
          props.history.push(`/?email=${email}`)
        })
        .catch((error) => {
          setError(t(ERROR_CODES[error.code]))
        })
    }
  };

  return (
    <div className="full-page">
      <Card style={{'maxWidth': '400px'}}>
        <form onSubmit={handleSubmit} autoComplete="off">
          <h1 style={{'marginBottom': '0.3em'}}>{t(I18N_HELLO)}</h1>
          <p className="big-text">{t(I18N_CONFIRM_ACCOUNT_TEXT)}</p>

          <Label name={t(I18N_EMAIL)}>
            <input type="email" disabled value={queryParams.get('email') ?? ''}/>
          </Label>

          <PasswordChangeInput required onChange={handlePasswordChange} onValidityChange={handlePasswordValidity}/>

          <Label name={t(I18N_PROFILE_IMAGE)}>
            <AvatarEditor ref={avatarEditorRef}/>
          </Label>

          <div className="buttons-container">
            <Button type="submit" disabled={!isValid}>
              {t(I18N_CONFIRM_ACCOUNT)}
            </Button>
          </div>

          {error !== '' && <div className="error-message">{error}</div>}
        </form>
      </Card>
    </div>
  )
})