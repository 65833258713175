import React from 'react';
import {Link as NavigationLink} from './link'
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {useCapability} from "../../hooks";
import {UserMenu} from "../user-menu";
import {RootState} from "../../store/configure";
import {I18N_GROUPS, I18N_ROOMS, I18N_TEMPLATES} from "../../translation";
import {useTranslation} from "react-i18next";

export default () => {
  const {t} = useTranslation();
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const canAddTemplates = useCapability('edit_templates');
  const canManageGroups = useCapability('manage_groups');

  return (
    <>
      <div className="navigation-bar">
        <Link to="/" className="logo">
          <img src={process.env.PUBLIC_URL + '/skillhublogo-v1.png'} alt="Skillhub logo"/>
        </Link>

        {currentUser && (
          <UserMenu/>
        )}
      </div>

      {currentUser && (
        <nav className="navigation-menu">
          <NavigationLink icon="room" title={t(I18N_ROOMS)} href="/rooms"/>
          {canAddTemplates && <NavigationLink icon="room-templates" title={t(I18N_TEMPLATES)} href="/room-templates"/>}
          {canManageGroups && <NavigationLink icon="user" title={t(I18N_GROUPS)} href="/groups"/>}
        </nav>
      )}
    </>
  )
}