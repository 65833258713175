import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {Route, RouteComponentProps, Switch, useLocation, withRouter} from 'react-router-dom';
import classNames from "classnames";

import Rooms, {RoomRoute, RoomTemplateRoute} from './routes/rooms';
import Groups from './routes/groups';
import Settings from './routes/settings';
import Login from './routes/login';
import {SetPassword} from "./routes/set-password";

import {getRooms, getRoomTemplates} from "./store/modules/room/actions";
import {getAdminsAndAuthors, getCaps, getGroups} from "./store/modules/user/actions";
import {RootState} from "./store/configure";
import {refreshToken, setAuthError} from "./store/modules/auth/actions";

import Navigation from './components/navigation';
import {Card} from "./components/card";
import {ProtectedRoute} from "./components/protected-route";

import {useApiDispatch, useCapability} from "./hooks";

import './App.css';
import {PublicRoute} from "./components/public-route";
import {ResetPassword} from "./routes/reset-password";
import {ForgotPassword} from "./routes/forgot-password";
import {DISPLAY_LOGIN, JWTToken} from "./store/modules/auth/types";
import {Overlay} from "./components/overlay";
import {LoginForm} from "./components/login-form";
import {useTranslation} from "react-i18next";

const TOKEN_CHECK_INTERVAL = 1000 * 60; // in ms
const TOKEN_TIME_TO_REFRESH = 1000 * 60 * 5; // in ms, before expiration

const tokenHasToBeRefreshed = (token: JWTToken, dispatch: any): boolean => {
  const expiration = new Date(token.expires * 1000).getTime();
  const now = new Date().getTime();

  if (now > expiration) {
    dispatch({
      type: DISPLAY_LOGIN
    });

    return false
  }

  return expiration - now < TOKEN_TIME_TO_REFRESH
};

export default withRouter((props: RouteComponentProps) => {
  const {i18n} = useTranslation();
  useApiDispatch(getRooms);
  const location = useLocation();
  const dispatch: any = useDispatch();
  const {darkMode, language} = useSelector((state: RootState) => state.settings);
  const {currentUser, sessionExpired} = useSelector((state: RootState) => state.auth);
  const canCreateRooms = useCapability('create_room');
  const canEditTemplates = useCapability('edit_templates');
  const canManageGroups = useCapability('manage_groups');

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  useEffect(() => {
    if (canCreateRooms) {
      dispatch(getRoomTemplates())
        .catch(() => {
        });
    }

    if (canEditTemplates) {
      dispatch(getAdminsAndAuthors())
        .catch(() => {
        })
    }
  }, [canCreateRooms, canEditTemplates, dispatch]);

  useEffect(() => {
    if (canManageGroups) {
      dispatch(getGroups());
      dispatch(getCaps());
    }
  }, [canManageGroups, dispatch]);

  useEffect(() => {
    dispatch(setAuthError(''));
  }, [location, dispatch]);

  useEffect(() => {
    const checkAndRefreshToken = () => {
      if (!sessionExpired && currentUser?.jwtToken && tokenHasToBeRefreshed(currentUser.jwtToken, dispatch)) {
        dispatch(refreshToken(currentUser.jwtToken))
      }
    };

    checkAndRefreshToken();

    const interval = setInterval(() => checkAndRefreshToken(), TOKEN_CHECK_INTERVAL);

    return () => {
      clearInterval(interval);
    }
  }, [currentUser, sessionExpired, dispatch]);

  return (
    <HelmetProvider>
      <div className={classNames({
        'app': true,
        'app--logged-in': !!currentUser,
        'app--dark-mode': darkMode
      })}>
        <Helmet>
          <meta charSet="utf-8"/>
          <meta name="viewport" content="initial-scale=1.0,user-scalable=no,width=device-width"/>
          <title>Skillhub VR</title>
        </Helmet>

        <Navigation/>

        <div className="app-wrapper">
          <Switch>
            <Route path="/" exact>
              <Login/>
            </Route>
            <Route path="/login" exact>
              <Login/>
            </Route>
            <PublicRoute path="/forgot-password" exact>
              <ForgotPassword/>
            </PublicRoute>
            <PublicRoute path="/set-password" exact>
              <SetPassword/>
            </PublicRoute>
            <PublicRoute path="/reset-password" exact>
              <ResetPassword/>
            </PublicRoute>
            <ProtectedRoute path="/room-templates" capabilityGuard="edit_templates">
              <RoomTemplateRoute/>
            </ProtectedRoute>
            <ProtectedRoute path="/rooms/:roomId" component={RoomRoute}/>
            <ProtectedRoute path="/rooms">
              <Rooms/>
            </ProtectedRoute>
            <ProtectedRoute path="/groups" capabilityGuard="manage_groups">
              <Groups/>
            </ProtectedRoute>
            <ProtectedRoute path="/settings">
              <Settings/>
            </ProtectedRoute>
            <Route>
              <div className="full-page">
                <Card>This page doesn´t exist.</Card>
              </div>
            </Route>
          </Switch>

          <Overlay visible={sessionExpired && !['/', '/login', '/forgot-password'].includes(props.location.pathname)}>
            <Card>
              <LoginForm/>
            </Card>
          </Overlay>
        </div>
      </div>
    </HelmetProvider>
  );
})