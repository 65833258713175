import {DISPLAY_LOGIN, JWTToken} from "../../modules/auth/types";
import {WP_REST_API_URL} from "../../types";
import {ApiAction, ApiError, isApiAction, ProgressCallbacks} from "./types";
import {ThunkAction} from "redux-thunk";
import {RootState} from "../../configure";
import {ERROR_CODES} from "../../../error-codes";
import {i18n} from "../../../index";
import {getI18n} from "react-i18next";

const axios = require('axios').default;

const catchApiError = (error: ApiError, dispatch: any) => {
  const t = getI18n().t.bind(i18n);

  if (error.request) {
    switch (error.request.status) {
      case 401:
      case 410:
        dispatch({
          type: DISPLAY_LOGIN
        });
        break;

      case 403:
        if (error.request.response.code === 'parallel_rooms_limit_reached') {
          alert(t(ERROR_CODES[error.request.response.code]));
        }

    }
  }
};

const createApiAction = (
  {
    type,
    method = 'GET',
    endpoint,
    bodyArgs,
    payloadCreator = (result: any) => result,
    responseType = 'json',
    onProgress,
    onUploadProgress
  }: ApiAction
): ThunkAction<Promise<any>, RootState, undefined, ApiAction> => {
  return async (dispatch: any, getState: any) => {
    const {auth} = getState();
    const jwtToken: JWTToken = auth.tmpUser?.jwtToken || auth.currentUser?.jwtToken;
    const headers: any = {
      'Authorization': `Bearer ${jwtToken.token}`
    };
    let data;

    if (bodyArgs instanceof FormData) {
      data = bodyArgs;
    } else {
      if (typeof bodyArgs === 'object') {
        data = JSON.stringify(bodyArgs);
      }

      headers['Content-Type'] = 'application/json'
    }

    const config: any = {
      url: WP_REST_API_URL + endpoint,
      method,
      headers,
      data,
      responseType,
      onUploadProgress: onUploadProgress ? (e: any) => {
        if (e.lengthComputable) {
          onUploadProgress(e.loaded / e.total)
        }
      } : undefined,
      onProgress: onProgress ? (e: any) => {
        onProgress(Math.round(e.loaded / e.total))
      } : undefined
    };

    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.request(config);
        const payload = payloadCreator(result.data);

        dispatch({
          type,
          payload
        });

        resolve(payload);
      } catch (e) {
        catchApiError(e, dispatch);

        if (![401, 410, 403].includes(e.request?.status)) {
          reject(e)
        }
      }
    })
  }
};

export const withProgress = (apiAction: ApiAction, progressCallbacks: ProgressCallbacks): ApiAction => {
  return {
    ...apiAction,
    ...progressCallbacks
  }
};

export default (store: any) => (next: any) => (action: any) => {
  if (isApiAction(action)) {
    return next(createApiAction(action));
  } else {
    return next(action)
  }
};