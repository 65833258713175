import React, {useState} from "react";
import {LabelProps} from "./types";
import classNames from "classnames";

export default (props: LabelProps | React.ComponentProps<any>) => {
  const [toggled, setToggled] = useState(props.toggled === true);

  const toggle = (checked: boolean) => {
    setToggled(checked);

    if (props.onToggle) {
      props.onToggle(checked);
    }
  };

  return (
    <div className={classNames({
      'label': true,
      [props.className]: !!props.className
    })}>
      <div className="label-name">
        <label>{props.name}{props.required && <span className="label-required-indicator"/>}</label>
        {props.toggleable &&
        <input type="checkbox" className="label-toggle" onChange={(e) => toggle(e.currentTarget.checked)}
               checked={toggled}/>}
      </div>
      <div style={{
        display: !props.toggleable || toggled ? '' : 'none'
      }} className="label-inputs">
        {props.children}
      </div>
    </div>
  )
}