import React, {ComponentProps, useRef} from "react";
import {CardProps} from "./types";
import classNames from "classnames";
import {useDropzone} from "../../hooks";
import {DragItem} from "../drag-item";

export default (props: CardProps & ComponentProps<any>) => {
  const {
    empty,
    image,
    onDrop,
    sortable,
    children,
    className,
    item,
    ...restProps
  } = props;
  const ref = useRef<HTMLDivElement>(null);

  const [draggingOver] = useDropzone(ref, onDrop);

  const card = (
    <div className={classNames({
      'card': true,
      'card--image': image,
      'card--empty': empty,
      'card--hover': draggingOver,
      'card--dropzone': !!onDrop,
      [className]: !!className
    })} {...restProps} ref={ref}>
      {children}

      {empty && (
        <svg className="card--empty-border" viewBox="0 0 10 10" preserveAspectRatio="none">
          <rect width={10} height={10} stroke="currentColor" strokeWidth={2} strokeDasharray="5 6" fill="none"/>
        </svg>
      )}
    </div>
  );

  if (sortable) {
    return (
      <DragItem item={item}>
        {card}
      </DragItem>
    )
  } else {
    return card
  }
}