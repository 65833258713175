import React, {FormEvent} from "react";
import {UserListItemProps} from "./types";
import {Avatar} from "../avatar";
import {UserRole} from "../../store/modules/user/types";
import {FiCheck, FiClock} from "react-icons/fi";
import {I18N_ADMIN, I18N_AUTHOR, I18N_CONTRIBUTOR, I18N_SUBSCRIBER} from "../../translation";
import {useTranslation} from "react-i18next";

const getUserRoleText = (role: UserRole) => {
  switch (role) {
    case 'administrator':
      return I18N_ADMIN;

    case 'author':
      return I18N_AUTHOR;

    case 'contributor':
      return I18N_CONTRIBUTOR;

    case 'subscriber':
      return I18N_SUBSCRIBER;
  }
};

export default ({user, renderActions, onRoleChange}: UserListItemProps) => {
  const {t} = useTranslation();

  const handleRoleChange = (e: FormEvent<HTMLSelectElement>) => {
    onRoleChange?.(e.currentTarget.value as UserRole)
  };

  return (
    <li className="user-list-item">
      <Avatar url={user.imageUrl ?? ''} key={user.name} size={40}/>
      <div>{user.displayName}</div>
      <div>{user.email}</div>
      {user.confirmed
        ? (
          <div title="Confirmed">
            <FiCheck/>
          </div>
        )
        : (
          <div title="Pending">
            <FiClock/>
          </div>
        )}
      <div>
        {onRoleChange
          ? (
            <select value={user.roles[0]} onChange={handleRoleChange}>
              <option value="subscriber">{t(I18N_SUBSCRIBER)}</option>
              <option value="contributor">{t(I18N_CONTRIBUTOR)}</option>
            </select>
          )
          : t(getUserRoleText(user.roles[0]) as string)
        }
      </div>
      {renderActions()}
    </li>
  )
}