import {Overlay} from "../overlay";
import {
  I18N_ADD_MEDIA,
  I18N_CANCEL,
  I18N_EDIT_MEDIA,
  I18N_FILE,
  I18N_HIDDEN,
  I18N_JSON_DATA,
  I18N_NAME,
  I18N_OBJECT_TAG,
  I18N_SAVE
} from "../../translation";
import {Card} from "../card";
import {Label} from "../label";
import {FileInput} from "../file-input";
import {ObjectTagHiddenPrefix} from "../object-tag/types";
import {JsonEditor} from "../json-editor";
import {Button} from "../button";
import {FiSave} from "react-icons/fi";
import React, {FormEvent, forwardRef, useImperativeHandle, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {MediaOverlayOpenParams, MediaOverlayProps} from "./types";
import {FileInputRef} from "../file-input/types";
import {NO_FILE} from "../../store/types";
import {uploadMedia} from "../../store/modules/room/actions";
import {withProgress} from "../../store/middleware/api/api";
import {useDispatch} from "react-redux";
import {Media} from "../../store/modules/room/types";

export default forwardRef(({objectTags, room}: MediaOverlayProps, ref: any) => {
  const {t} = useTranslation();
  const dispatch: any = useDispatch();

  const fileInputRef = useRef<FileInputRef>(null);
  const [media, setMedia] = useState<Media | undefined>(undefined);
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState<File | NO_FILE | undefined>(null);
  const [json, setJson] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const [objectTag, setObjectTag] = useState('');
  const [uploadProgress, setUploadProgress] = useState<number | undefined>(undefined);
  const jsonPlaceholder = useMemo(() => {
    if (media?.jsonData) {
      try {
        return JSON.parse(media.jsonData)
      } catch (e) {
        return undefined
      }
    } else {
      return undefined
    }
  }, [media]);
  const [isSaving, setIsSaving] = useState(false);

  const open = ({
                  media,
                  objectTag,
                  file
                }: MediaOverlayOpenParams) => {
    setMedia(media);
    setFile(file);
    setName(media?.name);
    setObjectTag(media?.objectTag ?? objectTag ?? room.objectTags?.[0]);
    setJson(media?.jsonData)
    setVisible(true);
  };

  const close = () => {
    fileInputRef.current?.reset();
    setMedia(undefined);
    setFile(null);
    setName(undefined);
    setObjectTag(room.objectTags[0] ?? '');
    setJson(undefined);
    setVisible(false);
  };

  const handleName = (e: FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  };

  const handleObjectTag = (e: FormEvent<HTMLSelectElement>) => {
    setObjectTag(e.currentTarget.value);
  };

  const handleJsonToggle = (checked: boolean) => {
    if (!checked) {
      setJson(undefined)
    } else {
      if (media) {
        setJson(media.jsonData ?? undefined)
      }
    }
  };

  const submitMedia = (e: FormEvent, mediaId: number | undefined = undefined) => {
    e.preventDefault();

    if (room && (file || mediaId) && objectTag) {
      const action = uploadMedia(room, {
        mediaId: mediaId,
        name: name,
        objectTag: objectTag,
        jsonData: json,
        file: file ?? undefined
      });

      setIsSaving(true);

      dispatch(withProgress(action, {
        onUploadProgress: progress => setUploadProgress(progress)
      }))
        .then(close)
        .catch((error: any) => {
          console.error(error)
        })
        .finally(() => {
          setUploadProgress(undefined);
          setIsSaving(false)
        })
    }
  };

  useImperativeHandle(ref, () => ({
    open,
    close
  }));

  return (
    <Overlay
      title={t(media ? I18N_EDIT_MEDIA : I18N_ADD_MEDIA)}
      visible={visible}
      onClose={close}>
      <Card>
        <form onSubmit={(e) => submitMedia(e, media?.mediaId)}>
          <h4 className="card-header">{t(media ? I18N_EDIT_MEDIA : I18N_ADD_MEDIA)}</h4>

          <Label name={t(I18N_FILE)} required>
            <FileInput
              onChange={(file) => setFile(file)}
              previousMedia={media ?? undefined}
              resettable
              value={file ?? undefined}/>
          </Label>

          <Label name={t(I18N_NAME)} required={!!media}>
            <input type="text" required={!!media} value={name} onChange={(e) => handleName(e)}/>
          </Label>

          <Label name={t(I18N_OBJECT_TAG)} required>
            <select onChange={(e) => handleObjectTag(e)} defaultValue={objectTag} required>
              {objectTags.map(objectTag => (
                <option key={objectTag} value={objectTag}>{
                  objectTag.includes(ObjectTagHiddenPrefix)
                    ? objectTag.replace(ObjectTagHiddenPrefix, '') + ` (${t(I18N_HIDDEN)})`
                    : objectTag
                }</option>
              ))}
            </select>
          </Label>

          <Label name={t(I18N_JSON_DATA)} toggleable toggled={!!media?.jsonData}
                 onToggle={handleJsonToggle}>
            <JsonEditor
              placeholder={jsonPlaceholder}
              onChange={(input: any) => {
                return setJson(input.json)
              }}/>
          </Label>

          <div className="buttons-container">
            <Button type="cancel" onClick={close}>{t(I18N_CANCEL)}</Button>
            <Button type="submit" progress={uploadProgress} isLoading={isSaving}>
              <FiSave/>
              {t(I18N_SAVE)}
            </Button>
          </div>
        </form>
      </Card>
    </Overlay>
  )
})