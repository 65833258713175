import {User, UserRole} from "../user/types";

export const GET_ROOMS = 'SET_ROOMS';
export const CREATE_ROOM = 'CREATE_ROOM';
export const UPDATE_ROOM = 'UPDATE_ROOM';
export const DELETE_ROOM = 'DELETE_ROOM';
export const START_MEETING = 'START_MEETING';
export const END_MEETING = 'END_MEETING';
export const GET_MEDIA = 'GET_MEDIA';
export const UPLOAD_MEDIA = 'UPLOAD_MEDIA';
export const DOWNLOAD_MEDIA = 'DOWNLOAD_MEDIA';
export const DELETE_MEDIA = 'DELETE_MEDIA';
export const GET_URLS = 'GET_URLS';
export const ADD_URL = 'ADD_URL';
export const DELETE_URL = 'DELETE_URL';
export const INVITE_USER_TO_ROOM = 'INVITE_USER_TO_ROOM';
export const REMOVE_USER_FROM_ROOM = 'REMOVE_USER_FROM_ROOM';
export const GET_ROOM_TEMPLATES = 'GET_ROOM_TEMPLATES';
export const CREATE_OR_UPDATE_ROOM_TEMPLATE = 'CREATE_OR_UPDATE_ROOM_TEMPLATE';
export const DELETE_ROOM_TEMPLATE = 'DELETE_ROOM_TEMPLATE';
export const SET_MEDIA_ORDER = 'SET_MEDIA_ORDER';
export const CHANGE_USER_ROLE = 'CHANGE_USER_ROLE';

export type ObjectTag = string

type FileType = 'image' | 'pdf' | 'zip' | 'text'

export interface UploadMedia {
  mediaId?: number;
  name?: string;
  file?: File;
  jsonData?: string;
  objectTag: ObjectTag;
}

export interface Media {
  mediaId: number;
  name: string;
  fileName: string;
  fileType: FileType;
  url: string;
  jsonData?: string;
  objectTag: ObjectTag;
}

interface MediaOrderMap {
  [key: string]: number[];
}

export interface Url {
  urlId?: number;
  name: string;
  url: string;
  objectTag: ObjectTag;
}

export interface UploadRoom {
  roomId?: number;
  roomTemplateId?: number;
  name: string;
  objectTags?: ObjectTag[];
  thumbnail?: File;
  description?: string;
  jsonData?: string;
}

export interface Room {
  roomId?: number;
  name: string;
  thumbnail: string;
  urls: Url[];
  media: Media[];
  users: User[];
  objectTags: ObjectTag[];
  active: boolean;
  description?: string;
  mediaOrder: MediaOrderMap;
  jsonData?: string;
  roomTemplate: RoomTemplate;
}

export interface UpdateRoomTemplate {
  roomTemplateId?: number;
  name: string;
  thumbnail?: File;
  objectTags: ObjectTag[];
  jsonData?: string;
}

export interface RoomTemplate {
  roomTemplateId: number;
  ownerId: number;
  name: string;
  thumbnail: string;
  objectTags: ObjectTag[];
  jsonData?: string;
}

export interface RoomState {
  rooms: Room[];
  templates: RoomTemplate[];
  isLoading: boolean;
}

interface GetRoomsAction {
  type: typeof GET_ROOMS;
  payload: Room[];
}

interface CreateRoomAction {
  type: typeof CREATE_ROOM;
  payload: Room;
}

interface UpdateRoomAction {
  type: typeof UPDATE_ROOM;
  payload: Room;
}

interface DeleteRoomAction {
  type: typeof DELETE_ROOM;
  payload: Room;
}

interface StartMeetingAction {
  type: typeof START_MEETING;
  payload: Room;
}

interface EndMeetingAction {
  type: typeof END_MEETING;
  payload: Room;
}

interface GetMediaAction {
  type: typeof GET_MEDIA;
  payload: {
    room: Room,
    media: Media[]
  };
}

interface UploadMediaAction {
  type: typeof UPLOAD_MEDIA;
  payload: {
    room: Room,
    media: Media
  };
}

interface DeleteMediaAction {
  type: typeof DELETE_MEDIA;
  payload: Media
}

interface DownloadMediaAction {
  type: typeof DOWNLOAD_MEDIA;
  payload: Blob
}

interface GetUrlsAction {
  type: typeof GET_URLS;
  payload: {
    room: Room,
    urls: Url[]
  };
}

interface AddUrlAction {
  type: typeof ADD_URL;
  payload: {
    room: Room,
    url: Url
  };
}

interface DeleteUrlAction {
  type: typeof DELETE_URL;
  payload: Url
}

interface InviteUserAction {
  type: typeof INVITE_USER_TO_ROOM;
  payload: {
    user: User,
    room: Room
  };
}

interface RemoveUserAction {
  type: typeof REMOVE_USER_FROM_ROOM;
  payload: {
    user: User,
    room: Room
  };
}

interface GetRoomTemplatesAction {
  type: typeof GET_ROOM_TEMPLATES;
  payload: RoomTemplate[];
}

interface CreateOrUpdateRoomTemplateAction {
  type: typeof CREATE_OR_UPDATE_ROOM_TEMPLATE;
  payload: RoomTemplate;
}

interface DeleteRoomTemplateAction {
  type: typeof DELETE_ROOM_TEMPLATE;
  payload: RoomTemplate;
}

interface SetMediaOrderAction {
  type: typeof SET_MEDIA_ORDER;
  payload: {
    room: Room,
    objectTag: ObjectTag,
    order: number[]
  };
}

interface ChangeUserRoleAction {
  type: typeof CHANGE_USER_ROLE;
  payload: {
    room: Room,
    user: User,
    role: UserRole
  };
}

export type RoomActionTypes =
  GetRoomsAction
  | CreateRoomAction
  | UpdateRoomAction
  | DeleteRoomAction
  | StartMeetingAction
  | EndMeetingAction
  | GetMediaAction
  | UploadMediaAction
  | DownloadMediaAction
  | DeleteMediaAction
  | GetUrlsAction
  | AddUrlAction
  | DeleteUrlAction
  | InviteUserAction
  | RemoveUserAction
  | GetRoomTemplatesAction
  | CreateOrUpdateRoomTemplateAction
  | DeleteRoomTemplateAction
  | SetMediaOrderAction
  | ChangeUserRoleAction;