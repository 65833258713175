import React, {ComponentProps} from "react";
import {ButtonProps} from "./types";
import classNames from 'classnames';
import {Loader} from "../loader";


export default (props: ButtonProps | ComponentProps<any> = {
  type: 'cancel',
  icon: false,
  small: false,
  asButton: false,
  disabled: false
}) => {
  const ElementType = props.asButton ? 'div' : 'button';

  const nativeProps: any = {};

  if (ElementType === 'button') {
    nativeProps.disabled = props.disabled;
    nativeProps.type = props.type === 'submit' ? 'submit' : 'button';
  }

  return (
    <ElementType className={classNames({
      'button': true,
      'button--disabled': props.disabled,
      'button--loading': props.isLoading || props.progress !== undefined,
      'button--outline': props.type === 'outline' || props.type === 'cancel',
      'button--negative': props.type === 'negative',
      'button--icon': props.icon === true,
      'button--small': props.small === true,
    })} onClick={props.onClick} {...nativeProps}>
      <span className="button-content">{props.children}</span>
      {props.progress !== undefined && (
        <span className="button-progress" style={{
          transform: `scaleX(${props.progress})`
        }}/>
      )}
      {props.isLoading !== undefined && (
        <span className="button-absolute">
          <Loader size="small"/>
          {props.progress !== undefined && (
            <span className="button-progress-text">{Math.round(props.progress * 100)}%</span>
          )}
        </span>
      )}
    </ElementType>
  )
}