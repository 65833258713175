import {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {Room} from "../store/modules/room/types";
import {RootState} from "../store/configure";

export default (roomId: number) => {
  const {rooms, isLoading} = useSelector((state: RootState) => state.rooms);
  const [error, setError] = useState<string | undefined>(undefined);

  const room: Room | undefined = useMemo(() => {
    const result = rooms.find(r => r.roomId === roomId);

    setError(isLoading || result ? undefined : 'Room not found');

    return result;
  }, [roomId, rooms, isLoading]);

  return {room, error}
};