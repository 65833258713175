import React, {FormEvent, useState} from 'react';
import {Card} from "../../components/card";
import {Label} from "../../components/label";
import {promisifyFetch} from "../../helpers/api";
import {WP_REST_API_URL} from "../../store/types";
import {Button} from "../../components/button";
import {Link} from "react-router-dom";
import {ERROR_CODES} from "../../error-codes";
import {
  I18N_BACK_TO_LOGIN,
  I18N_EMAIL,
  I18N_RESET_PASSWORD,
  I18N_RESET_PASSWORD_EMAIL_CONFIRMATION,
  I18N_RESET_PASSWORD_SUCCESS_TITLE,
  I18N_SEND_RESET_EMAIL,
  REPLACE_I18N_TOKEN
} from "../../translation";
import {useTranslation} from "react-i18next";

const requestPasswordResetEmail = (email: string) => {
  return promisifyFetch(`${WP_REST_API_URL}users/request-reset-password`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email
    })
  })
};

export default () => {
  const {t} = useTranslation();
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleEmail = (e: FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value)
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    setIsLoading(true);

    requestPasswordResetEmail(email)
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        setSuccess(false);
        setError(t(ERROR_CODES[error.code]))
      })
      .finally(() => {
        setIsLoading(false);
      })
  };

  return (
    <div className="full-page">
      <Card style={{'maxWidth': '400px'}}>
        <form onSubmit={handleSubmit} autoComplete="off" className="login-form">
          {success
            ? (
              <>
                <h1>{t(I18N_RESET_PASSWORD_SUCCESS_TITLE)}</h1>
                <p className="big-text"
                   dangerouslySetInnerHTML={{__html: t(I18N_RESET_PASSWORD_EMAIL_CONFIRMATION).replace(REPLACE_I18N_TOKEN, email)}}/>

                <div className="buttons-container">
                  <Link className="button" to="/">{t(I18N_BACK_TO_LOGIN)}</Link>
                </div>
              </>
            )
            : (
              <>
                <h3 style={{'marginBottom': '0.3em'}}>{t(I18N_RESET_PASSWORD)}</h3>

                <Label name={t(I18N_EMAIL)}>
                  <input type="email" autoComplete="username" required value={email} onChange={handleEmail}/>
                </Label>

                <div className="buttons-container">
                  <Button type="submit" isLoading={isLoading}>{t(I18N_SEND_RESET_EMAIL)}</Button>
                </div>

                {error && <div className="error-message">{error}</div>}

                <Link to="/" className="link small-text" style={{marginTop: '10px', alignSelf: 'center'}}>
                  {t(I18N_BACK_TO_LOGIN)}
                </Link>
              </>
            )
          }
        </form>
      </Card>
    </div>
  )
}