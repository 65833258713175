import {
  AUTH_SET_DONE,
  AUTH_SET_PENDING,
  AuthState,
  AuthUser,
  DISPLAY_LOGIN,
  LOGIN,
  LOGOUT,
  REFRESH_CURRENT_USER,
  REFRESH_TOKEN,
  SET_AUTH_ERROR,
  UPDATE_USER,
} from "./types";

const initialState: AuthState = {
  currentUser: null,
  authError: null,
  isLoading: false,
  tmpUser: null,
  sessionExpired: false
};

export default (state = initialState, action: any): AuthState => {
  switch (action.type) {
    case AUTH_SET_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case AUTH_SET_DONE:
      return {
        ...state,
        isLoading: false
      };

    case LOGIN:
      return {
        ...state,
        currentUser: action.payload,
        authError: null,
        tmpUser: null,
        sessionExpired: false
      };

    case LOGOUT:
      return {
        ...initialState
      };

    case SET_AUTH_ERROR:
      return {
        ...state,
        authError: action.payload,
        isLoading: false
      };

    case UPDATE_USER:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload
        },
      };

    case REFRESH_CURRENT_USER:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload
        },
      };

    case REFRESH_TOKEN:
      console.log('new token', action.payload);

      return {
        ...state,
        currentUser: {
          ...(state.currentUser as AuthUser),
          jwtToken: action.payload
        }
      };

    case DISPLAY_LOGIN:
      return {
        ...state,
        sessionExpired: true
      };

    default:
      return state;
  }
};