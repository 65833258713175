import React from "react";
import {DragContextState} from "../../helpers/types";

const defaultDragContext: DragContextState = {
  isDragging: false,
  setIsDragging: () => {
  },
  initDrag: () => {
  }
};

const context = React.createContext(defaultDragContext);

export default context;