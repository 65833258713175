import {SET_DARK_MODE, SET_LANGUAGE, SettingsActionTypes, SettingsState} from "./types";

const initialState: SettingsState = {
  darkMode: false,
  language: 'de',
};

export default (state = initialState, action: SettingsActionTypes) => {
  switch (action.type) {
    case SET_DARK_MODE:
      return {
        ...state,
        darkMode: action.payload
      };

    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload
      };

    default:
      return state;
  }
}