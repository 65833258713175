import React from 'react';
import {IconProps} from "../types";

export default (props: IconProps) => {
  return (
    <svg width={props.width ?? 30} height={props.height ?? 30} viewBox="0 0 30 30">
      <g transform="translate(7, 10)">
        <path d="M6,8H5C3.3,8,2,6.7,2,5c0-1.7,1.3-3,3-3h1V0H5C2.2,0,0,2.2,0,5c0,2.8,2.2,5,5,5h1V8z"
              fill="currentColor"/>
        <path d="M11,0h-1v2h1c1.7,0,3,1.3,3,3c0,1.7-1.3,3-3,3h-1v2h1c2.8,0,5-2.2,5-5C16,2.2,13.8,0,11,0z"
              fill="currentColor"/>
        <rect x="5" y="4" width="6" height="2" fill="currentColor"/>
      </g>
    </svg>
  )
}