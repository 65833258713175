import AvatarEditor from "react-avatar-editor";
import {Button} from "../button";
import {FiRotateCcw, FiRotateCw} from "react-icons/all";
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../store/configure";
import {AvatarEditorProps} from "./types";
import ReactSlider from 'react-slider';
import {FileInput} from "../file-input";
import {FileInputRef} from "../file-input/types";
import {NO_FILE} from "../../store/types";

export default forwardRef(({imageSource, required}: AvatarEditorProps, ref) => {
  const {darkMode} = useSelector((state: RootState) => state.settings);
  const avatarEditorRef = useRef<any>();
  const [file, setFile] = useState<File | NO_FILE | undefined>(undefined);
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const [changed, setChanged] = useState(false);
  const fileInputRef = useRef<FileInputRef>();

  const rotateRight = () => setRotation((rotation + 90) % 360);
  const rotateLeft = () => setRotation((rotation + 270) % 360);
  const handleScale = (scale: any) => setScale(scale);
  const fileName = imageSource?.split('/').reverse()[0] ?? '';

  const handleImageChange = () => {
    setChanged(true);
  };

  const handleFileChange = (file: File | undefined) => {
    if (file) {
      setFile(file);
    }
  };

  const reset = () => {
    fileInputRef.current?.reset();
    setRotation(0);
    setScale(1);
    setChanged(false);
    setFile(undefined);
  };

  const getFile = async () => {
    if (!avatarEditorRef.current) {
      return undefined
    }

    if (changed) {
      const type = 'image/jpeg';
      const avatarDataUrl = avatarEditorRef.current.getImageScaledToCanvas().toDataURL(type, .92);
      const blob = await fetch(avatarDataUrl)
        .then(r => r.blob());

      return new File([blob], file?.name ?? fileName, {
        lastModified: file?.lastModified ?? new Date().getTime(),
        type
      })
    }
  };

  const hasChanged = () => changed;

  useEffect(() => {
    if (file) {
      setRotation(0);
      setScale(1);
      setChanged(true)
    }
  }, [file]);

  useImperativeHandle(ref, () => ({
    getFile,
    reset,
    hasChanged
  }));

  return (
    <div className="AvatarEditorWrapper">
      {(file || imageSource) && (
        <>
          <AvatarEditor
            ref={avatarEditorRef}
            image={(file ?? imageSource) as (File | string)}
            rotate={rotation}
            scale={scale}
            onImageChange={handleImageChange}
            crossOrigin="anonymous"
            border={20}
            borderRadius={100}
            color={darkMode ? [2, 1, 2, .6] : [255, 255, 255, .6]}
            width={200}
            height={200}/>

          <div className="AvatarEditorActions">
            <Button type="outline" icon small onClick={rotateLeft}><FiRotateCcw/></Button>
            <ReactSlider
              className="slider"
              thumbClassName="slider-thumb"
              trackClassName="slider-track"
              value={scale}
              min={1}
              max={1.4}
              step={0.01}
              onChange={handleScale}/>
            <Button type="outline" icon small onClick={rotateRight}><FiRotateCw/></Button>
          </div>
        </>
      )}

      <FileInput
        required={required}
        ref={fileInputRef}
        resettable={!required}
        onReset={() => reset()}
        onChange={(file) => handleFileChange(file ?? undefined)}
        showPreview={false}/>
    </div>
  )
})