import React from 'react';
import {IconProps} from "../types";

export default (props: IconProps) => {
  return (
    <svg width={props.width ?? 30} height={props.height ?? 30} viewBox="0 0 30 30">
      <g transform="translate(11,9)">
        <polygon
          points="7.3,1 7.3,0 6.1,0 4.1,0 2.6,0 2.6,1 3.9,1 1.4,12 0,12 0,13 1.1,13 3.1,13 4.5,13 4.5,12 3.4,12 5.9,1"
          fill="currentColor"/>
      </g>
    </svg>
  )
}