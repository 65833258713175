import React, {FormEvent, useEffect, useMemo, useState} from 'react';
import {UserIcon} from "../../icons";
import {Card} from "../../components/card";
import {Grid} from "../../components/grid";
import {Overlay} from "../../components/overlay";
import {Label} from "../../components/label";
import {Button} from "../../components/button";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/configure";
import {UserSelect} from "../../components/user-select";
import {User, UserGroup} from "../../store/modules/user/types";
import {
  addCap,
  createOrUpdateGroup,
  deleteCap,
  deleteGroup,
  grantCapability,
  revokeCapability
} from "../../store/modules/user/actions";
import {AvatarList} from "../../components/avatar";
import {FiTrash2} from "react-icons/all";
import {NumberInput} from "../../components/number-input";
import {
  I18N_ADD_GROUP,
  I18N_ADD_NEW_CAPABILITY,
  I18N_CANCEL,
  I18N_CAPABILITIES,
  I18N_CAPABILITY_PRESS_ENTER,
  I18N_CREATE,
  I18N_CREATE_NEW_GROUP,
  I18N_CUSTOM_CAPABILITIES,
  I18N_DELETE_CAP_CONFIRMATION,
  I18N_DELETE_GROUP,
  I18N_DELETE_GROUP_CONFIRMATION,
  I18N_EDIT,
  I18N_EDIT_GROUP,
  I18N_MEMBER,
  I18N_MEMBERS,
  I18N_MORE_ACTIONS,
  I18N_NAME,
  I18N_PARALLEL_MEETINGS,
  I18N_REMOVE,
  I18N_SAVE,
  I18N_USER,
  I18N_USER_GROUPS,
  I18N_USERS,
  REPLACE_I18N_TOKEN
} from "../../translation";
import {useTranslation} from "react-i18next";
import {UserDropdown} from "../../components/user-dropdown";
import {AddInput} from "../../components/add-input";

const DEFAULT_PARALLEL_MEETINGS = 1;

export default () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {groups, users: adminsAndAuthors, capabilities} = useSelector((state: RootState) => state.users);

  const unassignedUsers = useMemo(() => {
    const emptyUsersArray: User[] = [];
    const assignedUsers = groups.reduce((acc, curr) => [...acc, ...curr.users], emptyUsersArray);
    return adminsAndAuthors.filter(user => !assignedUsers.find(assignedUser => assignedUser.userId === user.userId))
  }, [adminsAndAuthors, groups]);

  /* --- Groups form --- */
  const [formVisible, setFormVisible] = useState(false);
  const [editedGroup, setEditedGroup] = useState<UserGroup | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [groupName, setGroupName] = useState('');
  const [parallelMeetings, setParallelMeetings] = useState<number | undefined>(undefined);

  /* --- Capabilities form --- */
  const [selectedUser, setSelectedUser] = useState<User>(adminsAndAuthors[0]);

  useEffect(() => {
    if (selectedUser) {
      setSelectedUser(adminsAndAuthors.find(user => user.userId === selectedUser.userId) as User)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminsAndAuthors])

  const editGroup = (group: UserGroup) => {
    setGroupName(group.name);
    setUsers(group.users);
    setParallelMeetings(group.parallelMeetings);
    setEditedGroup(group);
    setFormVisible(true);
  };

  const addGroup = () => {
    setFormVisible(true);
    setEditedGroup(null);
    setGroupName('');
    setUsers([]);
    setParallelMeetings(undefined);
  };

  const stopEditing = () => {
    setFormVisible(false);
    setUsers([]);
    setEditedGroup(null);
    setGroupName('');
  };

  const promptDeleteGroup = (group: UserGroup) => {
    if (window.confirm(t(I18N_DELETE_GROUP_CONFIRMATION))) {
      dispatch(deleteGroup(group));
      stopEditing()
    }
  };

  const handleGroupName = (e: FormEvent<HTMLInputElement>) => {
    setGroupName(e.currentTarget.value)
  };

  const handleParallelMeetings = (value: number) => {
    if (parallelMeetings) {
      setParallelMeetings(value);
    }
  };

  const handleToggleParallelMeetings = (toggled: boolean) => {
    if (!toggled) {
      setParallelMeetings(undefined);
    } else {
      setParallelMeetings(DEFAULT_PARALLEL_MEETINGS);
    }
  };

  const handleSubmitGroup = (e: FormEvent) => {
    e.preventDefault();

    if (groupName && users.length) {
      dispatch(createOrUpdateGroup({
        groupId: editedGroup?.groupId,
        name: groupName,
        users: users,
        parallelMeetings: parallelMeetings
      }));

      setFormVisible(false)
    }
  };

  const handleSelectUser = (user: User) => {
    setSelectedUser(user)
  };

  const handleAddCapability = (capability: string) => {
    dispatch(addCap(capability))
  };

  const handleUserCapability = (e: FormEvent<HTMLInputElement>, capability: string) => {
    if (e.currentTarget.checked) {
      dispatch(grantCapability(selectedUser, capability))
    } else {
      dispatch(revokeCapability(selectedUser, capability))
    }
  };

  const handleDeleteCapability = (capability: string) => {
    const promptText = t(I18N_DELETE_CAP_CONFIRMATION).replace(REPLACE_I18N_TOKEN, capability);

    if (window.confirm(promptText)) {
      dispatch(deleteCap(capability))
    }
  }

  return (
    <>
      <section key="groups">
        <h2>{t(I18N_USER_GROUPS)}</h2>

        <Grid>
          {groups.length > 0 && groups.map(group => (
            <Card className="group-card" key={group.name}>
              <div className="card-content card-content--centered">
                <AvatarList urls={group.users.map(user => user.imageUrl)} alts={users.map(user => user.displayName)}/>
                <h3 className="card-title">{group.name}</h3>
                <div>{group.users.length} {t(group.users.length === 1 ? I18N_MEMBER : I18N_MEMBERS)}</div>
              </div>
              <Button onClick={() => editGroup(group)}>{t(I18N_EDIT)}</Button>
            </Card>
          ))}
          <Card empty onClick={addGroup}>
            <UserIcon width={60} height={65}/>
            <div>{t(I18N_ADD_GROUP)}</div>
          </Card>
        </Grid>
      </section>

      <section key="capabilities">
        <h2>{t(I18N_CUSTOM_CAPABILITIES)}</h2>

        <Card className="settings-card">
          <Label name={t(I18N_USER)}>
            <UserDropdown
              items={adminsAndAuthors}
              value={selectedUser}
              onChange={handleSelectUser}/>
          </Label>

          <Label name={t(I18N_CAPABILITIES)}>
            <ul className="capability-list">
              {capabilities.map((capability) => (
                <li key={capability}>
                  <label>
                    <input
                      type="checkbox"
                      checked={!!selectedUser?.caps[capability]}
                      onChange={(e) => handleUserCapability(e, capability)}/>
                    {capability}
                  </label>
                  <span
                    className="link"
                    onClick={() => handleDeleteCapability(capability)}>
                    <FiTrash2/>
                    {t(I18N_REMOVE)}
                  </span>
                </li>
              ))}
            </ul>

            <AddInput
              infoText={t(I18N_CAPABILITY_PRESS_ENTER)}
              placeholder={t(I18N_ADD_NEW_CAPABILITY)}
              onChange={handleAddCapability}/>
          </Label>
        </Card>
      </section>

      <Overlay visible={formVisible} onClose={stopEditing}>
        <Card className="overflow-visible user-group-edit">
          <section className="card-section card-section--form">
            <form onSubmit={handleSubmitGroup}>
              <h4>{t(editedGroup ? I18N_EDIT_GROUP : I18N_CREATE_NEW_GROUP)}</h4>

              <Label name={t(I18N_NAME)} required>
                <input type="text" required value={groupName} onChange={handleGroupName}/>
              </Label>

              <Label name={t(I18N_USERS)} required>
                <UserSelect required value={users} users={unassignedUsers} onChange={setUsers}/>
              </Label>

              <Label name={t(I18N_PARALLEL_MEETINGS)} toggleable toggled={!!parallelMeetings}
                     onToggle={handleToggleParallelMeetings}>
                <NumberInput required={!!parallelMeetings} value={parallelMeetings} min={1} max={50}
                             onChange={handleParallelMeetings}/>
              </Label>

              <div className="buttons-container">
                <Button type="cancel" onClick={stopEditing}>{t(I18N_CANCEL)}</Button>
                <Button type="submit">{t(editedGroup ? I18N_SAVE : I18N_CREATE)}</Button>
              </div>
            </form>
          </section>

          {editedGroup && <section className="card-section">
              <h4>{t(I18N_MORE_ACTIONS)}</h4>
              <span className="link room-card-delete" onClick={() => promptDeleteGroup(editedGroup)}>
              <FiTrash2/>
                {t(I18N_DELETE_GROUP)}
            </span>
          </section>}
        </Card>
      </Overlay>
    </>
  )
}