import React from "react";
import {IconProps} from "./types";

export default (props: IconProps) => (
  <svg className="icon" width={props.width ?? 26.973} height={props.height ?? 29.921} viewBox="0 0 26.973 29.921">
    <g transform="translate(-66.22 -177.703)">
      <path d="M92.193,171.847l-12.487-7.488L67.22,171.847v13.307l12.487,7.126,12.487-7.126Z"
            transform="translate(0 14.344)" fill="none" stroke="currentColor" strokeLinecap="round"
            strokeLinejoin="round" strokeWidth="2"/>
      <path d="M67.22,205.734,79.707,198.4l12.487,7.329" transform="translate(0 -6.329)" fill="none"
            stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
      <line y2="13.122" transform="translate(79.709 178.831)" fill="none" stroke="currentColor" strokeLinecap="round"
            strokeWidth="1"/>
    </g>
  </svg>
)