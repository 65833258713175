import React, {FormEvent, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card} from "../../components/card";
import {Grid} from "../../components/grid";
import {Button} from "../../components/button";
import {FiKey, FiLock, FiTrash2} from "react-icons/all";
import {Overlay} from "../../components/overlay";
import {createOrUpdateRoomTemplate, deleteRoomTemplate, getRooms} from "../../store/modules/room/actions";
import {ObjectTag, RoomTemplate} from "../../store/modules/room/types";
import {ObjectTagsInput} from "../../components/object-tags-input";
import {RoomTemplateIcon} from "../../icons";
import {Label} from "../../components/label";
import {Avatar} from "../../components/avatar";
import {grantTemplateAccess, revokeTemplateAccess} from "../../store/modules/user/actions";
import {NO_FILE} from "../../store/types";
import {FileInput} from "../../components/file-input";
import {ObjectTagComponent} from "../../components/object-tag";
import {RootState} from "../../store/configure";
import {useTranslation} from "react-i18next";
import {
  I18N_ACCESS_NO_USERS,
  I18N_CANCEL,
  I18N_CREATE,
  I18N_CREATE_NEW_TEMPLATE,
  I18N_CREATE_TEMPLATE,
  I18N_DELETE_CONFIRMATION,
  I18N_DELETE_TEMPLATE,
  I18N_EDIT,
  I18N_FILE,
  I18N_GRANT,
  I18N_JSON_DATA,
  I18N_MORE_ACTIONS,
  I18N_NAME,
  I18N_NO_OBJECT_TAGS,
  I18N_OBJECT_TAGS,
  I18N_PERMISSIONS,
  I18N_REVOKE,
  I18N_ROOM_TEMPLATES,
  I18N_SAVE,
  I18N_TEMPLATE_SETTINGS,
  REPLACE_I18N_TOKEN
} from "../../translation";
import {JsonEditor} from "../../components/json-editor";

export default () => {
  const {t} = useTranslation();

  const dispatch: any = useDispatch();
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const templates = useSelector((state: RootState) => state.rooms.templates.filter(template => template.ownerId === currentUser?.userId));
  const users = useSelector((state: RootState) => state.users.users);

  // currently edited template
  const [editFormVisible, setEditFormVisible] = useState(false);
  const [editedTemplate, setEditedTemplate] = useState<RoomTemplate | null>(null);
  const [thumbnail, setThumbnail] = useState<File | NO_FILE | undefined>(undefined);
  const [name, setName] = useState('');
  const [objectTags, setObjectTags] = useState<ObjectTag[]>([]);
  const [json, setJson] = useState<string | undefined>(undefined);
  const jsonPlaceholder = useMemo(() => {
    if (editedTemplate?.jsonData) {
      try {
        return JSON.parse(editedTemplate.jsonData)
      } catch (e) {
        return undefined
      }
    } else {
      return undefined
    }
  }, [editedTemplate]);
  const currentTemplate = useMemo(() => {
    return templates.find(template => template.roomTemplateId === editedTemplate?.roomTemplateId) ?? null
  }, [templates, editedTemplate]);

  // new template
  const [templateFormVisible, setTemplateFormVisible] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [newTemplateObjectTags, setNewTemplateObjectTags] = useState<ObjectTag[]>([]);
  const [newTemplateJson, setNewTemplateJson] = useState<string | undefined>(undefined);
  const [newTemplateThumbnail, setNewTemplateThumbnail] = useState<File | NO_FILE | undefined>(undefined);

  const [isSaving, setIsSaving] = useState(false);

  const edit = (roomTemplate: RoomTemplate) => {
    const template = templates.find(template => template.roomTemplateId === roomTemplate.roomTemplateId);

    if (template) {
      setEditFormVisible(true);
      setEditedTemplate(template);
      setName(template.name);
      setObjectTags(template.objectTags);
      setJson(template.jsonData);
    }
  };

  const stopEditing = () => {
    setEditFormVisible(false);
    setEditedTemplate(null);
    setObjectTags([]);
    setName('');
  };

  const handleName = (e: FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  };

  const handleNewTemplateName = (e: FormEvent<HTMLInputElement>) => {
    setNewTemplateName(e.currentTarget.value);
  };

  const handleJsonToggle = (toggled: boolean) => {
    if (!toggled) {
      setJson(undefined)
    }
  };

  const handleJson = (input: any) => setJson(input.json);

  const handleNewTemplateJsonToggle = (toggled: boolean) => {
    if (!toggled) {
      setNewTemplateJson(undefined)
    }
  };

  const handleNewTemplateJson = (input: any) => setNewTemplateJson(input.json);

  const submitCreateTemplate = (e: FormEvent) => {
    e.preventDefault();

    if (newTemplateName && newTemplateThumbnail) {
      dispatch(createOrUpdateRoomTemplate({
        name: newTemplateName,
        thumbnail: newTemplateThumbnail,
        objectTags: newTemplateObjectTags,
        jsonData: newTemplateJson
      }))
        .then(() => {
          dispatch(getRooms())
        });

      setTemplateFormVisible(false)
    }
  };

  const submitUpdateTemplate = (e: FormEvent) => {
    e.preventDefault();

    if (editedTemplate && name) {
      setIsSaving(true);

      dispatch(createOrUpdateRoomTemplate({
        roomTemplateId: editedTemplate.roomTemplateId,
        thumbnail: thumbnail ?? undefined,
        name,
        objectTags,
        jsonData: json
      }))
        .then(() => {
          dispatch(getRooms());
          stopEditing()
        })
        .finally(() => {
          setIsSaving(false)
        })
    }
  };

  const promptDeleteTemplate = () => {
    const roomTemplate = templates.find(template => template.roomTemplateId === editedTemplate?.roomTemplateId);

    if (!roomTemplate) {
      return
    }

    const promptText = t(I18N_DELETE_CONFIRMATION).replace(REPLACE_I18N_TOKEN, roomTemplate.name);

    if (window.confirm(promptText)) {
      dispatch(deleteRoomTemplate(roomTemplate));
      stopEditing()
    }
  };

  return (
    <>
      <h2>{t(I18N_ROOM_TEMPLATES)}</h2>

      <Grid>
        {templates.length ? templates.map(template => (
          <Card key={template.roomTemplateId}>
            <div className="card-image">
              <h4 className="card-title">{template.name}</h4>

              <img src={template.thumbnail} alt={template.name}/>
            </div>

            {template.objectTags.length
              ? (
                <div className="tags">
                  {template.objectTags.map(objectTag => (
                    <ObjectTagComponent objectTag={objectTag} key={objectTag}/>
                  ))}
                </div>
              )
              : <div>{t(I18N_NO_OBJECT_TAGS)}</div>
            }

            <Button onClick={() => edit(template)}>
              {t(I18N_EDIT)}
            </Button>
          </Card>
        )) : null}

        <Card empty={true} onClick={() => setTemplateFormVisible(true)}>
          <RoomTemplateIcon width={70} height={66}/>
          <div>{t(I18N_CREATE_TEMPLATE)}</div>
        </Card>
      </Grid>

      <Overlay visible={editFormVisible} onClose={() => setEditFormVisible(false)}>
        <Card>
          <section className="card-section card-section--form">
            <form onSubmit={submitUpdateTemplate}>
              <h4 className="card-header">{t(I18N_TEMPLATE_SETTINGS)}</h4>
              <Label name={t(I18N_NAME)} required>
                <input type="text" required value={name} onChange={handleName}/>
              </Label>

              <Label name={t(I18N_FILE)} required>
                <FileInput
                  resettable
                  onChange={(file) => setThumbnail(file)}
                  previousMedia={editedTemplate
                    ? {
                      url: editedTemplate.thumbnail,
                      name: editedTemplate.name,
                      fileType: 'image'
                    }
                    : undefined
                  }/>
              </Label>

              <Label name={t(I18N_OBJECT_TAGS)} required>
                <ObjectTagsInput required value={objectTags} onChange={(objectTags: ObjectTag[]) => {
                  setObjectTags(objectTags)
                }}/>
              </Label>

              <Label name={t(I18N_JSON_DATA)} toggleable toggled={!!editedTemplate?.jsonData}
                     onToggle={handleJsonToggle}>
                <JsonEditor placeholder={jsonPlaceholder} onChange={handleJson}/>
              </Label>

              <div className="buttons-container">
                <Button type="cancel" onClick={stopEditing}>{t(I18N_CANCEL)}</Button>
                <Button type="submit" isLoading={isSaving}>{t(I18N_SAVE)}</Button>
              </div>
            </form>
          </section>

          <section className="card-section">
            <h4>{t(I18N_PERMISSIONS)}</h4>

            {users.length && currentUser && currentTemplate
              ? (
                <ul className="user-list">
                  {users.filter(user => user.userId !== currentUser.userId).map(user => (
                    <li className="user" key={user.userId}>
                      <Avatar url={user.imageUrl ?? ''} key={user.name} size={40}/>
                      <div className="user-info">{user.displayName}</div>

                      {user.caps?.[`use_template_${currentTemplate?.roomTemplateId}`]
                        ? (
                          <span
                            className="link"
                            onClick={() => dispatch(revokeTemplateAccess(user, currentTemplate))}>
                            <FiLock/>
                            {t(I18N_REVOKE)}
                          </span>
                        )
                        : (
                          <span
                            className="link"
                            onClick={() => dispatch(grantTemplateAccess(user, currentTemplate))}>
                            <FiKey/>
                            {t(I18N_GRANT)}
                          </span>
                        )}
                    </li>
                  ))}
                </ul>
              )
              : (
                <div>{t(I18N_ACCESS_NO_USERS)}</div>
              )}
          </section>

          <section className="card-section">
            <h4>{t(I18N_MORE_ACTIONS)}</h4>
            <span className="link room-card-delete" onClick={() => promptDeleteTemplate()}>
              <FiTrash2/>
              {t(I18N_DELETE_TEMPLATE)}
            </span>
          </section>
        </Card>
      </Overlay>

      <Overlay visible={templateFormVisible} onClose={() => setTemplateFormVisible(false)}>
        <Card>
          <form onSubmit={submitCreateTemplate}>
            <h4>{t(I18N_CREATE_NEW_TEMPLATE)}</h4>

            <Label name={t(I18N_NAME)} required>
              <input autoFocus={templateFormVisible} name="name" type="text" required
                     onChange={handleNewTemplateName}/>
            </Label>

            <Label name={t(I18N_FILE)} required>
              <FileInput required onChange={(file) => setNewTemplateThumbnail(file)}/>
            </Label>

            <Label name={t(I18N_OBJECT_TAGS)} required>
              <ObjectTagsInput required value={newTemplateObjectTags} onChange={(objectTags: ObjectTag[]) => {
                setNewTemplateObjectTags(objectTags)
              }}/>
            </Label>

            <Label name={t(I18N_JSON_DATA)} toggleable toggled={false} onToggle={handleNewTemplateJsonToggle}>
              <JsonEditor onChange={handleNewTemplateJson}/>
            </Label>

            <div className="buttons-container">
              <Button type="cancel" onClick={() => setTemplateFormVisible(false)}>
                {t(I18N_CANCEL)}
              </Button>
              <Button type="submit">
                {t(I18N_CREATE)}
              </Button>
            </div>
          </form>
        </Card>
      </Overlay>
    </>
  )
}