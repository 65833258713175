import React, {useState} from "react";
import classNames from "classnames";
import {Button} from "../button";
import {FiDownload, FiEdit3, FiTrash2} from "react-icons/fi";
import {Card} from "../card";
import {MediaCardProps} from "./types";
import {Overlay} from "../overlay";
import {I18N_DELETE, I18N_DELETE_CONFIRMATION, REPLACE_I18N_TOKEN} from "../../translation";
import {deleteMedia} from "../../store/modules/room/actions";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {usePrompt} from "../../hooks";

export default ({media, canDelete, canEdit, onEdit, onDrop, item}: MediaCardProps) => {
  const {t} = useTranslation();
  const dispatch: any = useDispatch();
  const [imageOverlayVisible, setImageOverlayVisible] = useState(false);

  const [promptDelete, promptDeleteOverlay] = usePrompt({
    text: t(I18N_DELETE_CONFIRMATION).replace(REPLACE_I18N_TOKEN, media.name),
    onConfirm: () => {
      dispatch(deleteMedia(media))
    },
    confirmButtonText: t(I18N_DELETE)
  });

  const handleClick = () => {
    if (media.fileType === 'image') {
      setImageOverlayVisible(true)
    }
  };

  const handleOnEdit = () => {
    onEdit(media)
  };

  return (
    <>
      <Card sortable={canEdit} item={item} onDrop={onDrop} className="media-card">
        <div className={classNames({
          'card-image': true,
          'media-preview': true,
          'media-preview--has-gallery': media.fileType === 'image'
        })} onClick={handleClick}>
          <h4 className="card-title">{media.name}</h4>

          {media.fileType === 'image'
            ? (
              <img src={media.url} alt={media.name}/>
            )
            : (
              <span>{media.fileType}</span>
            )
          }
        </div>

        <div className="buttons-container">
          {canDelete && (
            <Button type="cancel" icon={true} onClick={promptDelete}>
              <FiTrash2/>
            </Button>
          )}
          {canEdit && (
            <Button type="cancel" icon={true} onClick={handleOnEdit}>
              <FiEdit3/>
            </Button>
          )}
          <a href={media.url} download={media.fileName} target="_blank"
             className="button button--icon"
             rel="noopener noreferrer">
            <FiDownload/>
          </a>
        </div>
      </Card>

      {promptDeleteOverlay}

      <Overlay title={media?.name} visible={imageOverlayVisible} onClose={() => setImageOverlayVisible(false)}>
        <Card image>
          <img src={media?.url} alt={media?.name}/>
        </Card>
      </Overlay>
    </>
  )
}