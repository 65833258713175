export const I18N_MEETING_NOT_STARTED = 'I18N_MEETING_STARTED';
export const I18N_MEETING_IN_PROGRESS = 'I18N_MEETING_IN_PROGRESS';
export const I18N_ROOM_NO_USERS = 'I18N_ROOM_NO_USERS';
export const I18N_INFORMATION = 'I18N_ROOM_HEADER_INFORMATION';
export const I18N_USERS = 'I18N_USERS';
export const I18N_USER = 'I18N_USER';
export const I18N_INVITE_USER = 'I18N_INVITE_USER';
export const I18N_INVITE_USERS = 'I18N_INVITE_USERS';
export const I18N_BREADCRUMB_ROOMS = 'I18N_BREADCRUMB_ROOMS';
export const I18N_EDIT = 'I18N_EDIT';
export const I18N_DELETE = 'I18N_DELETE';
export const I18N_END_MEETING = 'I18N_END_MEETING';
export const I18N_START_MEETING = 'I18N_START_MEETING';
export const I18N_REMOVE = 'I18N_REMOVE';
export const I18N_MEDIA = 'I18N_MEDIA';
export const I18N_ADD_MEDIA = 'I18N_ADD_MEDIA';
export const I18N_ROOM_NO_MEDIA = 'I18N_ROOM_NO_MEDIA';
export const I18N_DOWNLOAD_AS_ZIP = 'I18N_DOWNLOAD_AS_ZIP';
export const I18N_DROPZONE_TEXT = 'I18N_DROPZONE_TEXT';
export const I18N_ROOM_NO_URLS = 'I18N_ROOM_NO_URLS';
export const I18N_ADD_LINK = 'I18N_ADD_LINK';
export const I18N_ADD_LINKS = 'I18N_ADD_LINKS';
export const I18N_EDIT_ROOM = 'I18N_EDIT_ROOM';
export const I18N_NAME = 'I18N_NAME';
export const I18N_THUMBNAIL = 'I18N_THUMBNAIL';
export const I18N_DESCRIPTION = 'I18N_DESCRIPTION';
export const I18N_CANCEL = 'I18N_CANCEL';
export const I18N_CONFIRM = 'I18N_CONFIRM';
export const I18N_SAVE = 'I18N_SAVE';
export const I18N_TEXT_EDITOR_PLACEHOLDER = 'I18N_TEXT_EDITOR_PLACEHOLDER';
export const I18N_EMAIL = 'I18N_EMAIL';
export const I18N_USER_INVITATION_NOTE = 'I18N_USER_INVITATION_NOTE';
export const I18N_INVITE = 'I18N_INVITE';
export const I18N_EDIT_MEDIA = 'I18N_EDIT_MEDIA';
export const I18N_FILE = 'I18N_FILE';
export const I18N_OBJECT_TAG = 'I18N_OBJECT_TAG';
export const I18N_OBJECT_TAGS = 'I18N_OBJECT_TAGS';
export const I18N_HIDDEN = 'I18N_HIDDEN';
export const I18N_JSON_DATA = 'I18N_JSON_DATA';
export const I18N_URL = 'I18N_URL';
export const I18N_ROOM_TEMPLATES = 'I18N_ROOM_TEMPLATES';
export const I18N_NO_OBJECT_TAGS = 'I18N_NO_OBJECT_TAGS';
export const I18N_CREATE_TEMPLATE = 'I18N_CREATE_TEMPLATE';
export const I18N_CREATE_NEW_TEMPLATE = 'I18N_CREATE_NEW_TEMPLATE';
export const I18N_TEMPLATE_SETTINGS = 'I18N_TEMPLATE_SETTINGS';
export const I18N_PERMISSIONS = 'I18N_PERMISSIONS';
export const I18N_ACCESS_NO_USERS = 'I18N_ACCESS_NO_USERS';
export const I18N_MORE_ACTIONS = 'I18N_MORE_ACTIONS';
export const I18N_DELETE_TEMPLATE = 'I18N_DELETE_TEMPLATE';
export const I18N_REVOKE = 'I18N_REVOKE';
export const I18N_GRANT = 'I18N_GRANT';
export const I18N_CREATE = 'I18N_CREATE';
export const I18N_DELETE_CONFIRMATION = 'I18N_DELETE_CONFIRMATION';
export const I18N_DELETE_GROUP_CONFIRMATION = 'I18N_DELETE_GROUP_CONFIRMATION';
export const I18N_REMOVE_USER_CONFIRMATION = 'I18N_REMOVE_USER_CONFIRMATION';
export const I18N_ADD_TAG = 'I18N_ADD_TAG';
export const I18N_TAG_PRESS_ENTER = 'I18N_TAG_PRESS_ENTER';
export const I18N_USER_GROUPS = 'I18N_USER_GROUPS';
export const I18N_MEMBER = 'I18N_MEMBER';
export const I18N_MEMBERS = 'I18N_MEMBERS';
export const I18N_ADD_GROUP = 'I18N_ADD_GROUP';
export const I18N_EDIT_GROUP = 'I18N_EDIT_GROUP';
export const I18N_CREATE_NEW_GROUP = 'I18N_CREATE_NEW_GROUP';
export const I18N_DELETE_GROUP = 'I18N_DELETE_GROUP';
export const I18N_PARALLEL_MEETINGS = 'I18N_PARALLEL_MEETINGS';
export const I18N_USER_SELECT_PLACEHOLDER = 'I18N_USER_SELECT_PLACEHOLDER';
export const I18N_NO_MATCHES_FOUND = 'I18N_NO_MATCHES_FOUND';
export const I18N_MANAGE_ROOMS = 'I18N_MANAGE_ROOMS';
export const I18N_ADD_ROOM = 'I18N_ADD_ROOM';
export const I18N_CREATE_NEW_ROOM = 'I18N_CREATE_NEW_ROOM';
export const I18N_TEMPLATE = 'I18N_TEMPLATE';
export const I18N_ROOMS = 'I18N_ROOMS';
export const I18N_TEMPLATES = 'I18N_TEMPLATES';
export const I18N_GROUPS = 'I18N_GROUPS';
export const I18N_HELLO = 'I18N_HELLO';
export const I18N_SETTINGS = 'I18N_SETTINGS';
export const I18N_LOGOUT = 'I18N_LOGOUT';
export const I18N_ACCOUNT = 'I18N_ACCOUNT';
export const I18N_PROFILE = 'I18N_PROFILE';
export const I18N_PROFILE_IMAGE = 'I18N_PROFILE_IMAGE';
export const I18N_PASSWORD_EMPTY = 'I18N_ENTER_PASSWORD';
export const I18N_PASSWORD_INVALID = 'I18N_PASSWORD_INVALID';
export const I18N_PASSWORD_NO_MATCH = 'I18N_PASSWORD_NO_MATCH';
export const I18N_PASSWORD_MATCH = 'I18N_PASSWORD_MATCH';
export const I18N_PASSWORD = 'I18N_PASSWORD';
export const I18N_REPEAT_PASSWORD = 'I18N_REPEAT_PASSWORD';
export const I18N_PASSWORD_MIN_LENGTH = 'I18N_PASSWORD_MIN_LENGTH';
export const I18N_PASSWORD_HAS_NUMBERS = 'I18N_PASSWORD_HAS_NUMBERS';
export const I18N_PASSWORD_HAS_SPECIAL_CARS = 'I18N_PASSWORD_HAS_SPECIAL_CARS';
export const I18N_MORE_FEATURES = 'I18N_MORE_FEATURES';
export const I18N_DARK_MODE = 'I18N_DARK_MODE';
export const I18N_LANGUAGE = 'I18N_LANGUAGE';
export const I18N_GERMAN = 'I18N_GERMAN';
export const I18N_ENGLISH = 'I18N_ENGLISH';
export const I18N_SESSION_EXPIRED = 'I18N_SESSION_EXPIRED';
export const I18N_LOGIN = 'I18N_LOGIN';
export const I18N_LOGIN_TITLE = 'I18N_LOGIN_TITLE';
export const I18N_FORGOT_PASSWORD = 'I18N_FORGOT_PASSWORD';
export const I18N_RESET_PASSWORD_EMAIL_CONFIRMATION = 'RESET_PASSWORD_SUCCESS_TEXT';
export const I18N_RESET_PASSWORD_SUCCESS_TITLE = 'I18N_RESET_PASSWORD_SUCCESS_TITLE';
export const I18N_RESET_PASSWORD_SUCCESS_TEXT = 'I18N_RESET_PASSWORD_SUCCESS_TEXT';
export const I18N_BACK_TO_LOGIN = 'I18N_BACK_TO_LOGIN';
export const I18N_RESET_PASSWORD = 'I18N_RESET_PASSWORD';
export const I18N_SEND_RESET_EMAIL = 'I18N_SEND_RESET_EMAIL';
export const I18N_SET_PASSWORD = 'I18N_SET_PASSWORD';
export const I18N_CONFIRM_ACCOUNT_TEXT = 'I18N_CONFIRM_ACCOUNT_TEXT';
export const I18N_CONFIRM_ACCOUNT = 'I18N_CONFIRM_ACCOUNT';
export const I18N_CONTRIBUTOR = 'I18N_CONTRIBUTOR';
export const I18N_SUBSCRIBER = 'I18N_SUBSCRIBER';
export const I18N_ADMIN = 'I18N_ADMIN';
export const I18N_AUTHOR = 'I18N_ADMIN';
export const I18N_CAPABILITIES = 'I18N_CAPABILITIES';
export const I18N_USER_DROPDOWN_PLACEHOLDER = 'I18N_USER_DROPDOWN_PLACEHOLDER';
export const I18N_CAPABILITY_PRESS_ENTER = 'I18N_CAPABILITY_PRESS_ENTER';
export const I18N_ADD_NEW_CAPABILITY = 'I18N_ADD_NEW_CAPABILITY';
export const I18N_CUSTOM_CAPABILITIES = 'I18N_CUSTOM_CAPABILITIES';
export const I18N_DELETE_CAP_CONFIRMATION = 'I18N_DELETE_CAP_CONFIRMATION';
export const I18N_CONFIRMED = 'I18N_CONFIRMED';
export const I18N_ROLE = 'I18N_ROLE';
export const I18N_ACTIONS = 'I18N_ACTIONS';

export const REPLACE_I18N_TOKEN = "$X";

const resources = {
  en: {
    translation: {
      /* --- Backend error codes --- */
      'invalid_email': "The user doesn't exist.",
      'incorrect_password': "The password is not correct!",
      'invalid_key': "The key you provided is not valid!",
      'parallel_rooms_limit_reached': "You reached the maximum amount of parallel meetings. Please contact Skillhub for more information.",

      /* --- Frontend translations --- */
      [I18N_MEETING_NOT_STARTED]: "The meeting hasn't started yet.",
      [I18N_MEETING_IN_PROGRESS]: "Meeting is currently in progress.",
      [I18N_ROOM_NO_USERS]: "There are no users assigned to this room yet.",
      [I18N_INFORMATION]: "Information",
      [I18N_USERS]: "Users",
      [I18N_USER]: "User",
      [I18N_INVITE_USER]: "Invite user",
      [I18N_INVITE_USERS]: "Invite users",
      [I18N_BREADCRUMB_ROOMS]: "My Rooms",
      [I18N_EDIT]: "Edit",
      [I18N_DELETE]: "Delete",
      [I18N_END_MEETING]: "End meeting",
      [I18N_START_MEETING]: "Start meeting",
      [I18N_REMOVE]: "Remove",
      [I18N_MEDIA]: "Media",
      [I18N_ADD_MEDIA]: "Add media",
      [I18N_ROOM_NO_MEDIA]: "This room has no media.",
      [I18N_DOWNLOAD_AS_ZIP]: "Download as .zip",
      [I18N_DROPZONE_TEXT]: "Drag & drop file or <u>browse</u>",
      [I18N_ROOM_NO_URLS]: "This room has no links.",
      [I18N_ADD_LINK]: "Add a link",
      [I18N_ADD_LINKS]: "Add link",
      [I18N_EDIT_ROOM]: "Edit room",
      [I18N_NAME]: "Name",
      [I18N_THUMBNAIL]: "Thumbnail",
      [I18N_DESCRIPTION]: "Description",
      [I18N_CANCEL]: "Cancel",
      [I18N_CONFIRM]: "Confirm",
      [I18N_SAVE]: "Save",
      [I18N_TEXT_EDITOR_PLACEHOLDER]: "Enter some formatted text",
      [I18N_EMAIL]: "Email",
      [I18N_USER_INVITATION_NOTE]: "The user will receive an email with their personal invitation link.\n" +
      "If they are not signed up to Skillhub yet, they will also have to confirm their account first.",
      [I18N_INVITE]: 'Invite',
      [I18N_EDIT_MEDIA]: "Edit media",
      [I18N_FILE]: "File",
      [I18N_OBJECT_TAG]: "Object tag",
      [I18N_OBJECT_TAGS]: "Object tags",
      [I18N_HIDDEN]: "hidden",
      [I18N_JSON_DATA]: "JSON data",
      [I18N_URL]: "URL",
      [I18N_ROOM_TEMPLATES]: "Room templates",
      [I18N_NO_OBJECT_TAGS]: "No object tags",
      [I18N_CREATE_TEMPLATE]: "Create template",
      [I18N_CREATE_NEW_TEMPLATE]: "Create a new template",
      [I18N_TEMPLATE_SETTINGS]: "Template settings",
      [I18N_PERMISSIONS]: "Permissions",
      [I18N_ACCESS_NO_USERS]: "There are no users that could be given access.",
      [I18N_MORE_ACTIONS]: "More actions",
      [I18N_DELETE_TEMPLATE]: "Delete template",
      [I18N_REVOKE]: "Revoke",
      [I18N_GRANT]: "Grant",
      [I18N_CREATE]: "Create",
      [I18N_DELETE_CONFIRMATION]: `Do you really want to delete ${REPLACE_I18N_TOKEN}?`,
      [I18N_DELETE_GROUP_CONFIRMATION]: "Do you want to remove this group? The user accounts won't be affected.",
      [I18N_REMOVE_USER_CONFIRMATION]: `Do you want to remove ${REPLACE_I18N_TOKEN} from this room?`,
      [I18N_ADD_TAG]: "Add a tag",
      [I18N_TAG_PRESS_ENTER]: "Press 'Enter' to add a tag",
      [I18N_USER_GROUPS]: "User groups",
      [I18N_MEMBER]: "member",
      [I18N_MEMBERS]: "members",
      [I18N_ADD_GROUP]: "Add a group",
      [I18N_EDIT_GROUP]: "Edit group",
      [I18N_CREATE_NEW_GROUP]: "Create a new user group",
      [I18N_DELETE_GROUP]: "Delete group",
      [I18N_PARALLEL_MEETINGS]: "Max. parallel meetings",
      [I18N_USER_SELECT_PLACEHOLDER]: "Search for a user",
      [I18N_NO_MATCHES_FOUND]: "No matches found.",
      [I18N_MANAGE_ROOMS]: "Manage your rooms",
      [I18N_ADD_ROOM]: "Add a new room",
      [I18N_CREATE_NEW_ROOM]: "Create a new room",
      [I18N_TEMPLATE]: "Template",
      [I18N_ROOMS]: "Rooms",
      [I18N_TEMPLATES]: "Templates",
      [I18N_GROUPS]: "Groups",
      [I18N_HELLO]: "Hello",
      [I18N_SETTINGS]: "Settings",
      [I18N_LOGOUT]: "Logout",
      [I18N_ACCOUNT]: "Account",
      [I18N_PROFILE]: "Profile",
      [I18N_PROFILE_IMAGE]: "Profile image",
      [I18N_ACCOUNT]: "Account",
      [I18N_PROFILE]: "Profile",
      [I18N_PROFILE_IMAGE]: "Profile image",
      [I18N_PASSWORD_EMPTY]: "Please enter a password",
      [I18N_PASSWORD_INVALID]: "Password rules are not fulfilled",
      [I18N_PASSWORD_NO_MATCH]: "Passwords don't match",
      [I18N_PASSWORD_MATCH]: "Passwords match",
      [I18N_PASSWORD]: "Password",
      [I18N_REPEAT_PASSWORD]: "Repeat password",
      [I18N_PASSWORD_MIN_LENGTH]: "Is at least 10 characters long",
      [I18N_PASSWORD_HAS_NUMBERS]: "Contains at least 1 number",
      [I18N_PASSWORD_HAS_SPECIAL_CARS]: "Contains at least 1 special character",
      [I18N_MORE_FEATURES]: "More features",
      [I18N_DARK_MODE]: "Dark mode",
      [I18N_LANGUAGE]: "Language",
      [I18N_GERMAN]: "German",
      [I18N_ENGLISH]: "English",
      [I18N_SESSION_EXPIRED]: "Your session expired. Please login again.",
      [I18N_LOGIN]: "Login",
      [I18N_LOGIN_TITLE]: "Hi there!",
      [I18N_FORGOT_PASSWORD]: "Forgot password?",
      [I18N_RESET_PASSWORD_EMAIL_CONFIRMATION]: `We sent you an email with instructions on how to reset your password to <b>${REPLACE_I18N_TOKEN}</b>.`,
      [I18N_BACK_TO_LOGIN]: "Back to login",
      [I18N_RESET_PASSWORD]: "Reset your password",
      [I18N_RESET_PASSWORD_SUCCESS_TITLE]: "All done!",
      [I18N_RESET_PASSWORD_SUCCESS_TEXT]: "Your password has been reset successfully!",
      [I18N_SEND_RESET_EMAIL]: "Send reset email",
      [I18N_SET_PASSWORD]: "Set password",
      [I18N_CONFIRM_ACCOUNT_TEXT]: "You are almost ready to go! Please confirm your account by setting a new password down below.",
      [I18N_CONFIRM_ACCOUNT]: "Confirm account",
      [I18N_CONTRIBUTOR]: "Contributor",
      [I18N_SUBSCRIBER]: "Subscriber",
      [I18N_ADMIN]: "Admin",
      [I18N_AUTHOR]: "Author",
      [I18N_CAPABILITIES]: "Capabilities",
      [I18N_CUSTOM_CAPABILITIES]: "Custom capabilities",
      [I18N_USER_DROPDOWN_PLACEHOLDER]: "Select a user",
      [I18N_CAPABILITY_PRESS_ENTER]: "Press enter to add a capability",
      [I18N_ADD_NEW_CAPABILITY]: "Add a new capability",
      [I18N_DELETE_CAP_CONFIRMATION]: `Do you want to delete the capacity "${REPLACE_I18N_TOKEN}"?`,
      [I18N_CONFIRMED]: "Confirmed",
      [I18N_ROLE]: "Role",
      [I18N_ACTIONS]: "Actions",
    }
  },
  de: {
    translation: {
      /* --- Backend error codes --- */
      'invalid_email': "Der angegebene Nutzer existiert nicht.",
      'incorrect_password': "Das Passwort ist falsch!",
      'invalid_key': "Der verwendete Schlüssel ist nicht gültig!",
      'parallel_rooms_limit_reached': "Du hast das Limit an max. aktiven Räumen erreicht. Bitte kontaktiere Skillhub für mehr Informationen.",

      /* --- Frontend translations --- */
      [I18N_MEETING_NOT_STARTED]: "Das Meeting wurde noch nicht gestartet.",
      [I18N_MEETING_IN_PROGRESS]: "Das Meeting läuft gerade.",
      [I18N_ROOM_NO_USERS]: "Es wurden noch keine Nutzer in diesen Raum eingeladen.",
      [I18N_INFORMATION]: "Informationen",
      [I18N_USERS]: "Nutzer",
      [I18N_USER]: "Nutzer",
      [I18N_INVITE_USER]: "Nutzer einladen",
      [I18N_INVITE_USERS]: "Nutzer einladen",
      [I18N_BREADCRUMB_ROOMS]: "Meine Räume",
      [I18N_EDIT]: "Bearbeiten",
      [I18N_DELETE]: "Löschen",
      [I18N_END_MEETING]: "Meeting beenden",
      [I18N_START_MEETING]: "Meeting starten",
      [I18N_REMOVE]: "Entfernen",
      [I18N_MEDIA]: "Medien",
      [I18N_ADD_MEDIA]: "Medien hinzufügen",
      [I18N_ROOM_NO_MEDIA]: "Dieser Raum hat noch keine Medien.",
      [I18N_DOWNLOAD_AS_ZIP]: "Zip-Datei herunterladen",
      [I18N_DROPZONE_TEXT]: "Datei hierher ziehen oder <u>durchsuchen</u>",
      [I18N_ROOM_NO_URLS]: "Dieser Raum hat noch keine Links.",
      [I18N_ADD_LINK]: "Link hinzufügen",
      [I18N_ADD_LINKS]: "Link hinzufügen",
      [I18N_EDIT_ROOM]: "Raum bearbeiten",
      [I18N_NAME]: "Name",
      [I18N_THUMBNAIL]: "Vorschaubild",
      [I18N_DESCRIPTION]: "Beschreibung",
      [I18N_CANCEL]: "Abbrechen",
      [I18N_CONFIRM]: "Bestätigen",
      [I18N_SAVE]: "Speichern",
      [I18N_TEXT_EDITOR_PLACEHOLDER]: "Formatierten Text anlegen",
      [I18N_EMAIL]: "Email",
      [I18N_USER_INVITATION_NOTE]: "Der Nutzer erhält eine Email mit seinem persönlichen Einladungslink.\n" +
      "Wenn er noch nicht bei Skillhub registriert ist, muss er vorher zusätzlich sein Konto bestätigen.",
      [I18N_INVITE]: 'Einladen',
      [I18N_EDIT_MEDIA]: "Medien bearbeiten",
      [I18N_FILE]: "Datei",
      [I18N_OBJECT_TAG]: "Objekt-Tag",
      [I18N_OBJECT_TAGS]: "Objekt-Tags",
      [I18N_HIDDEN]: "versteckt",
      [I18N_JSON_DATA]: "JSON-Daten",
      [I18N_URL]: "URL",
      [I18N_ROOM_TEMPLATES]: "Raum-Vorlagen",
      [I18N_NO_OBJECT_TAGS]: "Keine Objekt-Tags",
      [I18N_CREATE_TEMPLATE]: "Vorlage erstellen",
      [I18N_CREATE_NEW_TEMPLATE]: "Eine neue Vorlage erstellen",
      [I18N_TEMPLATE_SETTINGS]: "Vorlage bearbeiten",
      [I18N_PERMISSIONS]: "Berechtigungen",
      [I18N_ACCESS_NO_USERS]: "Es gibt keine Nutzer, denen man eine Berechtigung geben könnte.",
      [I18N_MORE_ACTIONS]: "Weitere Aktionen",
      [I18N_DELETE_TEMPLATE]: "Vorlage löschen",
      [I18N_REVOKE]: "Aufheben",
      [I18N_GRANT]: "Erteilen",
      [I18N_CREATE]: "Erstellen",
      [I18N_DELETE_CONFIRMATION]: `Möchtest du ${REPLACE_I18N_TOKEN} wirklich löschen?`,
      [I18N_DELETE_GROUP_CONFIRMATION]: "Möchtest du diese Gruppe wirklich entfernen? Die Nutzerkonten bleiben bestehen.",
      [I18N_REMOVE_USER_CONFIRMATION]: `Möchtest du ${REPLACE_I18N_TOKEN} aus diesem Raum entfernen?`,
      [I18N_ADD_TAG]: "Tag hinzufügen",
      [I18N_TAG_PRESS_ENTER]: "Drücke 'Enter', um einen Tag hinzuzufügen",
      [I18N_USER_GROUPS]: "Nutzergruppen",
      [I18N_MEMBER]: "Mitglied",
      [I18N_MEMBERS]: "Mitglieder",
      [I18N_ADD_GROUP]: "Eine Gruppe hinzufügen",
      [I18N_EDIT_GROUP]: "Gruppe bearbeiten",
      [I18N_CREATE_NEW_GROUP]: "Eine neue Gruppe erstellen",
      [I18N_DELETE_GROUP]: "Gruppe löschen",
      [I18N_PARALLEL_MEETINGS]: "Max. aktive Meetings",
      [I18N_USER_SELECT_PLACEHOLDER]: "Suche nach einem Nutzer",
      [I18N_NO_MATCHES_FOUND]: "Keine Ergebnisse gefunden.",
      [I18N_MANAGE_ROOMS]: "Meine Räume verwalten",
      [I18N_ADD_ROOM]: "Raum hinzufügen",
      [I18N_CREATE_NEW_ROOM]: "Neuen Raum erstellen",
      [I18N_TEMPLATE]: "Vorlage",
      [I18N_ROOMS]: "Räume",
      [I18N_TEMPLATES]: "Vorlagen",
      [I18N_GROUPS]: "Gruppen",
      [I18N_HELLO]: "Hallo",
      [I18N_SETTINGS]: "Einstellungen",
      [I18N_LOGOUT]: "Ausloggen",
      [I18N_ACCOUNT]: "Konto",
      [I18N_PROFILE]: "Profil",
      [I18N_PROFILE_IMAGE]: "Profilbild",
      [I18N_PASSWORD_EMPTY]: "Bitte gib ein Passwort an",
      [I18N_PASSWORD_INVALID]: "Die Passwort-Regeln sind nicht erfüllt",
      [I18N_PASSWORD_NO_MATCH]: "Die Passwörter stimmen nicht überein",
      [I18N_PASSWORD_MATCH]: "Passwörter stimmen überein",
      [I18N_PASSWORD]: "Passwort",
      [I18N_REPEAT_PASSWORD]: "Passwort wiederholen",
      [I18N_PASSWORD_MIN_LENGTH]: "Mindestens 10 Zeichen lang",
      [I18N_PASSWORD_HAS_NUMBERS]: "Enthält mindestens eine Zahl",
      [I18N_PASSWORD_HAS_SPECIAL_CARS]: "Enthält mindestens ein Sonderzeichen",
      [I18N_MORE_FEATURES]: "Weitere Features",
      [I18N_DARK_MODE]: "Dunkelmodus",
      [I18N_LANGUAGE]: "Sprache",
      [I18N_GERMAN]: "Deutsch",
      [I18N_ENGLISH]: "Englisch",
      [I18N_SESSION_EXPIRED]: "Deine Sitzung ist abgelaufen. Bitte melde dich erneut an.",
      [I18N_LOGIN]: "Einloggen",
      [I18N_LOGIN_TITLE]: "Hallo!",
      [I18N_FORGOT_PASSWORD]: "Passwort vergessen?",
      [I18N_RESET_PASSWORD_EMAIL_CONFIRMATION]: `Wir haben dir eine Email an <b>${REPLACE_I18N_TOKEN}</b> geschickt, in der du ein Link zum Zurücksetzen deines Passwortes findest.`,
      [I18N_BACK_TO_LOGIN]: "Zurück zum Login",
      [I18N_RESET_PASSWORD]: "Passwort zurücksetzen",
      [I18N_RESET_PASSWORD_SUCCESS_TITLE]: "Erledigt!",
      [I18N_RESET_PASSWORD_SUCCESS_TEXT]: "Dein Passwort wurde erfolgreich zurückgesetzt!",
      [I18N_SEND_RESET_EMAIL]: "Email anfordern",
      [I18N_SET_PASSWORD]: "Passwort setzen",
      [I18N_CONFIRM_ACCOUNT_TEXT]: "Du bist fast startklar! Bitte bestätige dein Konto, indem du ein Passwort vergibst.",
      [I18N_CONFIRM_ACCOUNT]: "Konto bestätigen",
      [I18N_CONTRIBUTOR]: "Mitwirkender",
      [I18N_SUBSCRIBER]: "Teilnehmer",
      [I18N_ADMIN]: "Admin",
      [I18N_AUTHOR]: "Autor",
      [I18N_CAPABILITIES]: "Berechtigungen",
      [I18N_CUSTOM_CAPABILITIES]: "Benutzerdefinierte Berechtigungen",
      [I18N_USER_DROPDOWN_PLACEHOLDER]: "Wähle einen Nutzer aus",
      [I18N_CAPABILITY_PRESS_ENTER]: "Drücke 'Enter', um eine Berechtigung hinzuzufügen",
      [I18N_ADD_NEW_CAPABILITY]: "Berechtigung hinzufügen",
      [I18N_DELETE_CAP_CONFIRMATION]: `Möchtest du die Berechtigung "${REPLACE_I18N_TOKEN}" entfernen?`,
      [I18N_CONFIRMED]: "Bestätigt",
      [I18N_ROLE]: "Rolle",
      [I18N_ACTIONS]: "Aktionen",
    }
  }
};

export {resources}