import React, {FormEvent, useRef, useState} from "react";
import {Button} from "../button";
import {FiPlus} from "react-icons/all";
import {ObjectTag} from "../../store/modules/room/types";
import {AddInputProps} from "./types";

export default ({infoText, placeholder, onChange, children}: AddInputProps & React.ComponentProps<any>) => {
  const [value, setValue] = useState<ObjectTag>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const change = (e?: FormEvent<HTMLButtonElement>) => {
    e?.preventDefault();

    if (!value) {
      return
    }

    onChange(value);
    setValue('')
  }

  const handleChange = (e: FormEvent<HTMLInputElement>) => setValue(e.currentTarget.value)

  const handleObjectTagKeydown = (e: any) => {
    if (e.key === 'Enter') {
      const propagateEnter = !value;

      if (!propagateEnter) {
        e.preventDefault();
        change()
      }
    }
  };

  return (
    <div className="tag-input-wrapper">
      <div className="tag-input">
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          ref={inputRef}
          onKeyDown={handleObjectTagKeydown}
          onChange={handleChange}/>
        <Button
          small
          icon
          type="outline"
          onClick={change}>
          <FiPlus/>
        </Button>
      </div>
      {infoText && <div className="tag-input-info">{infoText}</div>}
      {children}
    </div>
  )
}