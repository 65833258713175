import React, {useCallback, useMemo, useState} from "react";
// Import the Slate editor factory.
import {createEditor} from 'slate'
// Import the Slate components and React plugin.
import {Editable, ReactEditor, Slate, withReact} from 'slate-react'
import {TextEditorProps} from "./types";
import {BlockButton, Element, Leaf, LinkButton, MarkButton} from "./elements";
import classNames from "classnames";
import {withLinks} from "./elements/buttons";
import {withHistory} from 'slate-history'
import {
  EditorBoldIcon,
  EditorBulletListIcon,
  EditorHeadlineIcon,
  EditorItalicIcon,
  EditorLinkIcon,
  EditorNumberedListIcon,
  EditorUnderlineIcon
} from "../../icons";
import {useTranslation} from "react-i18next";
import {I18N_TEXT_EDITOR_PLACEHOLDER} from "../../translation";

export default (props: TextEditorProps & React.ComponentProps<any>) => {
  const {t} = useTranslation();
  const editor: ReactEditor = useMemo(() => {
    return withLinks(withHistory(withReact(createEditor())))
  }, []);
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState<any>(props.value?.length ? props.value : [{
    type: 'paragraph',
    children: [
      {
        text: ''
      }
    ],
  }]);

  const handleChange = (value: any) => {
    setValue(value);
    props.onChange?.(value)
  };

  return (
    <Slate editor={editor} value={value} onChange={handleChange}>
      <div className={classNames({
        'text-editor': true,
        'text-editor--focus': focused,
        'text-editor--readonly': props.readOnly
      })}>
        {!props.readOnly && (
          <div className="text-editor-toolbar">
            <div title="Headline"><BlockButton format="heading-one" icon={EditorHeadlineIcon}/></div>
            <div title="Bold"><MarkButton format="bold" icon={EditorBoldIcon}/></div>
            <div title="Italic"><MarkButton format="italic" icon={EditorItalicIcon}/></div>
            <div title="Underlined"><MarkButton format="underline" icon={EditorUnderlineIcon}/></div>
            <div title="Add a link"><LinkButton format="link" icon={EditorLinkIcon}/></div>
            <div title="Ordered list"><BlockButton format="numbered-list" icon={EditorNumberedListIcon}/></div>
            <div title="Bullet list"><BlockButton format="bulleted-list" icon={EditorBulletListIcon}/></div>
          </div>
        )}

        <Editable
          className="text-editor-content"
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          readOnly={props.readOnly}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={t(I18N_TEXT_EDITOR_PLACEHOLDER)}
          spellCheck
          autoFocus/>
      </div>
    </Slate>
  )
}