import React, {useMemo} from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../store/configure";
import {ProtectedRouteProps} from "./types";
import {userHasCapability} from "../../hooks/useCapability/useCapability";
import {Loader} from "../loader";

export default ({capabilityGuard, children, ...routeProps}: ProtectedRouteProps & RouteProps) => {
  const {isLoading,currentUser} = useSelector((state: RootState) => state.auth);
  const canViewRoute = useMemo(() => {
    return currentUser && (!capabilityGuard || userHasCapability(currentUser, capabilityGuard))
  }, [currentUser, capabilityGuard]);

  if(isLoading) {
    return <Loader />
  }

  if (!canViewRoute) {
    return <Redirect to="/"/>
  }

  return (
    <Route {...routeProps}>
      {children}
    </Route>
  )
}