import React from "react";
import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {Card} from "../../components/card";
import {RootState} from "../../store/configure";
import {LoginForm} from "../../components/login-form";

export default () => {
  const {currentUser} = useSelector((state: RootState) => state.auth);

  if (currentUser) {
    return <Redirect to="/rooms"/>
  }
  return (
    <div className="full-page">
      <Card>
        <LoginForm/>
      </Card>
    </div>
  )
};