import React from "react";
import {Editor, Range, Transforms} from "slate";
import {ReactEditor, useSlate} from "slate-react";
import {BaseButtonProps, ButtonProps} from "./types";
import classNames from "classnames";
import isUrl from 'is-url';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const BaseButton = ({active, children, ...restProps}: BaseButtonProps & React.ComponentProps<any>) => {
  return (
    <div className={classNames({
      'editor-button': true,
      'editor-button--active': active,
    })} {...restProps}>
      {children}
    </div>
  )
};

const isMarkActive = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false
};

const toggleMark = (editor: Editor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
};

export const MarkButton = ({format, icon}: ButtonProps) => {
  const editor = useSlate();
  const Icon: any = icon;

  return (
    <BaseButton
      active={isMarkActive(editor, format)}
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        toggleMark(editor, format)
      }}>
      <Icon/>
    </BaseButton>
  )
};

const isBlockActive = (editor: Editor, format: string) => {
  const [match]: any = Editor.nodes(editor, {
    match: n => n.type === format,
  });

  return !!match
};

const toggleBlock = (editor: Editor, format: string) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  });

  if (!isActive && isList) {
    const block = {type: format, children: []};
    Transforms.wrapNodes(editor, block)
  }
};

export const BlockButton = ({format, icon}: ButtonProps) => {
  const editor = useSlate();
  const Icon = icon;

  return (
    <BaseButton
      active={isBlockActive(editor, format)}
      onMouseDown={(e: MouseEvent) => {
        e.preventDefault();
        toggleBlock(editor, format)
      }}>
      <Icon/>
    </BaseButton>
  )
};

const insertLink = (editor: Editor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
};

const isLinkActive = (editor: Editor) => {
  const [link]: any = Editor.nodes(editor, {match: n => n.type === 'link'});
  return !!link
};

const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {match: n => n.type === 'link'})
};

const wrapLink = (editor: Editor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const {selection} = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{text: url}] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, {split: true});
    Transforms.collapse(editor, {edge: 'end'})
  }
};

export const LinkButton = ({icon}: ButtonProps) => {
  const editor = useSlate();
  const Icon = icon;

  const handleMouseDown = (e: MouseEvent) => {
    e.preventDefault();
    const url = window.prompt('Enter the URL of the link:');
    if (!url) {
      return
    }

    insertLink(editor, url)
  };

  return (
    <BaseButton
      active={isLinkActive(editor)}
      onMouseDown={handleMouseDown}>
      <Icon/>
    </BaseButton>
  )
};

export const withLinks = (editor: ReactEditor) => {
  const {insertData, insertText, isInline} = editor;

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  };

  editor.insertData = (data: any) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  };

  return editor
};