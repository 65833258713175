import React from 'react';
import {RoomCardProps} from "./types";
import {AvatarList} from "../avatar";
import {Link} from "react-router-dom";
import {Card} from "../card";
import classNames from "classnames";
import {I18N_MEMBER, I18N_MEMBERS} from '../../translation';
import {useTranslation} from "react-i18next";

export default ({room}: RoomCardProps) => {
  const {t} = useTranslation();
  const users = room.users.slice(0, 3);

  return (
    <Card className="room-card">
      <div className="card-image">
        <img src={room.thumbnail || ''} alt={room.name}/>
      </div>

      <div className="card-content card-content--centered">
        {users.length > 0 && <AvatarList urls={users.map(user => user.imageUrl)} alts={users.map(user => user.displayName)} />}

        <Link to={`rooms/${room.roomId}`}>
          <h3 className="card-title">
            <span className={classNames({
              'room-indicator': true,
              'room-indicator--active': room.active
            })} title={'Room is ' + (room.active ? 'active' : 'not active')}/>
            {room.name}
          </h3>
        </Link>

        <div>{room.users.length} {t(room.users.length === 1 ? I18N_MEMBER : I18N_MEMBERS)}</div>
      </div>
    </Card>
  )
}