import React, {useEffect, useRef, useState} from "react";
import {ObjectTagsInputProps} from "./types";
import {ObjectTag} from "../../store/modules/room/types";
import {ObjectTagComponent} from "../object-tag";
import {ObjectTagHiddenPrefix} from "../object-tag/types";
import {I18N_ADD_TAG, I18N_TAG_PRESS_ENTER} from "../../translation";
import {useTranslation} from "react-i18next";
import {AddInput} from "../add-input";

export default (props: ObjectTagsInputProps) => {
  const {t} = useTranslation();
  const [objectTags, setObjectTags] = useState<ObjectTag[]>(props.value ?? []);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.required && inputRef?.current) {
      inputRef.current.setCustomValidity(objectTags.length ? '' : 'Please enter at least one object tag')
    }
  }, [props.required, objectTags, inputRef]);

  const addObjectTag = (objectTag: ObjectTag) => {
    if (objectTag) {
      const tags = [
        ...objectTags,
        objectTag
      ];

      setObjectTags(tags);
      props.onChange(tags)
    }
  };

  const removeObjectTag = (objectTag: ObjectTag) => {
    const tags = objectTags.filter(tag => tag !== objectTag);
    setObjectTags(tags);
    props.onChange(tags)
  };

  const handleObjectTagVisibility = (objectTag: ObjectTag, visible: boolean) => {
    const objectTagBase = objectTag.replace(ObjectTagHiddenPrefix, '');

    const tags = objectTags.map(objectTag => {
      if (objectTag.replace(ObjectTagHiddenPrefix, '') === objectTagBase) {
        return (visible ? '' : ObjectTagHiddenPrefix) + objectTagBase
      } else {
        return objectTag
      }
    });

    setObjectTags(tags);
    props.onChange(tags)
  };

  return (
    <AddInput
      infoText={t(I18N_TAG_PRESS_ENTER)}
      placeholder={t(I18N_ADD_TAG)}
      onChange={addObjectTag}>
      <div className="tags">
        {objectTags.map(objectTag => (
          <ObjectTagComponent
            key={objectTag}
            objectTag={objectTag}
            onRemove={() => removeObjectTag(objectTag)}
            onVisibilityChange={(visible: boolean) => handleObjectTagVisibility(objectTag, visible)}/>
        ))}
      </div>
    </AddInput>
  )
}