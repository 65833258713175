import React, {forwardRef} from "react";
import {GridProps} from "./types";
import {DragContainer} from "../drag-container";
import classNames from "classnames";

export default forwardRef(({className, sortable, onOrder, children}: GridProps & React.ComponentProps<any>, ref: any) => {
  className = classNames({
    [className]: !!className,
    'grid': true
  });

  if (sortable) {
    return (
      <DragContainer
        element={'div'}
        ref={ref}
        className={className}
        onOrder={onOrder}>
        {children}
      </DragContainer>
    )
  } else {
    return (
      <div
        className={className}
        ref={ref}>
        {children}
      </div>
    )
  }
})