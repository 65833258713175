import React from 'react';
import {IconProps} from "../types";

export default (props: IconProps) => {
  return (
    <svg width={props.width ?? 30} height={props.height ?? 30} viewBox="0 0 30 30">
      <rect x="12" y="9" width="11" height="2" fill="currentColor"/>
      <rect x="12" y="14" width="11" height="2" fill="currentColor"/>
      <rect x="12" y="19" width="11" height="2" fill="currentColor"/>
      <polygon points="7,8 7,9 8,9 8,12 9,12 9,9 9,8" fill="currentColor"/>
      <polygon points="10,13 7,13 7,14 8.8,14 7,16 7,17 10,17 10,16 8.2,16 10,14" fill="currentColor"/>
      <polygon points="7,18 7,19 9,19 9,19.8 8,19.8 8,20.2 9,20.2 9,21 7,21 7,22 10,22 10,21 10,19 10,18"
               fill="currentColor"/>
    </svg>
  )
}