export const API_ACTION = 'apiAction';

type RESTMethod = 'GET' | 'POST' | 'DELETE';

export type ApiAction = {
  [API_ACTION]: boolean;
  type: string;
  method: RESTMethod;
  endpoint: string;
  bodyArgs?: FormData | object | undefined;
  payloadCreator?: (result: any) => any;
  responseType?: string;
  successCallback?: () => any;
  onProgress?: (progress: number) => any;
  onUploadProgress?: (progress: number) => any;
}

export interface ApiError {
  response?: {
    data: any,
    status: number,
    headers: any,
  };
  request?: any;
  message?: string;
  config: any;
}

type ProgressCallback = (progress: number) => any;

export interface ProgressCallbacks {
  onProgress?: ProgressCallback;
  onUploadProgress?: ProgressCallback;
}

export function isApiAction(action: any): action is ApiAction {
  return API_ACTION in action
}