import React from "react";
import {IconProps} from "./types";

export default (props: IconProps) => (
  <svg className="icon" viewBox="0 0 33.312 30.32" width={props.width ?? 33.312} height={props.height ?? 30.32}>
    <g transform="translate(-66.151 -177.511)">
      <path d="M67.22,205.734,79.707,198.4l12.487,7.329" transform="translate(0 -6.329)" fill="none"
            stroke="currentColor"
            strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
      <line y2="13.122" transform="translate(79.709 178.831)" fill="none" stroke="currentColor" strokeLinecap="round"
            strokeWidth="1"/>
      <path d="M84.473,181.376l-4.891-2.686-12.432,7.68v13.3l12.432,7.016,12.58-7.016v-6.179" fill="none"
            stroke="currentColor"
            strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <line x2="11" transform="translate(87.463 184.011)" fill="none" stroke="currentColor" strokeLinecap="round"
            strokeWidth="2"/>
      <line x2="11" transform="translate(92.963 178.511) rotate(90)" fill="none" stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"/>
    </g>
  </svg>
)