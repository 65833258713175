import {SET_DARK_MODE, SET_LANGUAGE} from "./types";

export function setDarkMode(active: boolean) {
  return {
    type: SET_DARK_MODE,
    payload: active
  }
}

export function setLanguage(language: string) {
  return {
    type: SET_LANGUAGE,
    payload: language
  }
}