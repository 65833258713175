import React from 'react';
import {IconProps} from "../types";

export default (props: IconProps) => {
  return (
    <svg width={props.width ?? 30} height={props.height ?? 30} viewBox="0 0 30 30">
      <g transform="translate(8, 9)">
        <polygon points="7,0 7,5 2,5 2,0 0,0 0,13 2,13 2,7 7,7 7,13 9,13 9,0" fill="currentColor"/>
        <polygon points="10,7 11,8 13,6 13,13 15,13 15,4 13,4" fill="currentColor"/>
      </g>
    </svg>
  )
}