import {Label} from "../label";
import {Button} from "../button";
import {FiLogIn} from "react-icons/all";
import {Link} from "react-router-dom";
import React, {FormEvent, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/configure";
import {useQueryParams} from "../../hooks";
import {login} from "../../store/modules/auth/actions";
import {
  I18N_EMAIL,
  I18N_FORGOT_PASSWORD,
  I18N_LOGIN,
  I18N_LOGIN_TITLE,
  I18N_PASSWORD,
  I18N_SESSION_EXPIRED
} from "../../translation";
import {useTranslation} from "react-i18next";

export default () => {
  const {t} = useTranslation();
  const queryParams = useQueryParams();
  const dispatch = useDispatch();
  const {authError, isLoading, sessionExpired} = useSelector((state: RootState) => state.auth);
  const [email, setEmail] = useState(queryParams.get('email') ?? '');
  const [password, setPassword] = useState('');


  const handleEmail = (e: any) => {
    setEmail(e.target.value || '');
  };

  const handlePassword = (e: any) => {
    setPassword(e.target.value || '');
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    dispatch(login(email, password));
  };

  return (
    <form onSubmit={onSubmit} className="login-form">
      <h2>{t(I18N_LOGIN_TITLE)}</h2>

      {sessionExpired && <p>{t(I18N_SESSION_EXPIRED)}</p>}

      <Label name={t(I18N_EMAIL)} required>
        <input type="email" placeholder="max.mustermann@web.de" onChange={handleEmail} value={email}/>
      </Label>

      <Label name={t(I18N_PASSWORD)} required>
        <input type="password" placeholder={t(I18N_PASSWORD)} onChange={handlePassword} value={password}
               autoFocus={queryParams.has('email')}/>
      </Label>

      <div className="buttons-container">
        <Button type="submit" isLoading={isLoading}>
          <FiLogIn/>{t(I18N_LOGIN)}
        </Button>
      </div>

      {authError && <div className="error-message">{t(authError)}</div>}

      <Link to="/forgot-password" className="link small-text" style={{marginTop: '10px', alignSelf: 'center'}}>
        {t(I18N_FORGOT_PASSWORD)}
      </Link>
    </form>
  )
}