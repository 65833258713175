export interface DragItemData {
  type: string;
  id: number;

  [key: string]: any;
}

export function isDragItemProps(props: any): props is DragItemProps {
  return !!props.item?.type
}

export interface DragItemProps {
  item: DragItemData;
}

export interface MouseCoordinate {
  x: number;
  y: number;
}

export interface MouseCoordinateDistance {
  dx: number;
  dy: number;
}