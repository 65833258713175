import {ObjectTag} from "../../store/modules/room/types";

interface RemoveHandler {
  (e: any): void
}

interface VisibilityChangeHandler {
  (visible: boolean): void
}

export interface ObjectTagProps {
  objectTag: ObjectTag;
  onRemove?: RemoveHandler;
  onVisibilityChange?: VisibilityChangeHandler;
}

export const ObjectTagHiddenPrefix = '_hidden_';