import React from 'react';
import {LinkIcon, LinkProps} from "./types";
import {Link, useRouteMatch} from "react-router-dom";
import {MediaIcon, RoomIcon, RoomTemplateIcon, UserIcon} from '../../../icons';
import {useSelector} from "react-redux";
import {RootState} from "../../../store/configure";

const linkIcon = (icon: LinkIcon) => {
  switch (icon) {
    case 'room-templates':
      return <RoomTemplateIcon/>;

    case 'room':
      return <RoomIcon/>;

    case 'user':
      return <UserIcon/>;

    case 'media':
      return <MediaIcon/>;
  }
};

export default (props: LinkProps) => {
  const match = useRouteMatch(props.href);
  const isLoggedIn = useSelector((state: RootState) => state.auth.currentUser !== null);

  return (
    <Link className={
      'navigation-link ' +
      (match && isLoggedIn ? 'navigation-link--active' : '')
    } to={props.href}>
      {linkIcon(props.icon)}
      <span>{props.title}</span>
    </Link>
  )
}