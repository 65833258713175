import React from 'react'
import {Avatar} from "./index";
import {AvatarListProps} from "./types";

export default ({urls, alts}: AvatarListProps) => {
  return (
    <div className="avatar-list">
      {urls.map((url, i) => (
        <Avatar url={url} key={i} size={54 - ((urls.length - 1) - i) * 8} alt={alts?.[i]}/>
      ))}
    </div>
  )
}