import {RoomTemplate} from "../room/types";

export type UserRole = 'administrator' | 'author' | 'contributor' | 'subscriber' | 'temporary'

export type UserCapabilityMap = {
  [name in string]: boolean
}

export interface User {
  userId?: number;
  email: string;
  name: string;
  displayName: string;
  imageUrl?: string;
  roles: UserRole[];
  caps: UserCapabilityMap;
  confirmed: boolean;
}

export interface UserGroup {
  groupId?: number;
  name: string;
  users: User[];
  parallelMeetings?: number;
}

export interface UserState {
  users: User[];
  groups: UserGroup[];
  capabilities: string[];
}

export const GET_ADMINS_AND_AUTHORS = 'GET_ADMINS_AND_AUTHORS';
export const GRANT_TEMPLATE_ACCESS = 'GRANT_TEMPLATE_ACCESS';
export const REVOKE_TEMPLATE_ACCESS = 'REVOKE_TEMPLATE_ACCESS';
export const GET_GROUPS = 'GET_GROUPS';
export const CREATE_OR_UPDATE_GROUP = 'CREATE_OR_UPDATE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const GET_CAPS = 'GET_CAPS';
export const ADD_CAP = 'ADD_CAP';
export const DELETE_CAP = 'DELETE_CAP';
export const GRANT_CAP = 'GRANT_CAP';
export const REVOKE_CAP = 'REVOKE_CAP';

interface GetAdminsAndAuthorsAction {
  type: typeof GET_ADMINS_AND_AUTHORS;
  payload: User[];
}

interface GrantTemplateAccessAction {
  type: typeof GRANT_TEMPLATE_ACCESS;
  payload: {
    user: User,
    roomTemplate: RoomTemplate
  }
}

interface RevokeTemplateAccessAction {
  type: typeof REVOKE_TEMPLATE_ACCESS;
  payload: {
    user: User,
    roomTemplate: RoomTemplate
  }
}

interface GetGroupsAction {
  type: typeof GET_GROUPS;
  payload: UserGroup[];
}

interface CreateOrUpdateGroupAction {
  type: typeof CREATE_OR_UPDATE_GROUP;
  payload: UserGroup;
}

interface DeleteGroupAction {
  type: typeof DELETE_GROUP;
  payload: UserGroup;
}

interface GetCapsAction {
  type: typeof GET_CAPS;
  payload: string[];
}

interface AddCapAction {
  type: typeof ADD_CAP;
  payload: string;
}

interface DeleteCapAction {
  type: typeof DELETE_CAP;
  payload: string;
}

interface GrantCapAction {
  type: typeof GRANT_CAP;
  payload: {
    user: User;
    capability: string;
  };
}

interface RevokeCapAction {
  type: typeof REVOKE_CAP;
  payload: {
    user: User;
    capability: string;
  };
}

export type UserActionTypes = GetAdminsAndAuthorsAction
  | GrantTemplateAccessAction
  | RevokeTemplateAccessAction
  | GetGroupsAction
  | CreateOrUpdateGroupAction
  | DeleteGroupAction
  | GetCapsAction
  | AddCapAction
  | DeleteCapAction
  | GrantCapAction
  | RevokeCapAction;