export const SET_DARK_MODE = 'SET_DARK_MODE';
export const SET_LANGUAGE = 'SET_LANGUAGE';

export interface SettingsState {
  darkMode: boolean;
  language: string;
}

interface SetDarkModeAction {
  type: typeof SET_DARK_MODE;
  payload: boolean;
}

interface SetLanguageAction {
  type: typeof SET_LANGUAGE;
  payload: string;
}

export type SettingsActionTypes = SetDarkModeAction | SetLanguageAction;