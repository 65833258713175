import React from 'react'
import {AvatarProps} from "./types";
import classNames from "classnames";

export default ({size, url, alt, badge, className}: AvatarProps | React.ComponentProps<any>) => {
  const style = {
    '--avatar-size': size ? `${size}px` : ''
  } as React.CSSProperties;

  const Badge = badge;

  return (
    <div className={classNames({
      'avatar': true,
      [className]: !!className
    })}>
      {url
        ? <img className="avatar-image" src={url} style={style} alt={alt ?? 'Avatar'}/>
        : <div className="avatar-image" style={style}/>}

      {badge ? <div className="avatar-badge"><Badge/></div> : null}
    </div>
  )
}