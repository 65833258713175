import React, {ReactElement, useContext, useEffect, useRef} from "react";
import {DragItemProps} from "./types";
import {DragContext} from "../../contexts/drag-context";

export default (props: DragItemProps | React.ComponentProps<any>) => {
  const {children} = props;
  const {initDrag} = useContext(DragContext);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ref?.current) {
      return
    }

    const {current} = ref;

    const handleMouseDown = (e: MouseEvent) => {
      const clone = React.cloneElement(React.Children.toArray(children)[0] as ReactElement);
      const {left, top} = (e.currentTarget as HTMLElement).getBoundingClientRect();

      initDrag(props.item, clone, {
        x: e.pageX,
        y: e.pageY
      }, {
        dx: left,
        dy: top
      });
    };

    current.addEventListener('mousedown', handleMouseDown);

    return () => {
      current.removeEventListener('mousedown', handleMouseDown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, props]);

  const preventDrag = (e: React.DragEvent<HTMLDivElement>) => e.preventDefault();

  return (
    <div ref={ref} className="dragItem" onDragStart={preventDrag} draggable={false}>
      {children}
    </div>
  )
}