import React from "react"

export default () => (
  <svg className="icon" width="28.607" height="20.766" viewBox="0 0 28.607 20.766">
    <g transform="translate(-50.394 -229)">
      <g transform="translate(55 229)" fill="#fff">
        <path
          d="M 21.99960136413574 19.76570129394531 L 1.999800682067871 19.76570129394531 C 1.448510646820068 19.76570129394531 1.000000715255737 19.31679153442383 1.000000715255737 18.76500129699707 L 1.000000715255737 4.443300724029541 L 1.000000715255737 1.999800682067871 C 1.000000715255737 1.448510646820068 1.448510646820068 1.000000596046448 1.999800682067871 1.000000596046448 L 9.000000953674316 1.000000596046448 C 9.551290512084961 1.000000596046448 9.999800682067871 1.448510646820068 9.999800682067871 1.999800682067871 L 9.999800682067871 2.443500757217407 L 9.999800682067871 3.443500757217407 L 10.99980068206787 3.443500757217407 L 21.99960136413574 3.443500757217407 C 22.5513916015625 3.443500757217407 23.00030136108398 3.892010688781738 23.00030136108398 4.443300724029541 L 23.00030136108398 18.76500129699707 C 23.00030136108398 19.31679153442383 22.5513916015625 19.76570129394531 21.99960136413574 19.76570129394531 Z"
          stroke="none"/>
        <path
          d="M 21.99960136413574 18.76570129394531 L 22.00030136108398 18.76500129699707 L 21.99960136413574 4.443500518798828 L 10.99980068206787 4.443500518798828 L 8.999800682067871 4.443500518798828 L 8.999800682067871 2.443500757217407 L 9.000000953674316 2.000000715255737 L 2.000000715255737 1.999800682067871 L 2.000000715255737 4.443300724029541 L 1.999800682067871 18.76570129394531 L 21.99960136413574 18.76570129394531 M 21.99960136413574 20.76570129394531 L 1.999800682067871 20.76570129394531 C 0.895500659942627 20.76570129394531 6.805419729971618e-07 19.87020111083984 6.805419729971618e-07 18.76500129699707 L 6.805419729971618e-07 4.443300724029541 L 6.805419729971618e-07 1.999800682067871 C 6.805419729971618e-07 0.895500659942627 0.895500659942627 6.469726372415607e-07 1.999800682067871 6.469726372415607e-07 L 9.000000953674316 6.469726372415607e-07 C 10.1043004989624 6.469726372415607e-07 10.99980068206787 0.895500659942627 10.99980068206787 1.999800682067871 L 10.99980068206787 2.443500757217407 L 21.99960136413574 2.443500757217407 C 23.10480117797852 2.443500757217407 24.00030136108398 3.339000701904297 24.00030136108398 4.443300724029541 L 24.00030136108398 18.76500129699707 C 24.00030136108398 19.87020111083984 23.10480117797852 20.76570129394531 21.99960136413574 20.76570129394531 Z"
          stroke="none" fill="currentColor"/>
      </g>
      <path d="M55.859,247.175,51.394,236.47H73.12l3.762,10.705Z" transform="translate(0 1.351)" fill="#fff"
            stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </g>
  </svg>
)