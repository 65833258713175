import {Overlay} from "../../components/overlay";
import {Card} from "../../components/card";
import {I18N_CANCEL, I18N_CONFIRM} from "../../translation";
import {Button} from "../../components/button";
import React, {useState} from "react";
import {UsePromptParams, UsePromptReturnType} from "./types";
import {useTranslation} from "react-i18next";

export default ({text, onConfirm, onCancel, confirmButtonText, cancelButtonText, confirmOnly}: UsePromptParams): UsePromptReturnType => {
  const {t} = useTranslation();
  const [promptVisible, setPromptVisible] = useState(false);

  const prompt = () => {
    setPromptVisible(true)
  };

  const close = () => {
    setPromptVisible(false);
    onCancel?.()
  };

  const confirm = () => {
    setPromptVisible(false);
    onConfirm()
  };

  const render = (
    <Overlay visible={promptVisible} onClose={close}>
      <Card>
        <h4 className="prompt-title">{text}</h4>
        <div className="buttons-container">
          {!confirmOnly && <Button type="cancel" onClick={close}>{cancelButtonText ?? t(I18N_CANCEL)}</Button>}
          <Button onClick={confirm}>{confirmButtonText ?? t(I18N_CONFIRM)}</Button>
        </div>
      </Card>
    </Overlay>
  );

  return [
    prompt,
    render
  ]
}