import {
  ADD_URL,
  CHANGE_USER_ROLE,
  CREATE_OR_UPDATE_ROOM_TEMPLATE,
  CREATE_ROOM,
  DELETE_MEDIA,
  DELETE_ROOM,
  DELETE_ROOM_TEMPLATE,
  DELETE_URL,
  DOWNLOAD_MEDIA,
  END_MEETING,
  GET_MEDIA,
  GET_ROOM_TEMPLATES,
  GET_ROOMS,
  GET_URLS,
  INVITE_USER_TO_ROOM,
  Media,
  ObjectTag,
  REMOVE_USER_FROM_ROOM,
  Room,
  RoomTemplate,
  SET_MEDIA_ORDER,
  START_MEETING,
  UPDATE_ROOM,
  UpdateRoomTemplate,
  UPLOAD_MEDIA,
  UploadMedia,
  UploadRoom,
  Url,
} from "./types";
import {User, UserRole} from "../user/types";
import {API_ACTION, ApiAction} from "../../middleware/api/types";

export function createFormData(payload: any, action: string) {
  const formData = new FormData();

  for (let [key, value] of Object.entries(payload)) {
    if (Array.isArray(value)) {
      value.forEach((valueItem) => {
        if (valueItem !== undefined) {
          formData.append(`${key}[]`, valueItem)
        }
      })
    } else {
      if (value !== undefined) {
        formData.append(key, value as any);
      }
    }
  }

  formData.append('action', action);

  return formData;
}

export function getRooms(): ApiAction {
  return {
    [API_ACTION]: true,
    type: GET_ROOMS,
    method: 'GET',
    endpoint: 'rooms'
  };
}

export function createRoom(newRoom: UploadRoom): ApiAction {
  const formData = createFormData(newRoom, 'createRoom');

  return {
    [API_ACTION]: true,
    type: CREATE_ROOM,
    method: 'POST',
    endpoint: 'rooms',
    bodyArgs: formData,
    payloadCreator: (room: Room) => room
  }
}

export function updateRoom(updatedRoom: UploadRoom): ApiAction {
  const formData = createFormData(updatedRoom, 'updateRoom');

  return {
    [API_ACTION]: true,
    type: UPDATE_ROOM,
    method: 'POST',
    endpoint: `rooms/${updatedRoom.roomId}`,
    bodyArgs: formData,
    payloadCreator: (room: Room) => room
  };
}

export function deleteRoom(room: Room): ApiAction {
  return {
    [API_ACTION]: true,
    type: DELETE_ROOM,
    method: 'DELETE',
    endpoint: `rooms/${room.roomId}`,
    payloadCreator: () => room
  }
}

export function startMeeting(room: Room): ApiAction {
  return {
    [API_ACTION]: true,
    type: START_MEETING,
    method: 'POST',
    endpoint: `rooms/${room.roomId}/activate`,
    payloadCreator: () => room,
  }
}

export function endMeeting(room: Room): ApiAction {
  return {
    [API_ACTION]: true,
    type: END_MEETING,
    method: 'POST',
    endpoint: `rooms/${room.roomId}/deactivate`,
    payloadCreator: () => room
  }
}

export function uploadMedia(room: Room, uploadMedia: UploadMedia): ApiAction {
  const formData = createFormData(uploadMedia, 'postMediaToRoom');
  const endpoint = uploadMedia.mediaId ? `media/${uploadMedia.mediaId}` : `rooms/${room.roomId}/media`;

  return {
    [API_ACTION]: true,
    type: UPLOAD_MEDIA,
    endpoint,
    method: 'POST',
    bodyArgs: formData,
    payloadCreator: (result) => {
      const media: Media = {
        mediaId: +result.mediaId,
        ...result
      };

      return {
        room,
        media
      }
    }
  }
}

export function downloadMedia(room: Room, objectTag: string): ApiAction {
  return {
    [API_ACTION]: true,
    type: DOWNLOAD_MEDIA,
    endpoint: `rooms/${room.roomId}/media/download`,
    method: 'POST',
    bodyArgs: {objectTag},
    responseType: 'blob'
  }
}

export function getMedia(room: Room): ApiAction {
  return {
    [API_ACTION]: true,
    type: GET_MEDIA,
    endpoint: `rooms/${room.roomId}/media`,
    method: 'GET',
    payloadCreator: (media) => {
      return {
        room,
        media
      }
    }
  }
}

export function deleteMedia(media: Media): ApiAction {
  return {
    [API_ACTION]: true,
    type: DELETE_MEDIA,
    endpoint: `media/${media.mediaId}`,
    method: 'DELETE',
    payloadCreator: () => media
  }
}

export function getUrls(room: Room): ApiAction {
  return {
    [API_ACTION]: true,
    type: GET_URLS,
    endpoint: `rooms/${room.roomId}/urls`,
    method: 'GET',
    payloadCreator: (urls) => {
      return {
        room,
        urls
      }
    }
  }
}

export function addUrl(room: Room, url: Url): ApiAction {
  return {
    [API_ACTION]: true,
    type: ADD_URL,
    endpoint: `rooms/${room.roomId}/urls`,
    method: 'POST',
    bodyArgs: url,
    payloadCreator: (url) => {
      return {
        room,
        url
      }
    }
  }
}

export function deleteUrl(room: Room, url: Url): ApiAction {
  return {
    [API_ACTION]: true,
    type: DELETE_URL,
    endpoint: `rooms/${room.roomId}/urls/${url.urlId}`,
    method: 'DELETE',
    payloadCreator: () => url
  }
}

export function inviteUser(room: Room, userEmail: string, userName: string, isContributor: boolean): ApiAction {
  return {
    [API_ACTION]: true,
    type: INVITE_USER_TO_ROOM,
    method: 'POST',
    endpoint: `users/invite`,
    bodyArgs: {
      roomId: room.roomId,
      userEmail,
      userName,
      isContributor
    },
    payloadCreator: (user: User) => {
      return {
        user,
        room
      }
    }
  }
}

export function removeUser(room: Room, user: User): ApiAction {
  return {
    [API_ACTION]: true,
    type: REMOVE_USER_FROM_ROOM,
    method: 'DELETE',
    endpoint: `users/remove`,
    bodyArgs: {
      roomId: room.roomId,
      userEmail: user.email
    },
    payloadCreator: () => {
      return {
        user,
        room
      }
    }
  }
}

export function changeUserRole(room: Room, user: User, role: UserRole): ApiAction {
  return {
    [API_ACTION]: true,
    type: CHANGE_USER_ROLE,
    method: 'POST',
    endpoint: `rooms/${room.roomId}/users/${user.userId}/change-role`,
    bodyArgs: {
      role
    },
    payloadCreator: () => {
      return {
        user,
        room,
        role
      }
    }
  }
}

export function getRoomTemplates(): ApiAction {
  return {
    [API_ACTION]: true,
    type: GET_ROOM_TEMPLATES,
    method: 'GET',
    endpoint: 'room-templates'
  };
}

export function createOrUpdateRoomTemplate(template: UpdateRoomTemplate): ApiAction {
  const formData = createFormData(template, 'createOrUpdateRoomTemplate');

  return {
    [API_ACTION]: true,
    type: CREATE_OR_UPDATE_ROOM_TEMPLATE,
    method: 'POST',
    endpoint: 'room-templates',
    bodyArgs: formData
  }
}

export function deleteRoomTemplate(roomTemplate: RoomTemplate): ApiAction {
  return {
    [API_ACTION]: true,
    type: DELETE_ROOM_TEMPLATE,
    method: 'DELETE',
    endpoint: `room-templates/${roomTemplate.roomTemplateId}`,
    payloadCreator: () => roomTemplate
  }
}

export function setMediaOrder(room: Room, objectTag: ObjectTag, order: number[]): ApiAction {
  return {
    [API_ACTION]: true,
    type: SET_MEDIA_ORDER,
    method: 'POST',
    endpoint: `rooms/${room.roomId}/media/order`,
    bodyArgs: {
      objectTag,
      order
    },
    payloadCreator: () => {
      return {
        room,
        objectTag,
        order
      }
    }
  }
}