import {applyMiddleware, combineReducers, createStore} from "redux";
import thunkMiddleware from 'redux-thunk';
import {persistReducer, persistStore} from 'redux-persist';
import * as localForage from 'localforage';
import {AuthReducer, RoomReducer, SettingsReducer, UserReducer} from "./reducers";
import {ApiMiddleware} from './middleware'

// --- persistence config

const persistConfig = {
  key: 'root',
  storage: localForage,
  blacklist: ['auth']
};

const authPersistConfig = {
  key: 'auth',
  storage: localForage,
  whitelist: ['currentUser', 'sessionExpired']
};


// --- root reducer

const rootReducer = combineReducers({
  rooms: RoomReducer,
  users: UserReducer,
  settings: SettingsReducer,
  auth: persistReducer(authPersistConfig, AuthReducer)
});

export type RootState = ReturnType<typeof rootReducer>


// --- creating the store and persistor

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middlewareEnhancer = applyMiddleware(/*loggerMiddleware, */ApiMiddleware, thunkMiddleware);
const store = createStore(persistedReducer, undefined, middlewareEnhancer);
const persistor = persistStore(store);

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./reducers', () => store.replaceReducer(persistedReducer))
}

export {persistor, store};