import React, {FormEvent, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';

import {PASSWORD_MIN_LENGTH, PasswordChangeInputProps, PasswordRuleset} from "./types";
import {Label} from "../label";
import classNames from "classnames";
import {FiCheck, FiMinus} from "react-icons/all";
import {useTranslation} from "react-i18next";
import {
  I18N_PASSWORD,
  I18N_PASSWORD_EMPTY,
  I18N_PASSWORD_HAS_NUMBERS,
  I18N_PASSWORD_HAS_SPECIAL_CARS,
  I18N_PASSWORD_INVALID,
  I18N_PASSWORD_MATCH,
  I18N_PASSWORD_MIN_LENGTH,
  I18N_PASSWORD_NO_MATCH,
  I18N_REPEAT_PASSWORD
} from "../../translation";

export default forwardRef((props: PasswordChangeInputProps & React.ComponentProps<any>, ref: any) => {
  const {t} = useTranslation();
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
  const passwordRules = useMemo<PasswordRuleset>(() => {
    return {
      'min-length': {
        description: t(I18N_PASSWORD_MIN_LENGTH),
        rule: (password: string) => password.length >= PASSWORD_MIN_LENGTH
      },
      'numbers': {
        description: t(I18N_PASSWORD_HAS_NUMBERS),
        rule: /[0-9]/
      },
      'special-char': {
        description: t(I18N_PASSWORD_HAS_SPECIAL_CARS) + "<span class=\"password-rule-special-chars\">!?$%.-,:;~</span>",
        rule: /[!?$%.\-,:;~]/
      }
    };
  }, [t]);
  const rulesFulfilledMap = useMemo(() => {
    return Object.fromEntries(Object.entries(passwordRules).map(([ruleName, passwordRule]) => {
      const rule: any = passwordRule.rule;
      return [
        ruleName,
        rule instanceof Function ? rule(newPassword) : rule.test?.(newPassword)
      ]
    }))
  }, [passwordRules, newPassword]);
  const allRulesFulfilled = useMemo(() => {
    return Object.values(rulesFulfilledMap).every(fulfilled => fulfilled)
  }, [rulesFulfilledMap]);
  const passwordsMatch = useMemo(() => newPassword !== '' && newPassword === newPasswordRepeat, [newPassword, newPasswordRepeat]);
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRepeatRef = useRef<HTMLInputElement>(null);

  // --- exposed instance
  const clear = () => {
    setNewPassword('');
    setNewPasswordRepeat('');
  };

  useImperativeHandle(ref, () => ({
    clear
  }));


  // --- validation
  useEffect(() => {
    if (inputRef.current && inputRepeatRef.current) {
      let validationMessage = '';
      let repeatValidationMessage = '';

      if (props.required && !newPassword) {
        validationMessage = I18N_PASSWORD_EMPTY
      } else if (newPassword && !allRulesFulfilled) {
        validationMessage = I18N_PASSWORD_INVALID
      }

      if (newPassword && !passwordsMatch) {
        repeatValidationMessage = I18N_PASSWORD_NO_MATCH
      }

      inputRef.current.setCustomValidity(validationMessage && t(validationMessage));
      inputRepeatRef.current.setCustomValidity(repeatValidationMessage && t(repeatValidationMessage));
    }
  }, [t, props, inputRef, newPassword, newPasswordRepeat, passwordsMatch, allRulesFulfilled]);

  // --- event listeners
  useEffect(() => {
    props.onChange?.(newPassword)
  }, [props, newPassword]);

  useEffect(() => {
    props.onValidityChange?.(allRulesFulfilled && passwordsMatch)
  }, [props, allRulesFulfilled, passwordsMatch]);


  // --- internal event handlers
  const handleChangePassword = (e: FormEvent<HTMLInputElement>) => {
    setNewPassword(e.currentTarget.value)
  };

  const handleChangePasswordRepeat = (e: FormEvent<HTMLInputElement>) => {
    setNewPasswordRepeat(e.currentTarget.value)
  };

  return (
    <>
      <Label name={t(I18N_PASSWORD)} required={props.required}>
        <input type="password"
               ref={inputRef}
               required={props.required}
               onChange={handleChangePassword}
               onFocus={() => setInputFocused(true)}
               onBlur={() => setInputFocused(false)}
               autoComplete="new-password"/>
      </Label>

      <Label name={t(I18N_REPEAT_PASSWORD)} required={props.required}>
        <input type="password"
               ref={inputRepeatRef}
               required={props.required}
               onChange={handleChangePasswordRepeat}
               onFocus={() => setInputFocused(true)}
               onBlur={() => setInputFocused(false)}
               autoComplete="new-password"/>
      </Label>

      <ul className={classNames({
        'password-rules': true,
        'password-rules--hidden': props.hideRulesWhenNotFocused && (!inputFocused && !newPassword)
      })}>
        {Object.entries(passwordRules).map(([ruleName, rule]) => (
          <li key={ruleName} className={classNames({
            'password-rule': true,
            'password-rule--fulfilled': rulesFulfilledMap[ruleName]
          })}>
            {rulesFulfilledMap[ruleName] ? <FiCheck/> : <FiMinus/>}
            <span dangerouslySetInnerHTML={{__html: rule.description}}/>
          </li>
        ))}
        <li key="passwords-match" className={classNames({
          'password-rule': true,
          'password-rule--fulfilled': passwordsMatch
        })}>
          {passwordsMatch ? <FiCheck/> : <FiMinus/>}
          <span>{t(I18N_PASSWORD_MATCH)}</span>
        </li>
      </ul>
    </>
  )
})