import React, {FormEvent, useEffect, useMemo, useRef, useState} from "react";
import {DropdownProps} from "./types";
import classNames from "classnames";
import {useOnClickOutside, useOnKeyPress} from "../../hooks";
import {Card} from "../card";
import {I18N_NO_MATCHES_FOUND} from "../../translation";
import {useTranslation} from "react-i18next";

const defaultFilterSearch = (value: string, searchText: string) => value.includes(searchText);

export default (
  {
    items,
    renderItem,
    renderSelected,
    value,
    onChange,
    className,
    placeholder,
    filterSearch = defaultFilterSearch
  }: DropdownProps<any> & React.ComponentProps<any>
) => {
  const {t} = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value ?? items[0]);
  const [searchValue, setSearchValue] = useState('');
  const [highlightIndex, setHighlightIndex] = useState(0);
  const suggestions = useMemo(() => {
    return items.filter((item: any) => filterSearch(item, searchValue))
  }, [filterSearch, items, searchValue]);

  const handleChangeSearchText = (e: FormEvent<HTMLInputElement>) => setSearchValue(e.currentTarget.value);
  const open = () => {
    if (!isOpen) {
      setIsOpen(true)
    }
  };
  const close = () => {
    setIsOpen(false);
    setSearchValue('');
    ref.current?.focus()
  };
  const select = (item: any) => {
    setSelectedValue(item);
    close()
  };

  useEffect(() => {
    const index = suggestions.indexOf(selectedValue);
    setHighlightIndex(index > -1 ? index : 0);
    onChange?.(selectedValue)
  }, [suggestions, onChange, selectedValue]);

  useOnClickOutside(ref, close);

  useOnKeyPress({
    'ArrowUp': () => {
      if (!isFocused) {
        return
      }

      if (isOpen) {
        const previousIndex = (highlightIndex - 1 + suggestions.length) % suggestions.length;
        setHighlightIndex(previousIndex)
      } else {
        open()
      }
    },
    'ArrowDown': () => {
      if (!isFocused) {
        return
      }

      if (isOpen) {
        const nextIndex = (highlightIndex + 1 + suggestions.length) % suggestions.length;
        setHighlightIndex(nextIndex)
      } else {
        open()
      }
    },
    'Enter': () => {
      if (!isFocused) {
        return
      }

      if (isOpen) {
        select(suggestions[highlightIndex])
      } else {
        open()
      }
    },
    'Escape': () => {
      if (!isFocused || !isOpen) {
        return
      }

      close()
    },
    ' ': (e) => {
      if (!isFocused) {
        return
      }

      if (!isOpen) {
        e.preventDefault()
        open()
      }
    }
  })

  return (
    <div
      ref={ref}
      tabIndex={0}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onClick={() => open()}
      className={classNames({
        [className]: true,
        'dropdown': true,
        'dropdown--open': isOpen
      })}>
      {isOpen
        ? (
          <input
            ref={inputRef}
            className="dropdown-input"
            type="text"
            placeholder={placeholder}
            value={searchValue}
            autoFocus={isOpen}
            onChange={handleChangeSearchText}/>
        )
        : (
          <div className={classNames({
            "dropdown-input-wrapper": true,
            "input": true,
            "focused": isOpen
          })}>
            <div className="dropdown-input-selection" onClick={() => open()}>
              {renderSelected?.(selectedValue) ?? selectedValue}

              <svg className="dropdown-arrow" width="10" height="3" viewBox="0 0 2 2" preserveAspectRatio="none">
                <path d="M0,0L2,0L1,2Z" fill="currentColor"/>
              </svg>
            </div>
          </div>
        )
      }
      <Card className="dropdown-options-wrapper">
        <ul className="dropdown-options">
          {suggestions.length
            ? suggestions.map((item: any, i: number) => (
              <li
                key={i}
                className={classNames({
                  'dropdown-option': true,
                  'dropdown-option--highlighted': i === highlightIndex,
                })}
                onMouseOver={() => setHighlightIndex(i)}
                onClick={() => select(item)}>
                {renderItem(item)}
              </li>
            ))
            : (
              <span className="dropdown-no-match">{t(I18N_NO_MATCHES_FOUND)}</span>
            )
          }
        </ul>
      </Card>
    </div>
  )
}