import React, {ComponentProps, useRef} from 'react';
import {OverlayProps} from "./types";
import {useOnKeyPress, useOnClickSelf} from "../../hooks";

export default (props: OverlayProps | ComponentProps<any> = {visible: true}) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickSelf(ref, () => {
    if (props.onClose) {
      props.onClose()
    }
  });
  useOnKeyPress({
    'Escape': () => {
      if (props.onClose) {
        props.onClose();
      }
    }
  });

  return (props.visible && (
    <div className="overlay" ref={ref}>
      <div className="overlay-content">
        {props.children}
      </div>
    </div>
  ))
}