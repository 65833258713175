import React from "react";
// @ts-ignore
import JSONInput from 'react-json-editor-ajrm';
// @ts-ignore
import locale from 'react-json-editor-ajrm/locale/en';
import {useSelector} from "react-redux";
import {RootState} from "../../store/configure";
import {JsonEditorProps} from "./types";

const themeDark = {
  default: '#D4D4D4',
  background: 'var(--color-background)',
  background_warning: '#1E1E1E',
  string: '#CE8453',
  number: '#B5CE9F',
  colon: '#49B8F7',
  keys: '#9CDCFE',
  keys_whiteSpace: '#AF74A5',
  primitive: '#6392c6'
};

const themeLight = {
  default: '#D4D4D4',
  background: '#fff',
  background_warning: '#FEECEB',
  string: '#FA7921',
  number: '#70CE35',
  colon: '#49B8F7',
  keys: '#59A5D8',
  keys_whiteSpace: '#835FB6',
  primitive: '#386fa4'
};

const jsonInputStyle = {
  outerBox: {
    borderRadius: '4px',
    border: '1px solid var(--border-color-input)',
    boxSizing: 'content-box',
    overflow: 'auto'
  },
  labelColumn: {
    width: '2.5em'
  }
};

export default ({placeholder, onChange}: JsonEditorProps) => {
  const {darkMode} = useSelector((state: RootState) => state.settings);
  return (
    <JSONInput
      placeholder={placeholder}
      locale={locale}
      height="200px"
      width="300px"
      colors={darkMode ? themeDark : themeLight}
      confirmGood={false}
      waitAfterKeyPress={200}
      style={jsonInputStyle}
      onChange={onChange}/>
  )
}