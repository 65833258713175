import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store/configure";

export default (action: Function, actionParamCreator: Function = () => []): [boolean, string | null] => {
  const dispatch = useDispatch();
  const {sessionExpired, currentUser} = useSelector((state: RootState) => state.auth);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadContent = async () => {
      if (sessionExpired || !currentUser?.jwtToken) {
        return
      }

      setIsLoading(true);

      try {
        const actionParams = actionParamCreator();
        dispatch(action(...actionParams))
      } catch (e) {
        console.error(e);
        setError(e)
      } finally {
        setIsLoading(false);
      }
    };

    loadContent()
      .catch(e => {
        setError(e)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return [isLoading, error]
}