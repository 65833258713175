import React, {FormEvent, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Room, RoomTemplate} from "../../store/modules/room/types";
import {createRoom} from "../../store/modules/room/actions";

import {Grid} from "../../components/grid";
import {RoomCard} from "../../components/room-card";
import {Card} from "../../components/card";

import {useCapability} from "../../hooks";

import RoomRoute from './room'
import RoomTemplateRoute from './templates'
import {Button} from "../../components/button";
import {FiPlus} from "react-icons/all";
import {Overlay} from "../../components/overlay";
import {RoomIcon} from "../../icons";
import {Label} from "../../components/label";
import {RootState} from "../../store/configure";
import {FileInput} from "../../components/file-input";
import {NO_FILE} from "../../store/types";
import {refreshCurrentUser} from "../../store/modules/auth/actions";
import {ApiAction} from "../../store/middleware/api/types";
import {ThunkDispatch} from "redux-thunk";
import {
  I18N_ADD_ROOM,
  I18N_CANCEL,
  I18N_CREATE,
  I18N_CREATE_NEW_ROOM,
  I18N_JSON_DATA,
  I18N_MANAGE_ROOMS,
  I18N_NAME,
  I18N_TEMPLATE
} from "../../translation";
import {useTranslation} from "react-i18next";
import {JsonEditor} from "../../components/json-editor";

type ApiDispatch = ThunkDispatch<RootState, {}, ApiAction>

const Rooms = () => {
  const {t} = useTranslation();
  const dispatch: any = useDispatch<ApiDispatch>();
  const canCreateRoom = useCapability('create_room');
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const rooms = useSelector((state: RootState) => state.rooms.rooms);
  const roomTemplates = useSelector((state: RootState) => state.rooms.templates);
  const [roomFormVisible, setFormVisible] = useState(false);
  const [roomName, setRoomName] = useState('');
  const [selectedRoomTemplate, setSelectedRoomTemplate] = useState<RoomTemplate | undefined>(undefined);
  const [thumbnail, setThumbnail] = useState<File | undefined>(undefined);
  const [json, setJson] = useState<string | undefined>(undefined);

  const handleJsonToggle = (toggled: boolean) => {
    if (!toggled) {
      setJson(undefined)
    }
  };

  const handleJson = (input: any) => setJson(input.json);

  const submitRoom = (e: FormEvent) => {
    e.preventDefault();

    if (currentUser?.userId && selectedRoomTemplate) {
      dispatch(createRoom({
        name: roomName,
        roomTemplateId: selectedRoomTemplate.roomTemplateId,
        thumbnail,
        jsonData: json
      }))
        .then(() => {
          dispatch(refreshCurrentUser())
        });

      setFormVisible(false);
      setRoomName('');
    }

    return false;
  };

  const handleChangeRoomTemplate = (e: FormEvent<HTMLSelectElement>) => {
    setSelectedRoomTemplate(roomTemplates.find(template => template.roomTemplateId === +e.currentTarget.value));
  };

  const handleThumbnailChange = (file: File | NO_FILE | undefined) => {
    setThumbnail(file ?? undefined)
  };

  const showForm = () => {
    if (roomTemplates.length) {
      setSelectedRoomTemplate(roomTemplates[0]);
      setFormVisible(true)
    }
  };

  return (
    <>
      <h2>{t(I18N_MANAGE_ROOMS)}</h2>
      {rooms && (
        <Grid>
          {rooms && rooms.map((room: Room) => (
            <RoomCard room={room} key={room.name}/>
          ))}

          {canCreateRoom && roomTemplates.length > 0 && (
            <Card empty={true} onClick={showForm}>
              <RoomIcon width={60} height={66}/>
              <div>{t(I18N_ADD_ROOM)}</div>
            </Card>
          )}
        </Grid>
      )}

      <Overlay visible={roomFormVisible} onClose={() => setFormVisible(false)}>
        <Card>
          <form onSubmit={submitRoom}>
            <h4>{t(I18N_CREATE_NEW_ROOM)}</h4>

            <Label name={t(I18N_NAME)} required>
              <input type="text" name="name" required autoFocus onChange={e => setRoomName(e.target.value)}/>
            </Label>

            <Label name={t(I18N_TEMPLATE)} required>
              <select onChange={handleChangeRoomTemplate} required>
                {roomTemplates.map(template => (
                  <option key={template.roomTemplateId} value={template.roomTemplateId}>{template.name}</option>
                ))}
              </select>
              <FileInput
                previousMedia={selectedRoomTemplate
                  ? {
                    url: selectedRoomTemplate.thumbnail,
                    name: selectedRoomTemplate.name,
                    fileType: 'image'
                  }
                  : undefined
                }
                resettable
                onChange={handleThumbnailChange}/>
            </Label>

            <Label name={t(I18N_JSON_DATA)} toggleable toggled={false} onToggle={handleJsonToggle}>
              <JsonEditor onChange={handleJson}/>
            </Label>

            <div className="buttons-container">
              <Button type="cancel" onClick={() => setFormVisible(false)}>{t(I18N_CANCEL)}</Button>
              <Button type="submit"><FiPlus/>{t(I18N_CREATE)}</Button>
            </div>
          </form>
        </Card>
      </Overlay>
    </>
  )
};

export {RoomRoute, RoomTemplateRoute};
export default Rooms;