import {
  ADD_URL,
  CHANGE_USER_ROLE,
  CREATE_OR_UPDATE_ROOM_TEMPLATE,
  CREATE_ROOM,
  DELETE_MEDIA,
  DELETE_ROOM,
  DELETE_ROOM_TEMPLATE,
  DELETE_URL,
  DOWNLOAD_MEDIA,
  END_MEETING,
  GET_MEDIA,
  GET_ROOM_TEMPLATES,
  GET_ROOMS,
  GET_URLS,
  INVITE_USER_TO_ROOM,
  REMOVE_USER_FROM_ROOM,
  RoomActionTypes,
  RoomState,
  SET_MEDIA_ORDER,
  START_MEETING,
  UPDATE_ROOM,
  UPLOAD_MEDIA,
} from "./types";

const download = require('downloadjs');

const initialState: RoomState = {
  rooms: [],
  templates: [],
  isLoading: true
};

export default (state = initialState, action: RoomActionTypes): RoomState => {
  switch (action.type) {
    case GET_ROOMS:
      return {
        ...state,
        rooms: action.payload,
        isLoading: false
      };

    case CREATE_ROOM:
      return {
        ...state,
        rooms: [
          ...state.rooms,
          action.payload
        ]
      };

    case UPDATE_ROOM:
      return {
        ...state,
        rooms: state.rooms.map(room => {
          if (room.roomId === action.payload.roomId) {
            const {users, media, urls, ...restRoomProps} = action.payload;

            return {
              ...room,
              ...restRoomProps
            };
          }
          return room
        })
      };

    case DELETE_ROOM:
      return {
        ...state,
        rooms: state.rooms.filter(room => room.roomId !== action.payload.roomId)
      };

    case START_MEETING:
      return {
        ...state,
        rooms: state.rooms.map(room => {
          return room.roomId === action.payload.roomId ? {...room, active: true} : room
        })
      };

    case END_MEETING:
      return {
        ...state,
        rooms: state.rooms.map(room => {
          return room.roomId === action.payload.roomId ? {...room, active: false} : room
        })
      };

    case INVITE_USER_TO_ROOM:
      return {
        ...state,
        rooms: state.rooms.map(room => {
          if (room.roomId === action.payload.room.roomId) {
            room.users.push(action.payload.user)
          }

          return room
        })
      };

    case REMOVE_USER_FROM_ROOM:
      return {
        ...state,
        rooms: state.rooms.map(room => {
          if (room.roomId === action.payload.room.roomId) {
            room.users = room.users.filter(user => user.userId !== action.payload.user.userId)
          }

          return room
        })
      };

    case GET_MEDIA:
      return {
        ...state,
        rooms: state.rooms.map(room => {
          if (room.roomId === action.payload.room.roomId) {
            room.media = action.payload.media
          }

          return room
        })
      };

    case UPLOAD_MEDIA:
      return {
        ...state,
        rooms: state.rooms.map(room => {
          if (room.roomId === action.payload.room.roomId) {
            room.media = [
              ...room.media.filter(media => media.mediaId !== action.payload.media.mediaId),
              action.payload.media
            ]
          }

          return room
        })
      };

    case DELETE_MEDIA:
      return {
        ...state,
        rooms: state.rooms.map(room => {
          room.media = room.media.filter(media => media.mediaId !== action.payload.mediaId);

          return room
        })
      };

    case DOWNLOAD_MEDIA:
      download(action.payload);
      return state;

    case GET_URLS:
      return {
        ...state,
        rooms: state.rooms.map(room => {
          if (room.roomId === action.payload.room.roomId) {
            room.urls = action.payload.urls
          }

          return room
        })
      };

    case ADD_URL:
      return {
        ...state,
        rooms: state.rooms.map(room => {
          if (room.roomId === action.payload.room.roomId) {
            room.urls = [
              ...room.urls,
              action.payload.url
            ]
          }

          return room
        })
      };

    case DELETE_URL:
      return {
        ...state,
        rooms: state.rooms.map(room => {
          room.urls = room.urls.filter(url => url.urlId !== action.payload.urlId);

          return room
        })
      };

    case GET_ROOM_TEMPLATES:
      return {
        ...state,
        templates: action.payload
      };

    case CREATE_OR_UPDATE_ROOM_TEMPLATE:
      const templates = [
        ...state.templates.filter(template => template.roomTemplateId !== action.payload.roomTemplateId),
        action.payload
      ].sort((a, b) => (b.roomTemplateId ?? 0) - (a.roomTemplateId ?? 0));

      return {
        ...state,
        templates
      };

    case DELETE_ROOM_TEMPLATE:
      return {
        ...state,
        templates: state.templates.filter(template => template.roomTemplateId !== action.payload.roomTemplateId)
      };

    case SET_MEDIA_ORDER:
      return {
        ...state,
        rooms: state.rooms.map(room => {
          if (room.roomId === action.payload.room.roomId) {
            room.mediaOrder = {
              ...room.mediaOrder,
              [action.payload.objectTag]: action.payload.order
            }
          }

          return room
        })
      };

    case CHANGE_USER_ROLE:
      const stateCopy = JSON.parse(JSON.stringify(state)) as RoomState;
      const {room, user, role} = action.payload;

      const u = stateCopy.rooms.find(({roomId}) => roomId === room.roomId)
        ?.users
        .find(({userId}) => userId === user.userId);

      if (u) {
        u.roles = [role]
      }

      return stateCopy;

    default:
      return state;
  }
};