import React, {FormEvent, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {
  addUrl,
  changeUserRole,
  deleteRoom,
  deleteUrl,
  downloadMedia,
  endMeeting,
  getMedia,
  getUrls,
  inviteUser,
  removeUser,
  setMediaOrder,
  startMeeting,
  updateRoom,
} from "../../store/modules/room/actions";

import {Card} from "../../components/card";
import {useCapability, usePrompt, useRoom} from "../../hooks";
import {User} from "../../store/modules/user/types";

import {Grid} from "../../components/grid";
import {
  FiDownload,
  FiEdit,
  FiExternalLink,
  FiEyeOff,
  FiLink2,
  FiPlay,
  FiSave,
  FiSlash,
  FiTrash2,
  FiUpload,
  FiUserMinus,
  FiUserPlus
} from "react-icons/fi";
import {Media, ObjectTag, Url} from "../../store/modules/room/types";
import {Overlay} from "../../components/overlay";
import {Loader} from "../../components/loader";
import {UserListItem} from "../../components/user-list-item";
import {AuthUser} from "../../store/modules/auth/types";
import {Button} from "../../components/button";
import {Label} from "../../components/label";
import {FileInput} from "../../components/file-input";
import {NO_FILE} from "../../store/types";
import {ObjectTagHiddenPrefix} from "../../components/object-tag/types";
import {TextEditor} from "../../components/text-editor";
import {
  I18N_ACTIONS,
  I18N_ADD_LINK,
  I18N_ADD_LINKS,
  I18N_ADD_MEDIA,
  I18N_BREADCRUMB_ROOMS,
  I18N_CANCEL,
  I18N_CONFIRMED,
  I18N_CONTRIBUTOR,
  I18N_DELETE,
  I18N_DELETE_CONFIRMATION,
  I18N_DESCRIPTION,
  I18N_DOWNLOAD_AS_ZIP,
  I18N_DROPZONE_TEXT,
  I18N_EDIT,
  I18N_EDIT_ROOM,
  I18N_EMAIL,
  I18N_END_MEETING,
  I18N_INFORMATION,
  I18N_INVITE,
  I18N_INVITE_USER,
  I18N_INVITE_USERS,
  I18N_JSON_DATA,
  I18N_MEDIA,
  I18N_MEETING_IN_PROGRESS,
  I18N_MEETING_NOT_STARTED,
  I18N_NAME,
  I18N_OBJECT_TAG,
  I18N_REMOVE,
  I18N_REMOVE_USER_CONFIRMATION,
  I18N_ROLE,
  I18N_ROOM_NO_MEDIA,
  I18N_ROOM_NO_URLS,
  I18N_ROOM_NO_USERS,
  I18N_SAVE,
  I18N_START_MEETING,
  I18N_TEMPLATE,
  I18N_THUMBNAIL,
  I18N_URL,
  I18N_USER_INVITATION_NOTE,
  I18N_USERS,
  REPLACE_I18N_TOKEN
} from "../../translation";
import {JsonEditor} from "../../components/json-editor";
import {MediaOverlay} from "../../components/media-overlay";
import {MediaCard} from "../../components/media-card";
import {MediaOverlayRef} from "../../components/media-overlay/types";
import classNames from "classnames";

interface RoomRouteProps {
  match: {
    params: {
      roomId: number
    }
  }
}

const sortByKey = (obj: { [key: string]: any }): { [key: string]: any } => {
  const sorted: any = {};
  const keys = Object.keys(obj).sort((a, b) => a > b ? 1 : -1);

  for (let key of keys) {
    sorted[key] = obj[key];
  }

  return sorted
};

const groupByObjectTagSorted = (items: Media[] | Url[], visibleObjectTags: ObjectTag[]) => {
  const grouped: { [key: string]: Url[] } = {};

  for (let objectTag of visibleObjectTags) {
    grouped[objectTag] = [];
  }

  if (items) {
    for (let item of items) {
      if (!visibleObjectTags.includes(item.objectTag)) {
        continue;
      }

      grouped[item.objectTag].push(item)
    }
  }

  return sortByKey(grouped);
};

export default withRouter((props: RoomRouteProps & RouteComponentProps) => {
  const {t} = useTranslation();

  const roomId = +props.match.params.roomId;

  const dispatch: any = useDispatch();
  const currentUser: AuthUser = useSelector((state: any) => state.auth.currentUser);
  const {room, error} = useRoom(roomId);
  const allMedia = room?.media;
  const allUrls = room?.urls;

  /* --- Room --- */
  const canEditRoom = useCapability('edit_room', {room});
  const canDeleteRoom = useCapability('delete_room', {room});
  const [editRoomFormVisible, setEditRoomFormVisible] = useState(false);
  const [roomName, setRoomName] = useState('');
  const [roomDescription, setRoomDescription] = useState(room?.description ? JSON.parse(room.description) : undefined);
  const [thumbnail, setThumbnail] = useState<File | NO_FILE | undefined>(undefined);
  const [json, setJson] = useState<string | undefined>(undefined);
  const jsonPlaceholder = useMemo(() => {
    if (room?.jsonData) {
      try {
        return JSON.parse(room.jsonData)
      } catch (e) {
        return undefined
      }
    } else {
      return undefined
    }
  }, [room]);

  /* --- Users --- */
  const canEditUsers = useCapability('edit_users', {room});
  const [userInviteFormVisible, setUserInviteFormVisible] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserName, setNewUserName] = useState('');
  const [newUserIsContributor, setNewUserIsContributor] = useState(false);


  /* --- Media --- */
  const canDeleteMedia = useCapability('edit_media', {room});
  const canEditMedia = useCapability('edit_media', {room});
  const canUploadMedia = useCapability('edit_media', {room});
  const mediaOverlayRef = useRef<MediaOverlayRef>();
  // store media order even while saving to avoid visual glitches
  const [tmpMediaOrder, setTmpMediaOrder] = useState(room?.mediaOrder);

  /* --- Urls --- */
  const canEditUrls = useCapability('edit_urls', {room});
  const [urlFormVisible, setUrlFormVisible] = useState(false);
  const [newUrl, setNewUrl] = useState('');
  const [newUrlName, setNewUrlName] = useState('');
  const [newUrlObjectTag, setNewUrlObjectTag] = useState('');

  const visibleObjectTags = useMemo(() => {
    if (canUploadMedia) {
      return room?.objectTags ?? []
    } else {
      return room?.objectTags.filter(t => !t.includes(ObjectTagHiddenPrefix)) ?? []
    }
  }, [room, canUploadMedia]);
  const mediaGroupedByTag = useMemo(() => {
    return allMedia ? groupByObjectTagSorted(allMedia, visibleObjectTags) : {}
  }, [allMedia, visibleObjectTags]);
  const urlsGroupedByTag = useMemo(() => {
    return allUrls ? groupByObjectTagSorted(allUrls, visibleObjectTags) : {}
  }, [allUrls, visibleObjectTags]);
  const renderDescription = useMemo(() => {
    if (room?.description) {
      return <TextEditor readOnly value={JSON.parse(room.description)}/>
    }

    return null
  }, [room]);

  useEffect(() => {
    if (room) {
      dispatch(getMedia(room));
      dispatch(getUrls(room));

      setNewUrlObjectTag(room.objectTags[0]);
      setRoomName(room.name);
    }
  }, [dispatch, room]);

  useEffect(() => {
    setTmpMediaOrder(room?.mediaOrder)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room?.mediaOrder]);

  const promptDeleteUser = (e: any, user: User) => {
    if (room) {
      const promptMessage = t(I18N_REMOVE_USER_CONFIRMATION).replace(REPLACE_I18N_TOKEN, user.displayName);
      if (window.confirm(promptMessage)) {
        dispatch(removeUser(room, user))
      }
    }
  };

  const [promptDeleteRoom, promptDeleteRoomOverlay] = usePrompt({
    text: t(I18N_DELETE_CONFIRMATION).replace(REPLACE_I18N_TOKEN, room?.name ?? ''),
    onConfirm: () => {
      if (!room) {
        return
      }

      dispatch(deleteRoom(room))
        .then(() => {
          props.history.push('/rooms')
        })
    },
    confirmButtonText: t(I18N_DELETE)
  });

  const promptDeleteUrl = (url: Url) => {
    const promptText = t(I18N_DELETE_CONFIRMATION).replace(REPLACE_I18N_TOKEN, url.name);

    if (room && window.confirm(promptText)) {
      dispatch(deleteUrl(room, url))
    }
  };

  const submitUrl = (e: FormEvent) => {
    e.preventDefault();

    if (room && newUrl && newUrlObjectTag) {
      dispatch(addUrl(room, {
        url: newUrl,
        name: newUrlName,
        objectTag: newUrlObjectTag
      }));

      setNewUrl('');
      setNewUrlName('');
      setNewUrlObjectTag('');
      setUrlFormVisible(false)
    }
  };

  const submitRoom = (e: FormEvent) => {
    e.preventDefault();

    if (room && roomName) {
      dispatch(updateRoom({
        roomId: room.roomId,
        name: roomName,
        thumbnail: thumbnail ?? undefined,
        description: roomDescription ? JSON.stringify(roomDescription) : undefined,
        jsonData: json
      }));

      setEditRoomFormVisible(false)
    }
  };

  const submitInviteUser = async (e: FormEvent) => {
    e.preventDefault();

    if (room) {
      dispatch(inviteUser(room, newUserEmail, newUserName, newUserIsContributor));
      setNewUserEmail('');
      setNewUserName('');
      setNewUserIsContributor(false);
      setUserInviteFormVisible(false)
    }
  };

  const handleNewUrl = (e: FormEvent<HTMLInputElement>) => {
    setNewUrl(e.currentTarget.value)
  };

  const handleNewUrlName = (e: FormEvent<HTMLInputElement>) => {
    setNewUrlName(e.currentTarget.value)
  };

  const handleNewUrlObjectTag = (e: FormEvent<HTMLSelectElement>) => {
    setNewUrlObjectTag(e.currentTarget.value)
  };

  const handleRoomName = (e: FormEvent<HTMLInputElement>) => {
    setRoomName(e.currentTarget.value);
  };

  const handleRoomDescription = (value: any) => {
    setRoomDescription(value)
  };

  const handleRoomDescriptionToggle = (checked: boolean) => {
    if (!checked) {
      setRoomDescription(undefined);
    }
  };

  const handleRoomJsonToggle = (toggled: boolean) => {
    if (!toggled) {
      setJson(undefined)
    }
  };

  const handleRoomJson = (input: any) => setJson(input.json);

  const handleEditMedia = (media: Media, file?: File) => {
    mediaOverlayRef.current?.open({
      media,
      objectTag: media?.objectTag,
      file
    })
  };

  const handleMediaDrop = (objectTag: ObjectTag, file: File, media?: Media) => {
    if (media) {
      handleEditMedia(media, file);
    } else {
      openAddMediaOverlay(objectTag, file);
    }
  };

  const downloadAllMediaByObjectTag = (objectTag: string) => {
    if (room) {
      dispatch(downloadMedia(room, objectTag))
    }
  };

  const dispatchMeetingActive = (active: boolean) => {
    if (room) {
      if (active) {
        dispatch(startMeeting(room))
      } else {
        dispatch(endMeeting(room))
      }
    }
  };

  const openAddMediaOverlay = (objectTag?: ObjectTag, file?: File) => {
    mediaOverlayRef.current?.open({
      objectTag,
      file
    })
  };

  const changeMediaOrder = (objectTag: ObjectTag, order: number[]) => {
    if (!room) {
      return
    }

    setTmpMediaOrder({
      ...tmpMediaOrder,
      [objectTag]: order
    });

    dispatch(setMediaOrder(room, objectTag, order))
  };

  const handleNewUserRole = (toggled: boolean) => {
    setNewUserIsContributor(toggled)
  };

  if (room) {
    return (
      <>
        <ul className="breadcrumb">
          <li><Link to="/rooms">{t(I18N_BREADCRUMB_ROOMS)}</Link></li>
          <li><Link to={`/rooms/${roomId}`}>{room.name}</Link></li>
        </ul>

        <h1 className="header">
          {room.name}

          <div className="header-actions">
            {canEditRoom && (
              <span className="link header-action" onClick={() => setEditRoomFormVisible(true)}>
                <FiEdit/>
                {t(I18N_EDIT)}
              </span>
            )}
            {canDeleteRoom && (
              <span className="link header-action" onClick={promptDeleteRoom}>
                <FiTrash2/>
                {t(I18N_DELETE)}
              </span>
            )}
            {canEditRoom && (
              room.active
                ? (
                  <span className="link header-action" onClick={() => dispatchMeetingActive(false)}>
                    <FiSlash/>
                    {t(I18N_END_MEETING)}
                  </span>
                )
                : (
                  <span className="link header-action" onClick={() => dispatchMeetingActive(true)}>
                    <FiPlay/>
                    {t(I18N_START_MEETING)}
                  </span>
                )
            )}
          </div>
        </h1>

        <div className="room-status">
          <div
            className={classNames({
              'room-indicator': true,
              'room-indicator--active': room.active
            })}
            title={'Room is ' + (room.active ? 'active' : 'not active')}/>
          {t(room.active ? I18N_MEETING_IN_PROGRESS : I18N_MEETING_NOT_STARTED)}
        </div>

        {room.description && (
          <section key="description">
            <h2 className="header">{t(I18N_INFORMATION)}</h2>

            <Card>
              {renderDescription}
            </Card>
          </section>
        )}

        <section key="users">
          <h2 className="header">
            {t(I18N_USERS)}
            {canEditUsers && (
              <span className="link header-action"
                    onClick={() => setUserInviteFormVisible(true)}><FiUserPlus/> {t(I18N_INVITE_USERS)}</span>
            )}
          </h2>

          {room.users.length
            ? (
              <Card>
                <ul className="user-list">
                  <li className="user-list-header">
                    <span/>
                    <span>{t(I18N_NAME)}</span>
                    <span>{t(I18N_EMAIL)}</span>
                    <span>{t(I18N_CONFIRMED)}</span>
                    <span>{t(I18N_ROLE)}</span>
                    <span>{t(I18N_ACTIONS)}</span>
                  </li>
                  {room.users.map((user) => (
                    <UserListItem
                      user={user}
                      key={user.userId}
                      onRoleChange={
                        canEditUsers
                          ? (role) => dispatch(changeUserRole(room, user, role))
                          : undefined
                      }
                      renderActions={() => user.userId !== currentUser?.userId && canEditUsers && (
                        <div className="link" onClick={(e) => promptDeleteUser(e, user)}>
                          <FiUserMinus/>{t(I18N_REMOVE)}
                        </div>
                      )}/>
                  ))}
                </ul>
              </Card>
            )
            : (
              <div>{t(I18N_ROOM_NO_USERS)}</div>
            )
          }
        </section>

        <section key="media">
          <h2 className="header">
            {t(I18N_MEDIA)}
            {canUploadMedia && (
              <span className="link header-action" onClick={() => openAddMediaOverlay()}><FiUpload/> {t(I18N_ADD_MEDIA)}</span>
            )}
          </h2>

          {!visibleObjectTags.length || !Object.values(mediaGroupedByTag).flat().length
            ? (
              <div>{t(I18N_ROOM_NO_MEDIA)}</div>
            )
            : visibleObjectTags.map(objectTag => (canUploadMedia || mediaGroupedByTag[objectTag].length > 0) && (
              <React.Fragment key={objectTag}>
                <h3 className="header">
                  {objectTag.includes(ObjectTagHiddenPrefix)
                    ? (
                      <>
                        <FiEyeOff/>{objectTag.replace(ObjectTagHiddenPrefix, '')}
                      </>
                    )
                    : objectTag}
                  {mediaGroupedByTag[objectTag].length > 0 && (
                    <span className="link header-action" onClick={() => downloadAllMediaByObjectTag(objectTag)}>
                      <FiDownload/> {t(I18N_DOWNLOAD_AS_ZIP)}
                    </span>
                  )}
                </h3>

                <Grid
                  className="media-grid"
                  sortable={canEditMedia}
                  onOrder={(order: number[]) => changeMediaOrder(objectTag, order)}>
                  {
                    mediaGroupedByTag[objectTag]
                      .sort((a: Media, b: Media) => {
                        if (tmpMediaOrder?.[objectTag]) {
                          const order = tmpMediaOrder[objectTag];
                          return order.indexOf(a.mediaId) > order.indexOf(b.mediaId) ? 1 : -1;
                        }

                        return a.mediaId > b.mediaId ? 1 : -1
                      })
                      .map((media: Media) => (
                        <MediaCard
                          key={media.mediaId}
                          media={media}
                          canEdit={canEditMedia}
                          canDelete={canDeleteMedia}
                          onEdit={handleEditMedia}
                          onDrop={canEditMedia ? (file: File) => handleMediaDrop(objectTag, file, media) : undefined}
                          item={{
                            type: 'MEDIA',
                            id: media.mediaId
                          }}/>
                      ))
                  }
                  {canUploadMedia && (
                    <Card
                      empty={true}
                      onClick={() => openAddMediaOverlay(objectTag)}
                      onDrop={(file: File) => handleMediaDrop(objectTag, file)}>
                      <FiUpload className="icon"/>
                      <div dangerouslySetInnerHTML={{__html: t(I18N_DROPZONE_TEXT)}}/>
                    </Card>
                  )}
                </Grid>
              </React.Fragment>
            ))
          }
        </section>

        <section key="urls">
          <h2 className="header">
            URLs
            {canEditUrls && (
              <span className="link header-action"
                    onClick={() => setUrlFormVisible(true)}><FiLink2/> {t(I18N_ADD_LINKS)}</span>
            )}
          </h2>

          {!Object.values(urlsGroupedByTag).flat(1).length
            ? (
              <div>{t(I18N_ROOM_NO_URLS)}</div>
            )
            : visibleObjectTags.map(objectTag => urlsGroupedByTag[objectTag].length > 0 && (
              <React.Fragment key={objectTag}>
                <h3 className="header">
                  {objectTag.includes(ObjectTagHiddenPrefix)
                    ? (
                      <>
                        <FiEyeOff/>{objectTag.replace(ObjectTagHiddenPrefix, '')}
                      </>
                    )
                    : objectTag}
                </h3>

                <Grid className="media-grid">
                  {
                    urlsGroupedByTag[objectTag].map((url: Url) => (
                      <Card key={url.urlId}>
                        <h4>{url.name}</h4>

                        <div className="buttons-container">
                          {canEditUrls &&
                          <Button type="cancel" icon onClick={() => promptDeleteUrl(url)}><FiTrash2/></Button>}
                          <a href={url.url} target="_blank" rel="noopener noreferrer"
                             className="button button--icon"><FiExternalLink/></a>
                        </div>
                      </Card>
                    ))
                  }
                </Grid>
              </React.Fragment>
            ))
          }

        </section>

        <Overlay title={t(I18N_EDIT_ROOM)} visible={editRoomFormVisible} onClose={() => setEditRoomFormVisible(false)}>
          <Card>
            <form onSubmit={submitRoom}>
              <h2 className="card-header">{t(I18N_EDIT_ROOM)}</h2>
              <Label name={t(I18N_NAME)} required>
                <input autoFocus={editRoomFormVisible} name="name" type="text" value={roomName}
                       onChange={handleRoomName}/>
              </Label>

              <Label name={t(I18N_TEMPLATE)}>
                <select disabled>
                  <option>{room.roomTemplate.name}</option>
                </select>
              </Label>

              <Label name={t(I18N_THUMBNAIL)} required>
                <FileInput
                  onChange={(file) => setThumbnail(file)}
                  previousMedia={room ? {
                    url: room.thumbnail,
                    name: room.name,
                    fileType: 'image'
                  } : undefined}
                  resettable
                  value={thumbnail ?? undefined}/>
              </Label>

              <Label name={t(I18N_DESCRIPTION)} toggleable toggled={!!roomDescription}
                     onToggle={handleRoomDescriptionToggle}>
                <TextEditor value={roomDescription} onChange={handleRoomDescription}/>
              </Label>

              <Label name={t(I18N_JSON_DATA)} toggleable toggled={!!room?.jsonData} onToggle={handleRoomJsonToggle}>
                <JsonEditor placeholder={jsonPlaceholder} onChange={handleRoomJson}/>
              </Label>

              <div className="buttons-container">
                <Button type="cancel" onClick={() => setEditRoomFormVisible(false)}>{t(I18N_CANCEL)}</Button>
                <Button type="submit">
                  <FiSave/>{t(I18N_SAVE)}
                </Button>
              </div>
            </form>
          </Card>
        </Overlay>

        <Overlay title={I18N_INVITE_USER} visible={userInviteFormVisible}
                 onClose={() => setUserInviteFormVisible(false)}>
          <Card>
            <form onSubmit={submitInviteUser}>
              <h4>{t(I18N_INVITE_USER)}</h4>

              <Label name={t(I18N_EMAIL)} required>
                <input autoFocus={userInviteFormVisible} name="email" type="email" required
                       onChange={(e) => setNewUserEmail(e.target.value)}
                       value={newUserEmail}/>
              </Label>

              <Label name={t(I18N_NAME)} required>
                <input type="text" required onChange={(e) => setNewUserName(e.target.value)} value={newUserName}/>
              </Label>

              <Label
                name={t(I18N_CONTRIBUTOR)}
                toggleable={true}
                toggled={newUserIsContributor}
                onToggle={handleNewUserRole}/>

              <p className="notification" dangerouslySetInnerHTML={{__html: t(I18N_USER_INVITATION_NOTE)}}/>

              <div className="buttons-container">
                <Button type="cancel" onClick={() => setUserInviteFormVisible(false)}>{t(I18N_CANCEL)}</Button>
                <Button type="submit"><FiUserPlus/>{t(I18N_INVITE)}</Button>
              </div>
            </form>
          </Card>
        </Overlay>

        <MediaOverlay ref={mediaOverlayRef} room={room} objectTags={room.objectTags}/>

        {promptDeleteRoomOverlay}

        <Overlay title={t(I18N_ADD_LINK)} visible={urlFormVisible} onClose={() => setUrlFormVisible(false)}>
          <Card>
            <form onSubmit={submitUrl}>
              <h4>{t(I18N_ADD_LINK)}</h4>

              <Label name={t(I18N_URL)} required>
                <input autoFocus={urlFormVisible} name="url" type="text" required onChange={(e) => handleNewUrl(e)}/>
              </Label>

              <Label name={t(I18N_NAME)} required>
                <input type="text" required onChange={(e) => handleNewUrlName(e)}/>
              </Label>

              <Label name={t(I18N_OBJECT_TAG)} required>
                <select onChange={(e) => handleNewUrlObjectTag(e)} required>
                  {visibleObjectTags.map(objectTag => (
                    <option key={objectTag} value={objectTag}>{
                      objectTag.includes(ObjectTagHiddenPrefix)
                        ? objectTag.replace(ObjectTagHiddenPrefix, '') + ' (hidden)'
                        : objectTag
                    }</option>
                  ))}
                </select>
              </Label>

              <div className="buttons-container">
                <Button type="cancel" onClick={() => setUrlFormVisible(false)}>{t(I18N_CANCEL)}</Button>
                <Button type="submit">
                  <FiLink2/>
                  {t(I18N_SAVE)}
                </Button>
              </div>
            </form>
          </Card>
        </Overlay>
      </>
    )
  } else if (error) {
    return (
      <div className="full-page">
        <div className="page-error">{error}</div>
      </div>
    )
  } else {
    return (
      <div className="full-page">
        <Loader size="large"/>
      </div>
    )
  }
})