import React, {useEffect, useMemo, useState} from "react";
import {UserDropdownProps} from "./types";
import {User} from "../../store/modules/user/types";
import {Avatar} from "../avatar";
import {Dropdown} from "../dropdown";
import {useTranslation} from "react-i18next";
import {I18N_USER_DROPDOWN_PLACEHOLDER} from "../../translation";

export default (props: UserDropdownProps) => {
  const {t} = useTranslation();
  const [selectedUser, setSelectedUser] = useState<User>(props.value ?? props.items[0]);
  const filterSearch = useMemo(() => {
    return (user: User, searchValue: string) => [user.displayName, user.email].some(userAttribute => userAttribute.includes(searchValue))
  }, []);
  const renderSelected = useMemo(() => (user: User) => (
    <div className="user">
      <Avatar url={user.imageUrl ?? ''} key={user.name} size={40}/>
      <div className="user-info">
        <div>{user.name}</div>
        <div className="user-email">{user.email}</div>
      </div>
    </div>
  ), []);
  const renderItem = useMemo(() => (user: User, searchText: string) => (
    <div className="user">
      <Avatar url={user.imageUrl ?? ''} key={user.name} size={40}/>
      <div className="user-info">
        <div dangerouslySetInnerHTML={{__html: user.name}}/>
        <div className="user-email">{user.email}</div>
      </div>
    </div>
  ), []);

  useEffect(() => {
    props.onChange(selectedUser);
  }, [props, selectedUser]);

  return (
    <Dropdown
      className="user-dropdown"
      items={props.items}
      value={props.value}
      placeholder={t(I18N_USER_DROPDOWN_PLACEHOLDER)}
      onChange={(user: User) => setSelectedUser(user)}
      filterSearch={filterSearch}
      renderSelected={renderSelected}
      renderItem={renderItem}/>
  )
}