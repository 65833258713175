import React from "react";
import {ElementProps} from "./types";

export default ({element, children, attributes}: ElementProps) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 className="h2" {...attributes}>{children}</h1>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'link':
      return <a {...attributes} target="_blank" rel="noopener noreferrer" href={element.url}>{children}</a>;
    default:
      return <p {...attributes}>{children}</p>
  }
};