import {User, UserCapabilityMap, UserRole} from "../user/types";
import {NO_FILE} from "../../types";

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
export const AUTH_SET_PENDING = 'AUTH_SET_PENDING';
export const AUTH_SET_DONE = 'AUTH_SET_DONE';
export const UPDATE_USER = 'UPDATE_USER';
export const REFRESH_CURRENT_USER = 'REFRESH_CURRENT_USER';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const DISPLAY_LOGIN = 'DISPLAY_LOGIN';

export interface JWTToken {
  token: string;
  expires: number;
}

export interface AuthUser extends User {
  jwtToken: JWTToken;
}

export interface UploadUser {
  imageFile?: File | NO_FILE;
  password?: string;
}

export interface TmpUser {
  displayName: string;
  resetKey: string;
}

export interface AuthState {
  currentUser: AuthUser | null;
  isLoading: boolean;
  authError: string | null;
  tmpUser: TmpUser | null;
  sessionExpired: boolean;
}

export interface AuthResponseToken {
  token: string;
  token_expires: number;
}

export interface AuthUserResponse {
  user: {
    data: {
      ID: string,
      user_email: string,
      user_nicename: string,
      user_registered: string,
      user_status: string,
      display_name: string,
    },
    caps: UserCapabilityMap,
    roles: UserRole[],
  };
  jwt: AuthResponseToken;
}

export interface AuthResponseError {
  code: string;
  message: string;
  data: { status: number };
}

export type AuthResponseType = AuthUserResponse | AuthResponseToken | AuthResponseError;

export function isErrorResponse(response: AuthResponseType): response is AuthResponseError {
  return 'code' in response
}

interface LoginAction {
  type: typeof LOGIN;
  payload: AuthUser;
}

interface LogoutAction {
  type: typeof LOGOUT
}

interface UpdateUserAction {
  type: typeof UPDATE_USER;
  payload: User;
}

interface RefreshCurrentUserAction {
  type: typeof REFRESH_CURRENT_USER;
  payload: User;
}

interface RefreshTokenAction {
  type: typeof REFRESH_TOKEN;
  payload: JWTToken;
}

interface DisplayLoginAction {
  type: typeof DISPLAY_LOGIN;
}

export type AuthActionTypes = LoginAction
  | LogoutAction
  | UpdateUserAction
  | RefreshCurrentUserAction
  | RefreshTokenAction
  | DisplayLoginAction;