import React from 'react';
import {IconProps} from "../types";

export default (props: IconProps) => {
  return (
    <svg width={props.width ?? 30} height={props.height ?? 30} viewBox="0 0 30 30">
      <rect x="12" y="9" width="11" height="2" fill="currentColor"/>
      <rect x="12" y="14" width="11" height="2" fill="currentColor"/>
      <rect x="12" y="19" width="11" height="2" fill="currentColor"/>
      <circle cx="9" cy="10" r="1" fill="currentColor"/>
      <circle cx="9" cy="15" r="1" fill="currentColor"/>
      <circle cx="9" cy="20" r="1" fill="currentColor"/>
    </svg>
  )
}