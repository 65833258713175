import {
  ADD_CAP,
  CREATE_OR_UPDATE_GROUP,
  DELETE_CAP,
  DELETE_GROUP,
  GET_ADMINS_AND_AUTHORS,
  GET_CAPS,
  GET_GROUPS,
  GRANT_CAP,
  GRANT_TEMPLATE_ACCESS,
  REVOKE_CAP,
  REVOKE_TEMPLATE_ACCESS,
  User,
  UserGroup
} from "./types";
import {RoomTemplate} from "../room/types";
import {API_ACTION, ApiAction} from "../../middleware/api/types";

export function getAdminsAndAuthors(): ApiAction {
  return {
    [API_ACTION]: true,
    type: GET_ADMINS_AND_AUTHORS,
    method: 'GET',
    endpoint: 'users'
  }
}

export function grantTemplateAccess(user: User, roomTemplate: RoomTemplate): ApiAction {
  return {
    [API_ACTION]: true,
    type: GRANT_TEMPLATE_ACCESS,
    method: 'POST',
    endpoint: `users/${user.userId}/template-access/${roomTemplate.roomTemplateId}/grant`,
    payloadCreator: () => {
      return {
        user,
        roomTemplate
      }
    }
  }
}

export function revokeTemplateAccess(user: User, roomTemplate: RoomTemplate): ApiAction {
  return {
    [API_ACTION]: true,
    type: REVOKE_TEMPLATE_ACCESS,
    method: 'POST',
    endpoint: `users/${user.userId}/template-access/${roomTemplate.roomTemplateId}/revoke`,
    payloadCreator: () => {
      return {
        user,
        roomTemplate
      }
    }
  }
}

export function getGroups(): ApiAction {
  return {
    [API_ACTION]: true,
    type: GET_GROUPS,
    method: 'GET',
    endpoint: 'users/groups'
  }
}

export function createOrUpdateGroup(group: UserGroup): ApiAction {
  const {users, ...restProps} = group;
  const uploadGroup = {
    ...restProps,
    userIds: group.users.map(user => user.userId),
  };

  return {
    [API_ACTION]: true,
    type: CREATE_OR_UPDATE_GROUP,
    method: 'POST',
    endpoint: 'users/groups',
    bodyArgs: uploadGroup
  }
}

export function deleteGroup(group: UserGroup): ApiAction {
  return {
    [API_ACTION]: true,
    type: DELETE_GROUP,
    method: 'DELETE',
    endpoint: `users/groups/${group.groupId}`,
    payloadCreator: () => group
  }
}

export function getCaps(): ApiAction {
  return {
    [API_ACTION]: true,
    type: GET_CAPS,
    method: 'GET',
    endpoint: 'users/capabilities'
  }
}

export function addCap(capability: string): ApiAction {
  return {
    [API_ACTION]: true,
    type: ADD_CAP,
    method: 'POST',
    endpoint: 'users/capabilities',
    bodyArgs: {
      capability
    },
    responseType: 'text'
  }
}

export function deleteCap(capability: string): ApiAction {
  return {
    [API_ACTION]: true,
    type: DELETE_CAP,
    method: 'DELETE',
    endpoint: 'users/capabilities',
    bodyArgs: {
      capability
    },
    payloadCreator: () => capability
  }
}

export function grantCapability(user: User, capability: string): ApiAction {
  return {
    [API_ACTION]: true,
    type: GRANT_CAP,
    method: 'POST',
    endpoint: `users/${user.userId}/capabilities/${capability}/grant`,
    payloadCreator: () => ({
      user,
      capability
    })
  }
}

export function revokeCapability(user: User, capability: string): ApiAction {
  return {
    [API_ACTION]: true,
    type: REVOKE_CAP,
    method: 'POST',
    endpoint: `users/${user.userId}/capabilities/${capability}/revoke`,
    payloadCreator: () => ({
      user,
      capability
    })
  }
}