import {useMemo} from "react";
import {useSelector} from "react-redux";
import {Capability, CapabilityParams} from "./types";
import {RootState} from "../../store/configure";
import {AuthUser} from "../../store/modules/auth/types";

export const userHasCapability = (currentUser: AuthUser, capability: Capability, params?: CapabilityParams) => {
  const capRoomAdmin = (params?.room?.roomId ? `admin_room_${params?.room?.roomId}` : '');
  const capRoomAuthor = (params?.room?.roomId ? `author_room_${params?.room?.roomId}` : '');
  const capRoomContributor = (params?.room?.roomId ? `contributor_room_${params?.room?.roomId}` : '');

  switch (capability) {
    case 'edit_templates':
      return currentUser.roles.some(role => ['administrator'].includes(role));

    case 'create_room':
      return currentUser.roles.some(role => ['administrator', 'author'].includes(role));

    case 'delete_room':
      return [capRoomAdmin, capRoomAuthor].some(cap => currentUser.caps[cap]);

    case 'edit_room':
    case 'edit_users':
    case 'edit_media':
    case 'edit_urls':
      return [capRoomAdmin, capRoomAuthor, capRoomContributor].some(cap => currentUser.caps[cap]);

    case 'manage_groups':
      return currentUser.roles.includes('administrator') && (currentUser.caps?.['manage_groups'] ?? false);

    case 'manage_capabilities':
      return currentUser.roles.includes('administrator') && (currentUser.caps?.['manage_capabilities'] ?? false);

    default:
      return false;
  }
};

export default (capability: Capability, params?: CapabilityParams): boolean => {
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);

  return useMemo(() => {
    if (currentUser) {
      return userHasCapability(currentUser, capability, params)
    }

    return false
  }, [capability, params, currentUser]);
}