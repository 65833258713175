import React from 'react';
import {IconProps} from "../types";

export default (props: IconProps) => {
  return (
    <svg width={props.width ?? 30} height={props.height ?? 30} viewBox="0 0 30 30">
      <g transform="translate(10, 9)">
        <path d="M8.4,5.8C8.8,5.3,9,4.6,9,3.9V3.1C9,1.4,7.6,0,5.9,0H2H0v5.9V7v5.1V13h0.9H2h4c2.2,0,4-1.8,4-4C10,7.7,9.3,6.5,8.4,5.8z
		 M2,2h3.9C6.5,2,7,2.5,7,3.1v0.8C7,4.5,6.5,5,5.9,5H2V2z M6,11H2V7h3.9H6c1.1,0,2,0.9,2,2C8,10.1,7.1,11,6,11z"
              fill="currentColor"/>
      </g>
    </svg>
  )
}