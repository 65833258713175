export type PasswordRuleFunction = (password: string) => boolean;

export interface PasswordRule {
  description: string;
  rule: RegExp | PasswordRuleFunction;
}

export type PasswordRuleset = { [key: string]: PasswordRule };

type ChangeHandler = (value: string) => void;
type ValidityHandler = (valid: boolean) => void;

export interface PasswordChangeInputProps {
  onChange?: ChangeHandler;
  onValidityChange?: ValidityHandler;
  hideRulesWhenNotFocused?: boolean;
}

export interface PasswordChangeInputRef {
  clear: () => void
}

export const PASSWORD_MIN_LENGTH = 10;