import React, {FormEvent, useEffect, useRef, useState} from "react";
import {FiMinus, FiPlus} from "react-icons/all";
import {useOnKeyPress} from "../../hooks";
import classNames from "classnames";

export default (props: React.ComponentProps<any>) => {
  const [value, setValue] = useState<number>(props.value || props.min || 1);
  const [inputFocused, setInputFocused] = useState(props.autoFocus);
  const inputRef = useRef<HTMLInputElement>(null);

  useOnKeyPress({
    'ArrowDown': (e) => {
      e.preventDefault();
      if (inputFocused) {
        decreaseValue()
      }
    },
    'ArrowUp': (e) => {
      e.preventDefault();
      if (inputFocused) {
        increaseValue()
      }
    }
  });

  useEffect(() => {
    if (inputFocused && inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef, inputFocused]);

  useEffect(() => {
    props.onChange?.(value)
  }, [props, value]);

  const checkValue = (newValue: number) => Math.max(props.min ?? 1, Math.min(props.max ?? Infinity, newValue));

  const increaseValue = () => {
    setValue(checkValue(value + 1));
    setInputFocused(true)
  };

  const decreaseValue = () => {
    setValue(checkValue(value - 1));
    setInputFocused(true)
  };

  const handleChangeValue = (e: FormEvent<HTMLInputElement>) => {
    setValue(checkValue(+e.currentTarget.value));
  };

  return (
    <div className="number-input">
      <input ref={inputRef} {...props} type="number" value={value}
             onFocus={() => setInputFocused(true)}
             onBlur={() => setInputFocused(false)}
             onChange={handleChangeValue}
             className={classNames({
               'focused': inputFocused,
               [props.className]: !!props.className
             })}/>
      <div className="number-input-decrease" onMouseDown={decreaseValue}>
        <FiMinus/>
      </div>
      <div className="number-input-increase" onMouseDown={increaseValue}>
        <FiPlus/>
      </div>
    </div>
  )
}