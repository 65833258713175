import React, {useMemo, useState} from "react";
import {ObjectTagHiddenPrefix, ObjectTagProps} from "./types";
import {FiEye, FiEyeOff, FiX} from "react-icons/all";
import classNames from "classnames";

export default (props: ObjectTagProps) => {
  const [visible, setVisible] = useState(!props.objectTag.includes(ObjectTagHiddenPrefix));
  const objectTag = useMemo(() => {
    return props.objectTag.replace(ObjectTagHiddenPrefix, '')
  }, [props.objectTag]);

  const handleVisibilityChange = () => {
    setVisible(!visible);

    if (props.onVisibilityChange) {
      props.onVisibilityChange(!visible);
    }
  };

  return (
    <div className={classNames({
      'tag': true,
      'tag--closable': props.onRemove
    })}>
      {props.onVisibilityChange
        ? (
          <span className="tag-visibility" onClick={handleVisibilityChange}>
            {visible ? <FiEye/> : <FiEyeOff/>}
          </span>
        )
        : (
          !visible && <span className="tag-visibility"><FiEyeOff/></span>
        )
      }
      <span>{objectTag}</span>
      {props.onRemove && (
        <span className="tag-close">
          <FiX onClick={props.onRemove}/>
        </span>
      )}
    </div>
  )
}