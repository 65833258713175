import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {DragContext} from '../../contexts/drag-context';
import {DragItemData, isDragItemProps, MouseCoordinate, MouseCoordinateDistance} from "../drag-item/types";
import {DragContainerProps, Rect} from "../../contexts/drag-context/types";
import classNames from "classnames";

export default ({element: Container, children, className, onOrder, ...restProps}: DragContainerProps & React.ComponentProps<any>) => {
  const ref = useRef<any>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const [dragData, setDragData] = useState<DragItemData | undefined>(undefined);
  const [order, setOrder] = useState<number[] | undefined>(undefined);

  const [containerOffset, setContainerOffset] = useState<MouseCoordinate>({x: 0, y: 0});
  const [mousePositionStart, setMousePositionStart] = useState<MouseCoordinate>({x: 0, y: 0});
  const [dragDifference, setDragDifference] = useState<MouseCoordinateDistance>({dx: 0, dy: 0});

  const [ghost, setGhost] = useState<any | null>(null);
  const [childBounds, setChildBounds] = useState<Rect[]>([]);
  const {staticChildren, floatingChildren} = useMemo(() => {
    if (!isMouseDown) {
      return {
        floatingChildren: null,
        staticChildren: null
      }
    }

    let staticChildren: any[] = [];
    let floatingChildren: any[] = [];
    let order: any[] = [];

    React.Children.toArray(children)
      .flat()
      .forEach((child: any, i) => {
        if (!isDragItemProps(child.props)) {
          staticChildren.push(child)
        } else {
          if (i >= childBounds.length) {
            return
          }

          const {id} = child.props.item;

          const placeholder = React.createElement('div', {
            'data-id': id,
            key: `placeholder_${id}`,
            className: 'drag-placeholder',
            style: {
              width: `${childBounds[i].width}px`,
              height: `${childBounds[i].height}px`,
            }
          });
          staticChildren.push(placeholder);

          order.push(id);

          if (id === dragData?.id) {
            return
          }

          const clone = React.cloneElement(child, {
            key: id,
            index: i
          });
          floatingChildren.push(clone);
        }
      });

    setOrder(order);

    return {
      staticChildren,
      floatingChildren
    }
  }, [isMouseDown, childBounds, children, dragData]);

  const initDrag = (data: DragItemData | undefined, clone: any, mousePosition: MouseCoordinate, mouseOffset: MouseCoordinateDistance) => {
    const {left, top} = ref.current.getBoundingClientRect();

    const bounds = Array.from(ref.current.childNodes)
      .flatMap((child: any) => {
        const bound = child.getBoundingClientRect();

        return {
          width: bound.width,
          height: bound.height,
          left: child.offsetLeft,
          top: child.offsetTop
        }
      });

    setChildBounds(bounds);
    setContainerOffset({
      x: left - mouseOffset.dx,
      y: top - mouseOffset.dy
    });
    setMousePositionStart(mousePosition);
    setDragData(data);
    setGhost(clone);
    setIsMouseDown(true);
  };

  const move = (idFrom: number, idTo: number): number[] => {
    if (!order) {
      return []
    }

    let newOrder = order.filter(id => id !== idFrom);
    newOrder.splice(order.findIndex(id => id === idTo), 0, idFrom);
    setOrder(newOrder);
    return newOrder
  };

  const startDrag = () => {
    setIsDragging(true);
  };

  const endDrag = useCallback((newOrder: number[]) => {
    if (order?.join(',') !== newOrder.join(',') && newOrder.length) {
      onOrder?.(newOrder);
    }

    setOrder(undefined);
    setIsMouseDown(false);
    setIsDragging(false);
    setDragData(undefined);
    setGhost(null);
  }, [order, onOrder]);

  /* Event handlers */
  useEffect(() => {
    if (!ref.current || !isMouseDown) {
      return
    }

    let isDragging = false;
    let dragDistance = 0;
    let dragDifference: MouseCoordinateDistance = {dx: 0, dy: 0};
    let order: number[] = [];

    const handleMouseMove = (e: MouseEvent) => {
      dragDifference = {
        dx: e.pageX - mousePositionStart.x,
        dy: e.pageY - mousePositionStart.y,
      };
      setDragDifference(dragDifference);
      dragDistance = Math.sqrt(Math.pow(dragDifference.dx, 2) + Math.pow(dragDifference.dy, 2));

      if (isDragging) {
        const target = (e.target as HTMLElement).dataset;

        if ('id' in target && target.id) {
          order = move(dragData?.id as number, parseInt(target.id));
        }
      } else if (dragDistance > 10) {
        isDragging = true;
        startDrag()
      }
    };

    const handleMouseUp = (e: MouseEvent) => {
      const prevent = !isDragging;

      isDragging = false;
      endDrag(order);

      if (prevent) {
        e.preventDefault();
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, isMouseDown]);

  return (
    <Container className={classNames({
      [className]: true,
      'drag-container': true,
      'isDragging': isDragging
    })} {...restProps} ref={ref}>
      <DragContext.Provider value={{
        initDrag,
        isDragging,
        setIsDragging,
      }}>
        {isDragging && order
          ? (
            <>
              {staticChildren}
              {floatingChildren && floatingChildren.map((child: any, i) => {
                const index = order.findIndex(id => id === child.props.item.id);
                const {width, height, left, top} = childBounds[index];

                return (
                  <div className="dragItem--floating" style={{
                    width: `${width}px`,
                    height: `${height}px`,
                    transform: `translate(${left}px, ${top}px)`
                  }} key={i}>
                    {child}
                  </div>
                )
              })}
              {ghost && (
                <div className="drag-ghost" style={{
                  width: `${childBounds[order.findIndex(id => id === dragData?.id)]?.width ?? 0}px`,
                  height: `${childBounds[order.findIndex(id => id === dragData?.id)]?.height ?? 0}px`,
                  transform: `translate(${dragDifference.dx - containerOffset.x}px, ${dragDifference.dy - containerOffset.y}px)`
                }}>
                  {ghost}
                </div>
              )}
            </>
          )
          : children}
      </DragContext.Provider>
    </Container>
  )
}