import {
  ADD_CAP,
  CREATE_OR_UPDATE_GROUP,
  DELETE_CAP,
  DELETE_GROUP,
  GET_ADMINS_AND_AUTHORS,
  GET_CAPS,
  GET_GROUPS,
  GRANT_CAP,
  GRANT_TEMPLATE_ACCESS,
  REVOKE_CAP,
  REVOKE_TEMPLATE_ACCESS,
  UserActionTypes,
  UserState
} from "./types";

const initialState: UserState = {
  users: [],
  groups: [],
  capabilities: []
};

export default (state = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case GET_ADMINS_AND_AUTHORS:
      return {
        ...state,
        users: action.payload
      };

    case GRANT_TEMPLATE_ACCESS:
      return {
        ...state,
        users: state.users.map(user => {
          if (user.userId === action.payload.user.userId) {
            return {
              ...user,
              caps: {
                ...user.caps,
                [`use_template_${action.payload.roomTemplate.roomTemplateId}`]: true
              }
            }
          } else {
            return user
          }
        })
      };

    case REVOKE_TEMPLATE_ACCESS:
      return {
        ...state,
        users: state.users.map(user => {
          if (user.userId === action.payload.user.userId) {
            const caps = user.caps;

            delete caps[`use_template_${action.payload.roomTemplate.roomTemplateId}`];

            return {
              ...user,
              caps
            }
          } else {
            return user
          }
        })
      };

    case GET_GROUPS:
      return {
        ...state,
        groups: action.payload
      };

    case CREATE_OR_UPDATE_GROUP:
      const groups = [
        ...state.groups.filter(group => group.groupId !== action.payload.groupId),
        action.payload
      ].sort((a, b) => (b.groupId ?? 0) - (a.groupId ?? 0));

      return {
        ...state,
        groups
      };

    case DELETE_GROUP:
      return {
        ...state,
        groups: state.groups.filter(group => group.groupId !== action.payload.groupId)
      };

    case GET_CAPS:
      return {
        ...state,
        capabilities: action.payload
      };

    case ADD_CAP:
      return {
        ...state,
        capabilities: [
          ...state.capabilities,
          action.payload
        ]
      };

    case DELETE_CAP:
      return {
        ...state,
        capabilities: state.capabilities.filter(cap => cap !== action.payload)
      };

    case GRANT_CAP:
      return {
        ...state,
        users: state.users.map(user => {
          if (user.userId === action.payload.user.userId) {
            user.caps[action.payload.capability] = true
          }

          return user
        })
      };

    case REVOKE_CAP:
      return {
        ...state,
        users: state.users.map(user => {
          if (user.userId === action.payload.user.userId) {
            user.caps[action.payload.capability] = false
          }

          return user
        })
      };

    default:
      return state;
  }
};