import React from 'react';
import {IconProps} from "../types";

export default (props: IconProps) => {
  return (
    <svg viewBox="0 0 30 30">
      <g transform="translate(9, 9)">
        <rect y="12" width="12" height="2" fill="currentColor"/>
        <path d="M6,10c-3,0-5-2.4-5-6V0h2v4c0,1.2,0.3,4,3,4c2.7,0,3-2.8,3-4V0h2v4C11,7.6,9,10,6,10z"
              fill="currentColor"/>
      </g>
    </svg>
  )
}