import {RefObject, useEffect} from "react";

export default (ref: RefObject<HTMLDivElement>, handler: Function) => {
  useEffect(
    () => {
      const listener = (e: MouseEvent) => {
        if (!ref.current || ref.current !== e.target) {
          return;
        }

        handler(e);
      };

      document.addEventListener('mousedown', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
      };
    },
    [ref, handler]
  );
}