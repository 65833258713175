import React, {useRef, useState} from "react";
import {FiLogOut, FiSettings} from "react-icons/all";
import {AuthUser} from "../../store/modules/auth/types";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../store/modules/auth/actions";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {Avatar} from "../avatar";
import classNames from "classnames";
import {useOnClickOutside, useOnKeyPress} from "../../hooks";
import {Card} from "../card";
import {useTranslation} from "react-i18next";
import {I18N_HELLO, I18N_LOGOUT, I18N_SETTINGS} from "../../translation";

export default withRouter((props: RouteComponentProps) => {
  const {t} = useTranslation();
  const currentUser: AuthUser | null = useSelector((state: any) => state.auth.currentUser);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    if (open) {
      setOpen(false)
    }
  });

  useOnKeyPress({
    'Escape': () => {
      setOpen(false)
    }
  });

  const doLogout = () => {
    if (currentUser) {
      setOpen(false);
      dispatch(logout(currentUser));
      props.history.push('/')
    }
  };

  return (
    <div className={classNames({
      'user-menu': true,
      'user-menu--open': open
    })}
         ref={ref}>
      <div className="user-menu-toggle" onClick={() => setOpen(!open)}>
        <div className="user-name">{t(I18N_HELLO)}, {currentUser?.displayName}!</div>
        <Avatar className="user-avatar" size={48} url={currentUser?.imageUrl}/>
        <svg width={12} height={6}>
          <path d="M1,1L6,5L11,1" fill="none" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>
      </div>

      <Card className="user-menu-dropdown">
        <ul>
          <Link to="/settings" onClick={() => setOpen(false)}>
            <li><FiSettings/> {t(I18N_SETTINGS)}</li>
          </Link>
          <a href="/logout" onClick={e => {
            e.preventDefault();
            return false
          }}>
            <li onClick={doLogout}><FiLogOut/> {t(I18N_LOGOUT)}</li>
          </a>
        </ul>
      </Card>
    </div>

  )
});