import React from "react";
import {IconProps} from "./types";

export default (props: IconProps) => (
  <svg className="icon" width={props.width ?? 18.472} height={props.height ?? 21.604} viewBox="0 0 18.472 21.604">
    <g transform="translate(-93.485 -258.504)">
      <g transform="translate(97.395 258.504)" fill="none" stroke="currentColor" strokeWidth="1.5">
        <ellipse cx="5.5" cy="6" rx="5.5" ry="6" stroke="none"/>
        <ellipse cx="5.5" cy="6" rx="4.5" ry="5" fill="none"/>
      </g>
      <path d="M94.481,279.562s.739-8.775,5.126-8.775c1.329,0,1.9-2.6,1.9-2.6" transform="translate(0 0.463)"
            fill="none" stroke="currentColor" strokeWidth="1.5"/>
      <path d="M101.507,279.562s-.739-8.775-5.126-8.775c-1.329,0-1.9-2.6-1.9-2.6" transform="translate(9.453 0.463)"
            fill="none" stroke="currentColor" strokeWidth="1.5"/>
      <path d="M99.336,270.728l3.213,3.468,3.213-3.468" transform="translate(0.244 0.591)" fill="none"
            stroke="currentColor"
            strokeWidth="1"/>
    </g>
  </svg>
)